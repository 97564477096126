<template>
  <div>
    <div class="card-header">AudaNet</div>
    <div class="card-block" style="padding-left: 0px; padding-right: 0px">

      <p style="padding-left: 15px">Let’s you manage Repairshop integration with your business AudaNet account.</p>

      <h5 style="padding-left: 15px; margin: 20px 0 35px; font-weight: bold;">AudaNet</h5>

      <div class="auda-line"></div>

      <div class="form-group row">
        <label tabindex="0" class="col-sm-4 col-4 col-form-label">Connection Status</label>
        <div class="col-sm-8 col-8 float-sm-right">
          <input class="form-control" :readonly="true">
        </div>
      </div>

      <div class="form-group row">
        <label tabindex="0" class="col-sm-4 col-4 col-form-label">Username</label>
        <div class="col-sm-8 col-8 float-sm-right">
          <input class="form-control" placeholder="Username" :readonly="!isStatusActive">
        </div>
      </div>

      <div class="form-group row">
        <label tabindex="0" class="col-sm-4 col-4 col-form-label">Password</label>
        <div class="col-sm-8 col-8 float-sm-right">
          <input class="form-control" placeholder="Password" :readonly="!isStatusActive">
        </div>
      </div>

      <div class="form-group row">
        <label tabindex="0" class="col-sm-4 col-4 col-form-label">Status to set new RFQ
          <p class="lable-text">Choose a Card Status to create new RFQ's from Estimage into</p>
        </label>
        <div class="col-sm-8 col-8 float-sm-right">
          <multiselect
              :showLabels="false"
              :close-on-select="true"
              :options="ceStatuses"
              :option-height="29"
              :disabled="!isStatusActive"
              style="opacity: 1"
              :max-height="203"
              track-by="ce_status_id"
              label="name"
              placeholder="Select"
          ></multiselect>
        </div>
      </div>

      <div class="form-group row d-flex" style="justify-content: center">
        <a :href="''" class="btn btn-primary" style="width: 100px">Enable</a>
      </div>


    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {mapGetters} from "vuex";

export default {
  name: 'Audanet',
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
  },
  data() {
    return {
      ceStatuses: [],
    }
  },
  methods: {
    loadCEStatuses () {
      this.$store.dispatch('loadCEStatuses').then((res) => {
        if (res.status === 200) {
          this.ceStatuses = res.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
  },
  mounted() {
    this.loadCEStatuses()
  }
};
</script>

<style scoped>
.lable-text {
  color: rgba(0, 0, 0, 0.5);
  padding-right: 10px;
  margin-top: 5px;
}

.auda-line {
  width: calc(100% - 45px);
  height: 10px;
  right: 15px;
  display: block;
  position: absolute;
  transform: translateY(-25px);
  background: #e3e4e7;
}
</style>

