import { UAParser } from 'ua-parser-js';
const device = new UAParser().getDevice();
const browser = new UAParser().getBrowser();

let getNavigatorInstance = function () {
    if (typeof window !== 'undefined') {
        if (window.navigator || navigator) {
            return window.navigator || navigator;
        }
    }

    return false;
};

let isIOS13Check = function (type) {
    var nav = getNavigatorInstance();
    return nav && nav.platform && (nav.platform.indexOf(type) !== -1 || nav.platform === 'MacIntel' && nav.maxTouchPoints > 1 && !window.MSStream);
};

let getIPad13 = function () {
    return isIOS13Check('iPad');
};

const isMobile = device?.type == 'mobile' || device?.type == 'tablet' || getIPad13()
const isTablet = device?.type == 'tablet' || getIPad13()
const isMobileOnly = device?.type == 'mobile'
const browserName = browser?.name
console.log('isMobile', isMobile, 'isMobileOnly', isMobileOnly, 'isTablet', isTablet, 'all info', new UAParser().getResult())

export {
    isMobile,
    isTablet,
    isMobileOnly,
    browserName,
}
