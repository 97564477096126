<script>
import EstimatesSnapshot from './snapshot/estimates-snapshot'
import EstimatesTable from './estimate-table'
import {mapGetters} from "vuex";

export default {
  name: 'estimates',
  data: function () {
    return {
      currentViewActive: "table-view",
    }
  },
  computed: {
    ...mapGetters({
      getterBusinessName: 'getBusinessName',
    })
  },
  methods: {
    setViewTable(){
      this.currentViewActive = "table-view"
    },
    setViewSnapshot(){
      this.currentViewActive = "snapshot-view"
    },
  },
  components: {
    EstimatesSnapshot,
    EstimatesTable
  }
}
</script>

<template>
  <div class="estimates-page">
    <div class="page-header">
      <h4 v-if="currentViewActive == 'table-view'">View Estimates </h4>
      <h4 v-else>Estimates Tracker</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item active">View Estimates</li>
      </ol>
    <h4 class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
    <div class="row">
      <estimates-table
          v-show="currentViewActive == 'table-view'"
          @setViewSnapshot="setViewSnapshot"
      ></estimates-table>
      <estimates-snapshot
          v-if="currentViewActive == 'snapshot-view'"
          @setViewTable="setViewTable"
      ></estimates-snapshot>
    </div>
  </div>
</template>

<style>
.V3 .estimates-page .set-estimate-priority-list {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 140px;
}

.estimates-page .no-gutters {
  display: flex;
  justify-content: space-between;
}

.estimates-page .progress {
  margin: 0px;
  padding: 0;
  width: 150px;
  height: 7px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
}

.estimates-page .bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);;
}


.estimates-page tbody tr {
  cursor: pointer;
}

.estimates-page .action-icon {
  display: block;
  width: 20px;
  height: 20px;
}

.estimates-page .action-icon i {
  font-size: 19px;
  color: black;
}

.estimates-page .action-icon.active i {
  color: #142742
}

.estimates-page .action-icon.active[tooltip] {
  position: relative;
  display: inline-block;
  transition: .3s;
}

.estimates-page .action-icon.active[tooltip]::before {
  content: "";
  position: absolute;
  bottom: 25px;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
  border-bottom: 0;
  margin-left: -5px;
  margin-bottom: -5px;
  opacity: 0;
  transition: .3s;
}

.estimates-page .action-icon.active[tooltip]:hover::before {
  opacity: 1;
}

.estimates-page .action-icon.active[tooltip-position='left']::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg)
}

.estimates-page .action-icon.active[tooltip-position='top']::before {
  left: 50%;
}

.estimates-page .action-icon.active[tooltip-position='buttom']::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg)
}

.estimates-page .action-icon.active[tooltip-position='right']::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg)
}

.estimates-page .action-icon.active[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: #333333;
  text-align: center;
  color: #FFFFFF;
  padding: 4px 2px;
  font-size: 10px;
  font-weight: 600;
  min-width: 155px;
  border-radius: 0px;
  pointer-events: none;
  z-index: 99;
  opacity: 0;
  transition: .3s;
}

.estimates-page .action-icon.active[tooltip-position='left']::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}

.estimates-page .action-icon.active[tooltip-position='top']::after {
  left: 50%;
}

.estimates-page .action-icon.active[tooltip-position='buttom']::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}

.estimates-page .action-icon.active[tooltip-position='right']::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

.estimates-page .action-icon.active[tooltip]:hover::after, [tooltip]:hover::before {
  opacity: 1
}

.estimates-page .semi-bold {
  font-size: 12px;
  font-weight: bold;
}

.estimates-page .semi {
  font-size: 12px;
  font-weight: 600;
}

.estimates-page .number {
  width: 72px !important;
}

.estimates-page .customer {
  width: 120px !important;
}

.estimates-page .rego {
  width: 60px !important;
}

.estimates-page .make {
  width: 60px !important;
}

.estimates-page .model {
  width: 80px !important;
}

.estimates-page .created-on {
  width: 70px !important
}

.estimates-page .amount {
  width: 70px !important;
}

.estimates-page .assigned {
  width: 80px !important;
}

.estimates-page .notification {
  width: 120px !important;
}

.estimates-page .status {
  width: 150px !important;
}

.estimates-page .insurer {
  width: 170px !important;
}

.V3 .estimates-page .tab-content-table table tr td {
  vertical-align: top;
  padding: 15px;
}

.estimates-page .table .status-header {
  margin-bottom: 7px;
  font-weight: bold;
}

.estimates-page .table .status-count {
  margin-bottom: 2px;
  font-size: 11px;
  opacity: 0.5;
  color: #1C1F39;
}

.V3 .estimates-page .search-filter__dropdown-item i {
  display: none;
}

.V3 .estimates-page .dropdown-menu.search-filter__dropdown-menu {
  margin-top: 12px;
  left: -10px;
}


.V3 .estimates-page .daterangepicker .btn-primary, .daterangepicker .btn-success {
  background-color: #5E79FF !important;
}
</style>
