<script>
import SiteFooter from './site-footer';
import {isMobile} from "@/deviceDetect";

export default {
  name: 'site-content',
  computed: {
    getStyleForContentArea() { //ToDo moved from old version. but I don't know why it needs.
      if (this.matchedComponentName === 'dashboard'
          || this.matchedComponentName === 'cards'
          || this.matchedComponentName == 'cards-edit'
          || this.matchedComponentName == 'estimates-edit'
          || this.matchedComponentName == 'board'
          || this.matchedComponentName ==  'qr-code-component') {
        return 'padding-bottom: 0 !important';
      }
      return 'padding-bottom: 46px';
    },
    matchedComponentName() {
      return (this.$route && this.$route.matched[0] && this.$route.matched[0].components)?this.$route.matched[0].components.default.name:'';
    },
  },
  data() {
    return{
      isMobile,
    }
  },
  components: {
    SiteFooter,
  },
};
</script>

<template>
  <div class="site-content" :class="{ 'site-content-mobile': isMobile }">
    <div class="content-area" :style="getStyleForContentArea">
      <div class="container-fluid">
        <router-view :key="$route.fullPath">Loading...</router-view>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<style>
.V3 .col-form-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.V3 .form-control {
  box-sizing: border-box;
  border: 1px solid rgb(28, 31, 57);
  border: 1px solid rgba(28, 31, 57, 0.25);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3px;
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.V3 .form-control::placeholder {
  color: rgba(28, 31, 57, 0.5);
  font-size: 12px;
  line-height: 16px;
}

/* WebKit, Edge */
.V3 .form-control::-webkit-input-placeholder {
  color: rgba(28, 31, 57, 0.5);
  font-size: 12px;
  line-height: 16px;
}

/* Firefox 4-18 */
.V3 .form-control:-moz-placeholder {
  color: rgba(28, 31, 57, 0.5);
  font-size: 12px;
  line-height: 16px;
}

/* Firefox 19+ */
.V3 .form-control::-moz-placeholder {
  color: rgba(28, 31, 57, 0.5);
  font-size: 12px;
  line-height: 16px;
}

/* IE 10-11 */
.V3 .form-control:-ms-input-placeholder {
  color: rgba(28, 31, 57, 0.5);
  font-size: 12px;
  line-height: 16px;
}

/* Edge */
.V3 .form-control::-ms-input-placeholder {
  color: rgba(28, 31, 57, 0.5);
  font-size: 12px;
  line-height: 16px;
}

.V3 .card {
  border: none;

}

.V3 .card-header {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;;
  color: #1C1F39 !important;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.V3 .card-header-2 {
  height: 36px;
  border-radius: 3px;
  background-color: rgba(27, 30, 56, 0.1);
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 11px 15px 9px;
}

</style>
