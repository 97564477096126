<script>
import NumberFormatter from './number-formatter'
import accounting from 'accounting'
import index from 'vue'
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import { isMobile } from '../../deviceDetect/index'

const dragDropItem = {
  data () {
    return {
      hoveredIndex: -1,
    }
  },
  methods: {
    isHovered (index) {
      return this.hoveredIndex === index
    },
    onDragEnter (index) {
      this.hoveredIndex = index
      this.$emit('dragenter', this.item, index)
    },
    onDragLeave (indexf) {
      this.hoveredIndex = -1
      this.$emit('dragleave', this.item, index)
    },
  }
}

/**
 * rsMsMixin
 * Adding right and left listener to Vue-Multiselect
 */
const rsMsMixin = {
  mounted () {
    const searchInput = this.$refs.search
    if (searchInput) {
      searchInput.addEventListener('keydown', this.handleKeydown)
    }
  },
  beforeDestroy () {
    const searchInput = this.$refs.search
    if (searchInput) {
      searchInput.removeEventListener('keydown', this.handleKeydown)
    }
  },
  methods: {
    handleKeydown (e) {
      if (e.keyCode === 37) {
        e.preventDefault()
        this.$emit('onKeyLeft')
        //   e.stopPropagation();

      } else if (e.keyCode === 39) {
        e.preventDefault()
        this.$emit('onKeyRight')
        //  e.stopPropagation();

      } else if (e.keyCode === 13) {
        var vm = this
        setTimeout(() => {
          vm.$emit('onKeyRight')
        }, 50)
      }
    }
  }
}

Multiselect.mixins.push(rsMsMixin)

export default {
  name: 'estimate-item',
  props: ['id', 'card', 'index', 'name', 'part_num', 'repairer',
    'type', 'subtype', 'paint_type', 'mup', 'adj_mup', 'qty', 'rate', 'adj_rate', 'rs_rate', 'cost', 'adj_changed_rate',
    'rate2', 'hrs', 'adj_hrs', 'rs_hrs', 'total', 'materialUseLevy', 'is_total_manual',
    'total_manual', 'estimateMethodology', 'item', 'isOverrided',
    'material', 'action', 'price', 'comment', 'showPDRPrice', 'isFromIag',
    'isEstimateTemplate', 'isInsuranceRepairYes',
     'isTemplate', 'row_highlight', 'is_show_in_jobcard', 'showPaintSetupTime', 'showEnviromentLevy', 'isEnableManualAdj',
    'original_hrs', 'original_total',
    'is_show_in_jobcard',
     'comments', 'isMovedElement', 'properties',
    'isNegativeValueCanBeRed', 'isSupplementDeletion',
    'isAuthorisePage', 'isChecked', 'isItemNew', 'isAuthorizedItemList'
  ],
  mixins: [dragDropItem],
  data: function () {
    return {
      isMouseMove: false,
      isMouseDown: false,
      isSelectedRow: false,
      selectedText: '',
      isMobile,
      isShowCommentBlock: false,
      optionsPDRMaterials: ['None', 'Steel', 'Aluminium'],
      optionsPDRActions: [{ key: 'None', label: 'None' }, { key: 'New', label: 'New' }, {
        key: 'PDR',
        label: 'PDR'
      }, { key: 'PushToPaint', label: 'Push To Paint' }],
      optionsPaintMaterials: [
        { key: 'not_set', label: '' },
        { key: 'S1', label: 'S1' },
        { key: 'S2', label: 'S2' },
        { key: 'M1', label: 'M1' },
        { key: 'M2', label: 'M2' },
        { key: 'M3', label: 'M3' },
        { key: 'M3PLUS', label: 'M3+' }],
      optionsPartsType: [
        { key: 'Select', label: 'Select' },
        { key: 'New', label: 'New' },
        { key: 'Parallel', label: 'Parallel' },
        { key: 'Alternate', label: 'Alternate' },
        { key: 'Aftermarket', label: 'Aftermarket' },
        { key: 'Used', label: 'Used' },
        { key: 'Recondition', label: 'Recondition' },
        { key: 'Exchange', label: 'Exchange' },
      ],
      value: '',
      d_id: this.id,
      d_card: this.card,
      d_index: this.index,
      d_name: this.name,
      d_part_num: this.part_num,
      d_repairer: this.repairer,
      d_type: this.type,
      d_subtype: this.subtype || 'Custom',
      d_mup: this.mup,
      d_adj_mup: this.adj_mup,
      d_qty: this.qty,
      d_adj_qty: this.adj_qty,
      d_rate: this.rate,
      d_adj_changed_rate: this.adj_changed_rate,
      d_adj_rate: this.adj_rate,
      d_rs_rate: this.rs_rate,
      d_cost: this.cost,
      d_rate_2: this.rate2,
      d_isChecked: this.item.isAuthorized,
      d_paint_type: this.paint_type,
      d_hrs: this.hrs,
      d_adj_hrs: this.adj_hrs,
      d_adj_total: this.adj_total,
      d_rs_hrs: this.rs_hrs,
      d_original_hrs: this.original_hrs,
      d_total: this.total,
      d_original_total: this.original_total,
      d_vis: this.materialUseLevy,
      isDrag: true,
      d_totalManual: this.total_manual,
      d_isTotalManual: this.is_total_manual,
      d_isOverrided: this.isOverrided,
      d_material: this.material,
      d_action: this.action,
      d_price: this.price,
      tempAdj: null,
      tempAdjHours: null,
    }
  },
  methods: {
    getStyleForAdjTotal() {
      if (!this.isAuthorisePage || this.isEstimateMethodologyDollars) {
        return {
          color: 'black',
        }
      }
      let adjTotal = this.d_adj_rate || this.resultAdj
      // console.log(Number(adjTotal), this.resultAdj)
      if (Number(adjTotal) > this.resultAdjOrigin) {
        return {
          color: '#2BAE66FF',
        }
      }
      if (Number(adjTotal) < this.resultAdjOrigin) {
        return {
          color: 'red',
        }
      }
      return {
        color: 'black',
      }
    },
    getSameCountString(s1, s2) {
      let count = 0;

      for(let i in s1) {
        s2.includes(s1[i]) ? count++ : false;
      }

      return count
    },
    _isEmpty2 (v) {
      if (_.isNumber(v)) {
        return _.isEmpty(_.toString(v))
      }
      return _.isEmpty(v)
    },
    onMouseMove () {
      this.isMouseMove = true
    },
    onMouseDown () {
      this.isMouseMove = false
      this.isMouseDown = true
      setTimeout(() => {
        if (this.isMouseDown && !this.isMouseMove) {
          this.isSelectedRow = true
        }
      }, 500)
    },
    onMouseUp () {
      this.isMouseDown = false
      this.isSelectedRow = false
    },
    updateSelectedText (e) {
      this.selectedText = e.target.value.substring(e.target.selectionStart, e.target.selectionEnd)
    },
    onKeyPressRightOnNumberFormatter (payload) {
      let f = payload.cell
      let e = payload.event
      let t = 'edit'
      if (f && f == 'price' && this.isPDRTechnicianUser) {
        f = 'name'
      }

      if (e && t && t == 'edit') {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos < elem.value.length && !elem.disabled && !elem.readOnly) {
          return false
        }
      }
      if (e) {
        e.preventDefault()
      }
      if (f == 'adj_hrs' && this.d_type === 'calculation' && this.card === 'Materials') {
        this.onKeyPressDown(this.card + '-' + f)
      } else if (f == 'name') {
        this.onKeyPressDown(this.card + '-' + f)
      } else {
        this.$emit('onKeyPressRight', this.card + '-' + f, this.card)
      }
    },
    onKeyPressLeftOnNumberFormatter (payload) {
      console.log('onKeyPressLeftOnNumberFormatter', payload.cell )
      let f = payload.cell
      let e = payload.event
      let t = 'edit'

      if (f && f == 'price' && this.isPDRTechnicianUser) {
        f = 'qty'
      }
      if (f && f == 'total' && this.isPDRTechnicianUser) {
        f = 'qty'
      }
      if (e && t && t == 'edit') {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos > 0 && !elem.disabled && !elem.readOnly) {
          return false
        }
      }
      if (e) {
        e.preventDefault()
      }
      if (f == 'adj_price' && (this.card !== 'Materials' ||
          (this.card === 'Materials' && ((this.d_type !== 'manual') || (this.isInsuranceRepairYes && !this.isTemplate))))) {
        this.onKeyPressUp(this.card + '-' + f)
      } else if (this.isEstimateMethodologyCustom && f == 'rate2' && !(this.isInsuranceRepairYes && this.d_adj_hrs === null)) {
        this.onKeyPressUp(this.card + '-' + f)
      } else if (!this.isEstimateMethodologyCustom && f == 'rate2' && (
          (this.card === 'Materials' && !(this.isInsuranceRepairYes && !this.isTemplate) && this.d_type !== 'calculation') ||
          (this.card !== 'Materials'))) {

        this.onKeyPressUp(this.card + '-' + f)
      } else if (!this.isEstimateMethodologyDollars && !this.isEstimateMethodologyCustom && f == 'rate' && ((!(this.d_hrs == 0 && this.d_rate == 0) && this.card !== 'Part' && this.card !== 'Sublet' && this.card !== 'Other') ||
          ((this.card === 'Part' || this.card === 'Other') && !this.isInsuranceRepairYes) ||
          (this.card === 'Sublet' && !(this.isInsuranceRepairYes && !this.isTemplate)))) {

        this.onKeyPressUp(this.card + '-' + f)
      } else {
        console.log(this.card + '-' + f , 'emit')
        this.$emit('onKeyPressLeft', this.card + '-' + f, this.card)
      }
    },
    showOrHideCommentBlock () {
      this.isShowCommentBlock = !this.isShowCommentBlock
    },
    updateDragFromNumberFormatter (bool) {
      this.$emit('updateDrag', bool)
    },
    updateDrag (bool) {
      this.$emit('updateDrag', bool);
    },
    onFocus: function () {
      this.isDrag = false
      this.$emit('updateDrag', false);
      // console.log('focus')
    },
    onBlur: function () {
      // console.log('blur')
      this.isDrag = true
                 this.$emit('updateDrag', true);
    },
    getCaretPosition: function (ctrl) {
      // IE < 9 Support
      if (document.selection) {
        ctrl.focus()
        var range = document.selection.createRange()
        var rangelen = range.text.length
        range.moveStart('character', -ctrl.value.length)
        var start = range.text.length - rangelen
        return start
      }
      // IE >=9 and other browsers
      else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return ctrl.selectionEnd
      } else {
        return 0
      }
    },
    setCaretPosition: function (ctrl, start) {
      // IE >= 9 and other browsers
      if (ctrl.setSelectionRange) {
        ctrl.focus()
        ctrl.setSelectionRange(start, start)
      }
      // IE < 9
      else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange()
        range.collapse(true)
        range.moveEnd('character', start)
        range.moveStart('character', start)
        //range.select();
      }
    },
    menuForRR: function (e) {
      this.$emit('openRightMenuForRR', e)
      this.$emit('openRightMenu', e)
    },
    menuForRepair: function (e) {
      this.$emit('openRightMenuForRepair', e)
      this.$emit('openRightMenu', e)
    },
    menuForPaint: function (e) {
      this.$emit('openRightMenuForPaint', e)
      this.$emit('openRightMenu', e)
    },
    menuForPart: function (e, isNeedCopy) {
      this.$emit('selectedText', { selectedText: this.selectedText, isNeedCopy: isNeedCopy })
      this.$emit('openRightMenuForPart', e)
      this.$emit('openRightMenu', e)
      this.selectedText = ''
    },
    menuForMaterials: function (e) {
      this.$emit('openRightMenuForMaterials', e)
      this.$emit('openRightMenu', e)
    },
    menuForSublet: function (e) {
      this.$emit('openRightMenuForSublet', e)
      this.$emit('openRightMenu', e)
    },
    menuForOther: function (e) {
      this.$emit('openRightMenuForOther', e)
      this.$emit('openRightMenu', e)
    },
    menuForPDR: function (e) {
      this.$emit('openRightMenuForPDR', e)
      this.$emit('openRightMenu', e)
    },
    menuForRemoveLevy: function (e, name) {
      if (name == 'ENVIRONMENT LEVY') {
        this.$emit('openRightMenuForRemoveLevy', e)
        this.$emit('openRightMenu', e)
      }
    },
    adjHrsBlur () {
      //  console.log('tempAdjHours: %s', this.tempAdjHours)
      this.d_adj_hrs = Math.round(this.tempAdjHours * 100) / 100
      // this.tempAdjHours = null;
      // console.log('d_adj_hrs: %s', this.d_adj_hrs)
      // this.emitUpdate();
    },
    totalAdjBlur () {
      console.log('BLUR', this)

      if (this.card === 'Part') {
        this.d_adj_qty = Math.round(this.tempAdj / this.d_cost / (1 + this.d_mup / 100))
      } else if (this.card === 'Sublet') {
        this.d_adj_qty = Math.round(this.tempAdj / this.d_cost)
      } else {
        if (this.tempAdj === null) {
          this.d_adj_hrs = null
        } else if (this.d_rate > 0) {
          this.d_adj_hrs = Math.round(this.tempAdj / this.d_rate * 100) / 100
        }
        this.d_adj_hrs = null
      }

      this.emitUpdate()
      this.onChange()
    },
    emitUpdate: function () {
      let data = {}

      if (this.card == 'Part') {
        data = {
          id: this.d_id,
          name: this.d_name,
          part_num: this.d_part_num,
          type: this.d_type,
          mup: Number(this.d_mup),
          adj_mup: this.d_adj_mup,
          qty: Number(this.d_qty),
          cost: Number(this.d_cost),
          rate: Number(this.d_rate),
          rate2: Number(this.d_rate_2),
          adj_rate: this.d_adj_rate === null ? null : Number(this.d_adj_rate),
          adj_price: this.d_adj_rate === null ? null : Number(this.d_adj_rate),
          total: Number(this.result),
          row_highlight: this.row_highlight,
          is_show_in_jobcard: this.is_show_in_jobcard,
          comments: this.d_comments,
        }
        if (data.type == 'Aftermarket' || data.type == 'Used' || data.type == 'Exchange' || data.type == 'Recondition') {
          data.part_num = data.type
        }
      } else if (this.card == 'Sublet') {
        data = {
          id: this.d_id,
          name: this.d_name,
          repairer: this.d_repairer,
          hrs: 1,
          adj_rate: this.d_adj_rate === null ? null : Number(this.d_adj_rate),
          adj_price: this.d_adj_rate === null ? null : Number(this.d_adj_rate),
          rate: Number(this.d_rate),
          mup: Number(this.d_mup),
          adj_mup: this.d_adj_mup,
          total: Number(this.result),
          row_highlight: this.row_highlight,
          is_show_in_jobcard: this.is_show_in_jobcard,
          comments: this.d_comments,
        }
      } else if (this.card == 'Paint') {
        data = {
          id: this.d_id,
          name: this.d_name,
          paint_type: this.d_paint_type,
          hrs: Number(this.d_hrs),
          adj_hrs: this.d_adj_hrs,
          adj_changed_rate: Number(this.d_adj_changed_rate),
          rate: Number(this.d_rate),
          adj_rate: this.d_adj_rate === null ? null : Number(this.d_adj_rate),
          rate2: Number(this.d_rate_2),
          total: Number(this.result),
          total_manual: this.d_totalManual,
          row_highlight: this.row_highlight,
          is_show_in_jobcard: this.is_show_in_jobcard,
          comments: this.d_comments,
        }
      } else if (this.card == 'Materials') {
        if (this.d_type == 'calculation' && this.d_adj_hrs != null && this.d_adj_rate == null && this.d_hrs > 0 && !this.isFromEio) {

          this.d_adj_rate = this.d_adj_hrs * (this.d_rate / this.d_hrs)
        }
        if (this.d_type == 'manual' && this.d_adj_hrs != null && this.d_adj_rate == null && this.d_hrs > 0) {
          this.d_adj_rate = this.d_adj_hrs * (this.d_rate / this.d_hrs)
        }
        data = {
          id: this.d_id,
          name: this.d_name,
          type: this.type,
          subtype: this.d_subtype,
          isOverrided: this.d_isOverrided,
          hrs: Number(this.d_hrs),
          original_hrs: this.d_original_hrs,
          rs_hrs: Number(this.d_rs_hrs),
          adj_hrs: this.d_adj_hrs,
          rate: Number(this.d_rate),
          rs_rate: Number(this.d_rs_rate),
          adj_rate: this.d_adj_rate,
          original_total: this.d_original_total,
          total: Number(this.result),
          row_highlight: this.row_highlight,
          comments: this.d_comments,
        }
      } else if (this.card == 'PDR') {
        data = {
          id: this.d_id,
          type: this.d_type,
          name: this.d_name,
          material: this.d_material,
          action: this.d_action,
          qty: this.d_qty,
          price: this.d_price,
          total: this.d_total,
          row_highlight: this.row_highlight,
          is_show_in_jobcard: this.is_show_in_jobcard,
          comments: this.d_comments,
        }
        //  console.log('emitUpdate', data)
      } else {
        data = {
          id: this.d_id,
          name: this.d_name,
          rate: Number(this.d_rate),
          adj_rate: this.d_adj_rate === null ? null : Number(this.d_adj_rate),
          rate2: Number(this.d_rate_2),
          hrs: Number(this.d_hrs),
          adj_hrs: this.d_adj_hrs,
          adj_changed_rate: Number(this.d_adj_changed_rate),
          total: Number(this.result),
          total_manual: this.d_totalManual,
          row_highlight: this.row_highlight,
          is_show_in_jobcard: this.is_show_in_jobcard,
          comments: this.d_comments,
        }
      }
      data.isAuthorized = this.item?.isAuthorized
      data.authorizedOn = this.item?.authorizedOn
      data.authorizedBy = this.item?.authorizedBy
      data.internalComment = this.item?.internalComment
      data.externalComment = this.item?.externalComment
      data.adj_approve_status = this.item?.adj_approve_status
      data.material_rate = this.item?.material_rate
      data.addedByAssessor = this.item?.addedByAssessor
      data.rs_rate = this.item?.rs_rate
      data.adj_changed_rate = this.item?.adj_changed_rate
      //    console.log('update:item', data)
      this.$emit('update:item', data)
    },
    emitMultiselect (t) {
      console.log('t', t)
      var vm = this
      setTimeout(() => {
        vm.emitUpdate()
        setTimeout(() => {
          vm.onChange()
        }, 50)
      }, 50)

    },
    emitChange: function (f) {
      if (this.card == 'Part' && (this.d_type == 'Aftermarket' || this.d_type == 'Used' || this.d_type == 'Exchange' || this.d_type == 'Recondition')) {
        this.d_part_num = this.d_type
      }
      this.emitUpdate()
      var vm = this
      setTimeout(() => {
        vm.onChange()
      }, 100)
    },
    onKeyPress: function (event) {
      this.$emit('onKeyPress', event)
    },
    onKeyPressEnter: function (index) {
      this.emitUpdate()
      this.$emit('onKeyPressEnter', index, this.card)
    },
    onKeyPressLeft: function (f, e, t) {
      if (f && f == 'price' && this.isPDRTechnicianUser) {
        f = 'qty'
      }
      if (f && f == 'total' && this.isPDRTechnicianUser) {
        f = 'qty'
      }
      if (e && t && t == 'edit') {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos > 0 && !elem.disabled && !elem.readOnly) {
          return false
        }
      }
      if (e) {
        e.preventDefault()
      }
      if (f == 'adj_price' && (this.card !== 'Materials' ||
          (this.card === 'Materials' && ((this.d_type !== 'manual') || (this.isInsuranceRepairYes && !this.isTemplate))))) {
        this.onKeyPressUp(this.card + '-' + f)
      } else if (this.isEstimateMethodologyCustom && f == 'rate2' && !(this.isInsuranceRepairYes && this.d_adj_hrs === null)) {
        this.onKeyPressUp(this.card + '-' + f)
      } else if (!this.isEstimateMethodologyCustom && f == 'rate2' && (
          (this.card === 'Materials' && !(this.isInsuranceRepairYes && !this.isTemplate) && this.d_type !== 'calculation') ||
          (this.card !== 'Materials'))) {

        this.onKeyPressUp(this.card + '-' + f)
      } else if (!this.isEstimateMethodologyCustom && f == 'rate' && ((!(this.d_hrs == 0 && this.d_rate == 0) && this.card !== 'Part' && this.card !== 'Sublet' && this.card !== 'Other') ||
          ((this.card === 'Part' || this.card === 'Other') && !this.isInsuranceRepairYes) ||
          (this.card === 'Sublet' && !(this.isInsuranceRepairYes && !this.isTemplate)))) {

        this.onKeyPressUp(this.card + '-' + f)
      } else {
        this.$emit('onKeyPressLeft', this.card + '-' + f, this.card)
      }
    },
    onKeyPressRight: function (f, e, t) {
      if (f && f == 'price' && this.isPDRTechnicianUser) {
        f = 'name'
      }

      if (e && t && t == 'edit') {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos < elem.value.length && !elem.disabled && !elem.readOnly) {
          return false
        }
      }
      if (e) {
        e.preventDefault()
      }
      if (f == 'adj_hrs' && this.d_type === 'calculation' && this.card === 'Materials') {
        this.onKeyPressDown(this.card + '-' + f)
      } else if (f == 'name') {
        this.onKeyPressDown(this.card + '-' + f)
      } else {
        this.$emit('onKeyPressRight', this.card + '-' + f, this.card)
      }
    },
    onKeyPressUp: function (f, e) {
      if (e) {
        e.preventDefault()
      }
      this.$emit('onKeyPressUp', this.card + '-' + f, this.card)
    },
    onKeyPressDown: function (f, e) {
      if (e) {
        e.preventDefault()
      }
      this.$emit('onKeyPressDown', this.card + '-' + f, this.card)

    },
    onChange: function () {
      this.$emit('onChange')
    },
    onChangeHrs: function () {
      if (this.card === 'Paint' || this.card == 'Repair') {
        this.emitUpdate()
      }
      this.$emit('onChange')
      this.$emit('onChangeHrs')
    },
    getFocus: function (f, isNeedSelect = false) {
      let hn = null
      console.log(f, this.card, 'get focus')
      switch (f) {
        case this.card + '-name':
          hn = this.$refs[this.card + '-name']
          hn.focus()
          // this.setCaretPosition(hn, 0);
          // hn.select();
          break

        case this.card + '-part_num':
          hn = this.$refs[this.card + '-part_num']
          hn.focus()
          //hn.select();
          break
        case this.card + '-repairer':
          hn = this.$refs[this.card + '-repairer']
          hn.focus()
          break

        case this.card + '-mup':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-mup']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-mup']
            hn.focus()
          }
          break

        case this.card + '-qty':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-qty']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-qty']
            hn.focus()
          }
          break
        case this.card + '-adj_qty':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-adj_qty']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-adj_qty']
            hn.focus()
          }
          break

        case this.card + '-hrs':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-hrs']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-hrs']
            hn.focus()
          }
          break
        case this.card + '-adj_hrs':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-adj_hrs']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-adj_hrs']
            hn.focus()
          }
          break
        case this.card + '-type':
          hn = this.$refs[this.card + '-type'].$el
          hn.focus()
          break

        case this.card + '-paint_type':
          hn = this.$refs[this.card + '-paint_type'].$el
          hn.focus()
          break

        case this.card + '-cost':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-cost']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-cost']
            hn.focus()
          }
          break

        case this.card + '-rate':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-rate']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-rate']
            hn.focus()
          }
          break

        case this.card + '-rate2':
          if (isNeedSelect) {
            let h = this.$refs[this.card + '-rate2']
            if (h._isVue) {
              h.selectAll()
            }
          } else {
            hn = this.$refs[this.card + '-rate2']
            hn.focus()
          }
          break

        case this.card + '-material':
        case this.card + '-action': {
          hn = this.$refs[this.card + '-' + f]
          // console.log(f, hn);
          if (hn) {
            // new panel row
            setTimeout(() => {
              hn.activate()
              // hn.$el.focus();
              // hn.$refs['search'].focus();
            }, 125)
          } else {
            // new item row
            if (f === this.card + '-material') {
              this.$refs[this.card + '-qty'].$refs['input'].focus()
            } else {
              this.$refs[this.card + '-name'].focus()
            }
          }
          break
        }

        case this.card + '-price':
        case this.card + '-adj_price':
        case this.card + '-total': {
          if (this.$refs[f] && this.$refs[f].$refs['input']) {
            if (isNeedSelect) {
              let h = this.$refs[f]
              if (h._isVue) {
                h.selectAll()
              }
            } else {
              hn = this.$refs[f].$refs['input'].focus()
            }
          }
          break
        }

        default:
          hn = this.$refs[this.card + '-name']
          hn.focus()
          // hn.select();
          break
      }

    },
    destroyMe: function () {
      Vue.$destroy(this)
    },
  },
  mounted () {
    this.d_isOverrided = this.isOverrided
        },
        computed: {
            ...mapGetters([
                'isShopManagerUser',
                'isProductionManagerUser',
                'isEstimatorUser',
                'isPDRTechnicianUser',
                'isStatusActive',
            ]),
      isNeedReduceCharacters() {
      let b = false
      if (this.d_name) {
        let lengthWithoutXML = this.d_name.replace(/[&'"<>]/g, '').length;
        let lengthXML = (this.getSameCountString(this.d_name, '&') * 5) + (this.getSameCountString(this.d_name, "'") * 6) + (this.getSameCountString(this.d_name, '"') * 6)
         + (this.getSameCountString(this.d_name, '<') * 4) + (this.getSameCountString(this.d_name, '>') * 4)
        let length = lengthWithoutXML + lengthXML
        b = length > 60
        // console.log('isNeedReduceCharacters',length);
      }
      return this.properties.isFromIag &&  b
    },
    isFromEio() {
      return this.properties?.isFromEio
    },
    isEditableAdjRate() {
      return (this.isAuthorisePage && this.isEstimateMethodologyCustom) || !this.isAuthorisePage
    },
    isDisabledFields() {
      return !this.isStatusActive || this.isAuthorizedItemList || (this.d_isChecked && this.isAuthorisePage)
    },
    isAdjDisabled() {
      return (this.item?.adj_approve_status === 'reported' || this.item?.adj_approve_status === 'approved' || this.item?.adj_approve_status === 'denied')
    },
    getMaterialsHrs1() {
      let hrs = null
      if (this.d_type === 'manual') {
        hrs = this.d_hrs
      } else {
        if (this.d_original_hrs && this.d_original_hrs !== null) {
          hrs = this.d_original_hrs
        } else if (this.d_rs_hrs && this.d_rs_hrs !== null) {
          hrs = this.d_rs_hrs
        } else {
          hrs = this.d_hrs
        }
      }
      return hrs
    },
    getMaterialsHrs3() {
      let hrs = null
      if (this.d_type === 'manual') {
        hrs = this.d_hrs
      } else {
        if (this.d_original_hrs && this.d_original_hrs !== null) {
          hrs = this.d_original_hrs
        } else if (this.d_rs_hrs && this.d_rs_hrs !== null) {
          hrs = this.d_rs_hrs
        } else {
          hrs = this.d_hrs
        }
      }
      return hrs
    },
    isErrorMaterialCheckbox1 () {
      let hrs = this.getMaterialsHrs1
      let rate = this.getMaterialsRate1

      return ((this._isEmpty2(rate) || Number(rate) == 0) || (this._isEmpty2(hrs) || Number(hrs) == 0)) && this.d_isChecked
    },
    getMaterialsRate1() {
      let rate = null


      if (this.d_type !== 'calculation') {
        rate = this.d_rate
      } else {
        if (this.d_original_total && this.d_original_total !== null) {
          rate = this.d_original_total
        } else if (this.d_rs_rate && this.d_rs_rate !== null) {
          rate = this.d_rs_rate
        } else {
          rate = this.result
        }
      }

      return rate
    },
    isErrorMaterialCheckbox2 () {
      let hrs = '999'
      let rate = this.getMaterialsRate2

      return ((this._isEmpty2(rate) || Number(rate) == 0) || (this._isEmpty2(hrs) || Number(hrs) == 0)) && this.d_isChecked
    },
    getMaterialsRate2() {
      let rate = null

      if (this.d_type !== 'calculation') {
        rate = this.d_rate
      } else {
        if (this.d_rs_rate && this.d_rs_rate !== null) {
          rate = this.d_rs_rate
        } else {
          rate = this.result
        }
      }
      return rate
    },
    isErrorMaterialCheckbox3 () {
      let hrs = this.getMaterialsHrs3
      let rate = this.getMaterialsRate3

      return ((this._isEmpty2(rate) || Number(rate) == 0) || (this._isEmpty2(hrs) || Number(hrs) == 0)) && this.d_isChecked
    },
    getMaterialsRate3() {
      let rate = null

      if (this.d_type !== 'calculation') {
        rate = this.d_rate
      } else {
        if (this.d_rs_rate && this.d_rs_rate !== null) {
          rate = this.d_rs_rate
        } else {
          rate = this.result
        }
      }
      return rate
    },
    isCanRemove () {
      return !this.isAuthorizedItemList
    },
    d_comments () {
      let comments = {}

      comments.adjComment = (this.comments && this.comments.adjComment) ? this.comments.adjComment : null
      comments.manualComment = (this.comments && this.comments.manualComment) ? this.comments.manualComment : null
      comments.manualCommentAuthor = (this.comments && this.comments.manualCommentAuthor) ? this.comments.manualCommentAuthor : null
      comments.manualPdfComment = (this.comments && this.comments.manualPdfComment) ? this.comments.manualPdfComment : null
      comments.manualPdfCommentAuthor = (this.comments && this.comments.manualPdfCommentAuthor) ? this.comments.manualPdfCommentAuthor : null

      return comments
    },
    isHasPartssearchLinkedPart () {
      return !!(this.item?.partssearchLinkedId && this.item?.partssearchLinkedPartName);
    },
    isHasPartssearchComment () {
      return !!this.item.partssearchComment
    },
    isRedHighlightRow () {
      if (this.isSupplementDeletion) {
        return true
      }
      if (this.isNegativeValueCanBeRed) {
        if (['RR', 'Repair', 'Paint'].includes(this.card) && this.d_hrs && this.d_hrs < 0) {
          return true
        } else if (['Part', 'Sublet', 'Other'].includes(this.card) && this.d_rate && this.d_rate < 0) {
          return true
        } else if (this.card == 'Materials') {
          if (this.d_original_hrs && this.d_original_hrs !== 0 && this.d_original_hrs < 0) {
            return true
          } else if (this.d_rs_hrs && this.d_rs_hrs !== 0 && this.d_rs_hrs < 0) {
            return true
          } else if (this.d_hrs && this.d_hrs < 0) {
            return true
          }
        }
      }
      return false
    },
    getClass () {
      if (this.isAuthorisePage && !this.isEstimateMethodologyDollars) {
        return
      }
      let adj = this.resultAdj
      let original = 0

      if (this.isEstimateMethodologyCustom) {
        original = this.resultTotalManual
      } else if (this.d_hrs == 0 && this.d_rate == 0) {
        original = this.d_rate_2
      } else {
        original = this.result
      }
      if (Number(adj) > Number(original)) {
        return 'adj-color-green'
      } else if (Number(adj) < Number(original)) {
        return 'adj-color-red'
      }
    },
          isHasAdjChange() {
            if (this.iagOrmItem && this.item2 && this.card !== 'PDR' &&
                ((this.item2.rate && this.iagOrmItem.hourlyRate && Number(this.item2.rate) !== Number(this.iagOrmItem.hourlyRate)) ||
                    (this.item2.hrs && this.iagOrmItem.hours && Number(this.item2.hrs) !== Number(this.iagOrmItem.hours)))) {
              return true;
            }
            return false;
          },
          isChangedName() {
            return this.iagOrmItem && this.iagOrmItem.description && this.item2 && this.item2.name && this.iagOrmItem.description.trim() !== this.item2.name.trim();
          },
          isHasOrmComment() {
            return this.iagOrmItem && this.iagOrmItem.comment && this.iagOrmItem.comment !== null && this.iagOrmItem.comment.trim();
          },
          isHasOrmCommentWithChangedName() {
            return this.isHasOrmComment && this.isChangedName;
          },
          isHasOrmCommentWithoutChangedName() {
            return this.isHasOrmComment && !this.isChangedName;
          },
          iagOrmItem() {
            let self = this;
            let item = _.filter(this.iagOrmItems, function (ormItem) {
              return ormItem.estimateItemId == self.item2.id;
            });
            return item[0];
          },
          isADJMoreThanOriginal () {
            let adj = this.resultAdj
            let original = 0

            if (this.isEstimateMethodologyCustom) {
              original = this.resultTotalManual
            } else if (this.d_hrs == 0 && this.d_rate == 0) {
              original = this.d_rate_2
            } else {
              original = this.result
            }
            if (Number(adj) > Number(original)) {
              return true
            }
            return false
          },
    isDisabledRates () {
      return !!(this.properties && (this.properties.isFromIag || (this.properties.isFromEio && !this.isEstimateMethodologyDollars)) && !this.properties.isRatesEditable)
    },
    isDisabledRatesRR () {
      return (this.properties.isFromIag || (this.properties.isFromEio && !this.isEstimateMethodologyDollars)) && !this.properties.isRatesRREditable
    },
    isDisabledRatesRepair () {
      return (this.properties.isFromIag || (this.properties.isFromEio && !this.isEstimateMethodologyDollars)) && !this.properties.isRatesRepairEditable
    },
    isDisabledRatesPaint () {
      return (this.properties.isFromIag || (this.properties.isFromEio && !this.isEstimateMethodologyDollars)) && !this.properties.isRatesPaintEditable
    },
    isHasEstimateComment () {
      return !!(this.d_comments && !_.isEmpty(this.d_comments.manualPdfComment))
    },
    isHasInternalComment () {
      return false && !_.isEmpty(this.item?.internalComment) && this.item?.authorizedBy?.name
    },
    isShowEIOMessage () {
      return (this.isFromEio && this.d_type == 'calculation' && this.resultAdj1 !== null && this.getMaterialsRate1 != this.resultAdj1 && this.card === 'Materials')
    },
    isHasExternalComment () {
      return false && !_.isEmpty(this.item?.externalComment) && this.item?.authorizedBy?.name
    },
    isHasManualComment () {
      return !!(this.d_comments && !_.isEmpty(this.d_comments.manualComment))
    },
    isHasADJComment () {
      return !!(this.d_comments && !_.isEmpty(this.d_comments.adjComment)) || !_.isEmpty(this.item?.externalComment)
    },
    isHasComment () {
      return this.isShowEIOMessage || !!(this.d_comments && (!_.isEmpty(this.d_comments.adjComment) || !_.isEmpty(this.d_comments.manualComment))) || (!_.isEmpty(this.item?.internalComment) || !_.isEmpty(this.item?.externalComment))
    },
    isHighlightRow () {
      return !!(!this.isRedHighlightRow && this.row_highlight && this.row_highlight !== null)
    },
    computedClassForSelectedRow () {
      if (this.isSelectedRow && this.isDrag) {
        return 'selected-row'
      }
    },
          computedClassForEstimateItemWithIagOrm() {
            // if (this.isChangedName || this.isHasAdjChange) {
            //     return 'highlight-comment';
            // } else if (this.isHasOrmCommentWithoutChangedName) {
            //     return 'highlight-flag';
            // }
          },
    computedClassForHighlightRow () {
      if (this.isRedHighlightRow) {
        return 'highlight-red-deleted'
      }
      if (!this.isHighlightRow) {
        return
      }
      if (this.row_highlight == 'ffff00') {
        return 'highlight-yellow'
      } else if (this.row_highlight == '00b050') {
        return 'highlight-green'
      } else if (this.row_highlight == 'f069ed') {
        return 'highlight-purple'
      }
    },
    computedPaintType: {
      get: function () {
        let vm = this
        try {
          let item = _.find(vm.optionsPaintMaterials, function (itm) {
            return itm.key == vm.d_paint_type
          })
          return item
        } catch (e) {
          console.log('e', e)
          return []
        }
      },
      set: function (item) {
        console.log('item', item)
        if (item && item.key) {
          this.d_paint_type = item.key
        }
      },
    },
    computedPartType: {
      get: function () {
        let vm = this
        try {
          let item = _.find(vm.optionsPartsType, function (itm) {
            return itm.key == vm.d_type
          })
          return item
        } catch (e) {
          console.log('e', e)
          return []
        }
      },
      set: function (item) {
        console.log('item', item)
        if (item && item.key) {
          this.d_type = item.key
        }
        if (this.card == 'Part' && (this.d_type == 'Aftermarket' || this.d_type == 'Used' || this.d_type == 'Exchange' || this.d_type == 'Recondition')) {
          this.d_part_num = this.d_type
        }
      },
    },
    computedPDRAction: {
      get: function () {
        let vm = this
        try {
          let item = _.find(vm.optionsPDRActions, function (itm) {
            return itm.key == vm.d_action
          })
          return item
        } catch (e) {
          console.log('e', e)
          return []
        }
      },
      set: function (item) {
        console.log('item', item)
        this.d_action = item.key
      },
    },
    isOv () {
      return this.isOverrided
    },
    isOverridedMaterialTotal () {
      return true
      if (this.d_type == 'calculation' && this.d_isOverrided == true) {
        return true
      }
      return false
    },
    isEstimateMethodologyCustom: function () {
      return (this.estimateMethodology === 'custom')
    },
    isEstimateMethodologyDollars(){
      return (this.estimateMethodology === 'dollars')
    },
    isNotEditText: function () {
      if (this.isDrag) {
        return true
      }
      return false
    },
    result: function () {
      if (this.card === 'Part') {
        if (!this.d_mup) {
          this.d_mup = 0
        }
        return accounting.toFixed(this.d_rate * this.d_qty * (1 + parseFloat(this.d_mup) / 100), 2)
      } else if (this.card === 'Materials') {
        return accounting.toFixed(this.d_rate, 2)
      } else if (this.card === 'Sublet') {
        if (!this.d_mup) {
          this.d_mup = 0
        }
        return accounting.toFixed(this.d_rate * (1 + parseFloat(this.d_mup) / 100), 2)
      } else if (this.card === 'PDR') {
        return accounting.toFixed(this.d_price * this.d_qty, 2)
      } else {
        if ((this.card === 'RR' || this.card === 'Repair' || this.card === 'Paint') && this.isAuthorisePage && !this.isEstimateMethodologyDollars) {
          return accounting.toFixed(this.d_rs_rate * this.d_hrs, 2)
        }
        return accounting.toFixed(this.d_rate * this.d_hrs, 2)
      }
    },
    adjHrs: {
      get () {
        return this.d_adj_hrs
      },
      set (newVal) {
        this.tempAdjHours = newVal
      }
    },
    resultAdjOrigin() {
      if ((this.card === 'RR' || this.card === 'Repair' || this.card === 'Paint') && this.isAuthorisePage && !this.isEstimateMethodologyDollars) {
        let hrs = this.adj_hrs || this.d_hrs || 0
        return Number(accounting.toFixed(hrs * this.d_rs_rate, 2))
      }
      return 0
    },
    resultAdj: {
      get () {
        if ((this.card === 'RR' || this.card === 'Repair' || this.card === 'Paint') && this.isAuthorisePage && !this.isEstimateMethodologyDollars) {
          if (this.isEstimateMethodologyCustom && this.d_adj_rate) {
            return this.d_adj_rate
          }
          let hrs = this.adj_hrs || this.d_hrs || 0
          return accounting.toFixed(hrs * this.d_adj_changed_rate, 2)
        }
        if (this.card === 'Part' && this.d_adj_qty != '') {
          return accounting.toFixed(this.d_rate * this.d_adj_qty * (1 + this.d_mup / 100), 2)
        } else if (this.d_adj_hrs != '' && this.d_adj_hrs != null) {
          return accounting.toFixed(this.d_rate * this.d_adj_hrs, 2)
        } else if (this.card === 'Paint' && this.d_adj_total == 0){
          return 0
        } else if (this.card === 'Paint' && this.d_adj_hrs === null){
          return null
        } else if (this.card === 'Paint' &&  this.d_adj_hrs == 0){
          return 0
        }
        return this.d_adj_rate
      },
      set (val) {
        this.tempAdj = val
        if (this.isEnableManualAdj) {
          this.d_adj_rate = val
        }
      }
    },
    resultAdj1: {
      get () {
        if (this.isFromEio && this.d_type == 'calculation') {
          if (this.d_adj_rate && this.d_original_total && this.d_adj_rate == this.d_original_total) {
            return null
          } else if (this.d_adj_rate) {
            return this.d_adj_rate
          } else if (this.d_original_total) {
            return this.d_original_total
          }
          return null
        } else {
          let hrs = this.getMaterialsHrs1
          let rate = this.item.material_rate
          if (this.d_adj_hrs == null) {
            return  null
          }
          // if (this.d_adj_hrs == 0){
          //   return 0
          // }
          if (hrs != '' && hrs != null && hrs>0) {
            return accounting.toFixed(rate * this.d_adj_hrs, 2)
          }

          return this.d_adj_rate
        }
      },
      set (val) {
        if (!(this.isFromEio && this.d_type == 'calculation')) {
          this.tempAdj = val
          if (this.isEnableManualAdj) {
            this.d_adj_rate = val
          }
        }
      }
    },
    resultTotalManual: {
      get: function () {
        if (this.isEstimateMethodologyCustom && (this.d_totalManual !== null && this.d_totalManual !== undefined)) {
          return this.d_totalManual
        } else {
          return this.result
        }
      },
      set: function (value) {
        if (value == '') {
          this.d_totalManual = null
        } else {
          this.d_totalManual = value
          this.d_hrs = 0
          this.d_rate = 0
        }
      },
    },
  },
  watch: {
    isMovedElement: function (val) {
      if (val) {
        this.isSelectedRow = false
      }
    },
    row_highlight: {
      handler (newVal) {
        console.log('row_highlight', newVal)
      },
    },
    ['item.isAuthorized']: {
      handler (newVal) {
        this.d_isChecked = newVal
      },
      immediate: true,
    },
    adj_hrs: {

      handler (newVal) {
        this.d_adj_hrs = newVal
      },
      immediate: true,
    },
    rate: {
      handler (newVal) {
        this.d_rate = newVal
      },
      immediate: true,
    },
    adj_changed_rate: {
      handler (newVal) {
        this.d_adj_changed_rate = newVal
      },
      immediate: true,
    },
    adj_rate: {
      handler (newVal) {
        this.d_adj_rate = newVal
      },
      immediate: true,
    },
  },
  components: {
    NumberFormatter,
    Multiselect,
  }
}
</script>

<template>
  <!--
    RR
    -->
  <div v-if="card === 'RR'"
       v-on:keypress.enter="onKeyPressEnter(index)"
       class="qtit estimate-items-content"
       v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]"
       @dragenter="onDragEnter(index)"
       @dragleave="onDragLeave(index)"
       @mousedown="onMouseDown"
       @mousemove="onMouseMove"
       @mouseup="onMouseUp"
       @dragover="$emit('dragover', item)"
  >

    <div @contextmenu.prevent="menuForRR" class="estimate-items-border estimate-items-name-all">
      <div class="d-flex">
        <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
        <input
            type="text"
            :ref="card + '-name'"
            @focus="onFocus"
            @dblclick="onFocus"
            @mouseover="updateDrag(false)"
            @mouseleave="updateDrag(true)"
            @click="onFocus"
            @blur="onBlur"
            @change="onChange"
            v-on:keypress="onKeyPress($event)"
            v-on:keydown.left="(isEstimateMethodologyDollars ?  onKeyPressLeft('adj_price',$event,'edit') : ((isEstimateMethodologyCustom && isInsuranceRepairYes && d_adj_hrs === null)? onKeyPressLeft('adj_price',$event,'edit'): (d_hrs==0 && d_rate==0)? onKeyPressLeft('rate2',$event,'edit'): onKeyPressLeft('rate',$event,'edit'))) "
            v-on:keydown.right="onKeyPressRight(isEstimateMethodologyDollars ? 'rate' : 'hrs',$event,'edit')"
            v-on:keydown.up="onKeyPressUp('name')"
            v-on:keydown.down="onKeyPressDown('name')"
            :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
            class="noselect form-control "
            v-model="d_name"
            v-on:input="emitUpdate"
        >
        <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note'
           style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
        <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note'
           style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
        <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note'
           style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
      </div>
    </div>
    <div v-if="!isEstimateMethodologyDollars" @contextmenu.prevent="menuForRR" class="estimate-items-border estimate-items-hrs">
      <number-formatter
          :ref="card + '-hrs'"
          @updateDrag="updateDragFromNumberFormatter"
          @onChange="onChangeHrs"
          @onKeyPress="onKeyPress"
          :prev-cell="'name'"
          :next-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'rate'): 'rate'"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('hrs')"
          @onKeyDown="onKeyPressDown('hrs')"
          type="text"
          v-model="d_hrs"
          :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"
          :isDisabled="isDisabledFields"
          v-on:blur="emitUpdate"
          class="noselect form-control numeric"
          format="0,0.00">
      </number-formatter>
    </div>
    <div v-if="isInsuranceRepairYes && !isEstimateMethodologyDollars" @contextmenu.prevent="menuForRR" class="estimate-items-border estimate-items-adj">
            <span v-if="isEstimateMethodologyCustom && d_adj_rate !== null" class=""
            >{{ d_adj_hrs }}</span>
      <number-formatter
          v-else
          :ref="card + '-adj_hrs'"
          :prev-cell="'hrs'"
          :next-cell="'rate'"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_hrs')"
          @onKeyDown="onKeyPressDown('adj_hrs')"
          :style="{color: d_adj_hrs > d_hrs ? '#2BAE66FF': ((d_adj_hrs < d_hrs) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_hrs"
          class="noselect form-control numeric adj"
          format="0,0.00"
          v-on:blur="emitUpdate"
          v-on:onChange="onChangeHrs"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (d_adj_rate != null && !isAuthorisePage)"
      ></number-formatter>
    </div>
    <div v-if="!isEstimateMethodologyDollars" @contextmenu.prevent="menuForRR" class="estimate-items-border estimate-items-total">
      <number-formatter
          v-if="isAuthorisePage"
          :ref="card + '-rate'"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'hrs'): 'hrs'"
                        :next-cell="(isEstimateMethodologyCustom || (d_hrs==0 && d_rate==0))? 'rate2': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_adj_changed_rate"
                        v-on:blur="emitUpdate"
                        :style="{color: d_adj_changed_rate > d_rs_rate ? '#2BAE66FF': ((d_adj_changed_rate < d_rs_rate) ? 'red' : 'black')}"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesRR"
                        class="noselect form-control numeric"
                        :class="{'error-authorisation': (_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) && d_isChecked}"
                        format="$0,0.00"></number-formatter>
      <number-formatter v-else
                        :ref="card + '-rate'"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'hrs'): 'hrs'"
                        :next-cell="(isEstimateMethodologyCustom || (d_hrs==0 && d_rate==0))? 'rate2': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_rate"
                        v-on:blur="emitUpdate"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesRR"
                        class="noselect form-control numeric"
                        :class="{'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-if="isEstimateMethodologyDollars" @contextmenu.prevent="menuForRR">
      <number-formatter :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'name'"
                        :next-cell="isInsuranceRepairYes ?'adj_price' : 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesRR"
                        type="text"
                        v-model="d_rate"
                        :class="{'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric dollar-total-input"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-else-if="isEstimateMethodologyCustom" @contextmenu.prevent="menuForRR"
         class="estimate-items-border estimate-items-total">
      <number-formatter :ref="card + '-rate2'"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'rate'"
                        :next-cell="(isInsuranceRepairYes && d_adj_hrs === null)?'adj_price':'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate2')"
                        @onKeyDown="onKeyPressDown('rate2')"
                        type="text"
                        :class="{'color-red': isRedHighlightRow}"
                        v-model="resultTotalManual"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        format="$0,0.00"
                        :isTotalManual="true"
      >
      </number-formatter>
    </div>
    <div v-else-if="(d_hrs==0 && d_rate==0)" @contextmenu.prevent="menuForRR"
         class="estimate-items-border estimate-items-total">
      <number-formatter :ref="card + '-rate2'"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'rate'"
                        :next-cell="'name'"
                        :class="{'color-red': isRedHighlightRow}"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate2')"
                        @onKeyDown="onKeyPressDown('rate2')"
                        type="text"
                        v-model="d_rate_2"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="$0,0.00"
      >
      </number-formatter>
    </div>
    <div v-else @contextmenu.prevent="menuForRR" class="estimate-items-border estimate-items-total">
      <span :class="{'color-red': isRedHighlightRow}"
            class="noselect form-control numeric total-item-hover additional-style-input-total">{{ result | formatMoney }}</span>
    </div>
    <div v-if="isInsuranceRepairYes" @contextmenu.prevent="menuForRR"
         class="estimate-items-content-adj estimate-items-border">

      <number-formatter
          v-if="isEstimateMethodologyDollars"
          :ref="card + '-adj_price'"
          :prev-cell="'rate'"
          :next-cell="'name'"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :style="{color: d_adj_rate > d_rate ? '#2BAE66FF': ((d_adj_rate < d_rate) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_rate"
          class="noselect form-control numeric adj"
          format="0,0.00"
          v-on:blur="emitUpdate"
          v-on:onChange="onChangeHrs"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
      ></number-formatter>

      <number-formatter
          @updateDrag="updateDragFromNumberFormatter"
          :ref="card + '-adj_price'"
          v-else-if="isEstimateMethodologyCustom && d_adj_hrs === null"
          :prev-cell="'rate2'"
          :next-cell="'name'"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :class="[getClass]"
          :isTotalManual="true"
          type="text"
          v-model="resultAdj"
          class="noselect form-control numeric adj"
          format="$0,0.00"
          :style="getStyleForAdjTotal()"
          :isDisabled="!isEditableAdjRate || !isEnableManualAdj || d_adj_hrs != null || isDisabledFields || (isAuthorisePage && !isItemNew && !isEditableAdjRate)"
          v-on:blur="emitUpdate"
          v-on:onChange="onChange"
      ></number-formatter>
      <span v-else-if="resultAdj !== null" class="noselect form-control numeric"
            :class="[getClass]"
            :style="getStyleForAdjTotal()"
            style="border:none; padding-top: 10px;">{{ resultAdj | formatMoney }}</span>
    </div>

    <div class="estimate-items-content-btn-remove estimate-items-border d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon"
              :class="{'error-authorisation-checkbox': ((isEstimateMethodologyDollars && (_isEmpty2(d_rate) || Number(d_rate) == 0)) || (!isEstimateMethodologyDollars && ((_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)))) && d_isChecked}">
              <div
                  v-if="((isEstimateMethodologyDollars && (_isEmpty2(d_rate) || Number(d_rate) == 0)) || (!isEstimateMethodologyDollars && ((_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)))) && d_isChecked">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>

      <div>
        <a v-if="isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForRR" class="comment-block"
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment" class="comment-text">
        <span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>

  <!--
    Repair
-->
  <div v-else-if="card === 'Repair'"
       @mousedown="onMouseDown"
       @mousemove="onMouseMove"
       @mouseup="onMouseUp"
       v-on:keypress.enter="onKeyPressEnter(index)" class="qtit estimate-items-content"
       v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]">
    <div @contextmenu.prevent="menuForRepair" class="d-flex estimate-items-border estimate-items-name-all">
      <!--<i class="fa fa-arrows drag-handle" aria-hidden="true" title="drag this row to change its position in the list"></i>-->
      <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
      <input v-if="isEstimateMethodologyCustom"
                    type="text"
                   :ref="card + '-name'"
                   @change="onChange"
                   @focus="onFocus"
                   @mouseover="updateDrag(false)"
                   @mouseleave="updateDrag(true)"
                   @dblclick="onFocus"
                   @click="onFocus"
                   @blur="onBlur"
                   v-on:keypress="onKeyPress($event)"
                   v-on:keydown.left="(isEstimateMethodologyDollars ?  onKeyPressLeft('adj_price',$event,'edit')  : (isInsuranceRepairYes && d_adj_hrs === null? onKeyPressLeft('adj_price',$event,'edit'): onKeyPressLeft('rate2',$event,'edit')))"
                   v-on:keydown.right="onKeyPressRight(isEstimateMethodologyDollars ? 'rate' : 'hrs',$event,'edit')"
                   v-on:keydown.up="onKeyPressUp('name')"
                   v-on:keydown.down="onKeyPressDown('name')"
                   class="noselect form-control"
                   v-model="d_name"
                   v-on:input="emitUpdate"
                   :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
            >
            <input v-else
                    type="text"
                   :ref="card + '-name'"
                   @change="onChange"
                   @focus="onFocus"
                   @mouseover="updateDrag(false)"
                   @mouseleave="updateDrag(true)"
                   @dblclick="onFocus"
                   @click="onFocus"
                   @blur="onBlur"
                   :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
                   v-on:keypress="onKeyPress($event)"
                   v-on:keydown.left="(isEstimateMethodologyDollars ?  onKeyPressLeft('adj_price',$event,'edit')  :(d_hrs==0 && d_rate==0)?onKeyPressLeft('rate2',$event,'edit'): onKeyPressLeft('rate',$event,'edit'))"
                   v-on:keydown.right="onKeyPressRight(isEstimateMethodologyDollars ? 'rate' : 'hrs',$event,'edit')"
                   v-on:keydown.up="onKeyPressUp('name')"
                   v-on:keydown.down="onKeyPressDown('name')"
                   class="noselect form-control"
                   v-model="d_name"
                   v-on:input="emitUpdate"
            >
            <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
            <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
            <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
        </div>
        <div v-if="!isEstimateMethodologyDollars" @contextmenu.prevent="menuForRepair" class="estimate-items-border estimate-items-hrs">
            <number-formatter :ref="card + '-hrs'"
                              @onChange="onChangeHrs"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'name'"
                        :next-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'rate'): 'rate'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('hrs')"
                        :isDisabled="isDisabledFields"
                        @onKeyDown="onKeyPressDown('hrs')"
                        type="text"
                        v-model="d_hrs"
                        :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="0,0.00"></number-formatter>
    </div>
    <div v-if="!isEstimateMethodologyDollars && isInsuranceRepairYes" @contextmenu.prevent="menuForRepair"
         class="estimate-items-border estimate-items-adj">
      <span v-if="isEstimateMethodologyCustom && d_adj_rate !== null" class="">{{ d_adj_hrs }}</span>
      <number-formatter
          v-else
          :ref="card + '-adj_hrs'"
          :prev-cell="'hrs'"
          :next-cell="'rate'"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_hrs')"
          @onKeyDown="onKeyPressDown('adj_hrs')"
          :style="{color: d_adj_hrs > d_hrs ? '#2BAE66FF': ((d_adj_hrs < d_hrs) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_hrs"
          class="noselect form-control numeric adj"
          format="0,0.00"
          v-on:blur="emitUpdate"
          v-on:onChange="onChangeHrs"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (d_adj_rate != null && !isAuthorisePage)"
      ></number-formatter>
    </div>
    <div v-if="!isEstimateMethodologyDollars" @contextmenu.prevent="menuForRepair"
         class="estimate-items-border estimate-items-total">
       <number-formatter v-if="isAuthorisePage"
                         :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'hrs'):'hrs'"
                        :next-cell="(isEstimateMethodologyCustom || (d_hrs==0 && d_rate==0))? 'rate2': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesRepair"
                        type="text"
                        v-model="d_adj_changed_rate"
                         :style="{color: d_adj_changed_rate > d_rs_rate ? '#2BAE66FF': ((d_adj_changed_rate < d_rs_rate) ? 'red' : 'black')}"
                        :class="{'error-authorisation': (_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
       <number-formatter v-else
                         :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'hrs'):'hrs'"
                        :next-cell="(isEstimateMethodologyCustom || (d_hrs==0 && d_rate==0))? 'rate2': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesRepair"
                        type="text"
                        v-model="d_rate"
                        :class="{'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
    </div>

    <div v-if="isEstimateMethodologyDollars" @contextmenu.prevent="menuForRepair" class="estimate-items-border estimate-items-total">
       <number-formatter :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'name'"
                        :next-cell="isInsuranceRepairYes ?'adj_price' : 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesRepair"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_rate"
                        v-on:blur="emitUpdate"
                        :class="{'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-else-if="isEstimateMethodologyCustom" @contextmenu.prevent="menuForRepair"
         class="estimate-items-border estimate-items-rate">
       <number-formatter :ref="card + '-rate2'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'rate'"
                        :next-cell="isInsuranceRepairYes && d_adj_hrs === null? 'adj_price': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate2')"
                        @onKeyDown="onKeyPressDown('rate2')"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        type="text"
                        :class="{'color-red': isRedHighlightRow}"
                        v-model="resultTotalManual"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="$0,0.00"
                        :isTotalManual="true"
      ></number-formatter>
    </div>
    <div v-else-if="(d_hrs==0 && d_rate==0)" @contextmenu.prevent="menuForRepair"
         class="estimate-items-border estimate-items-total">
       <number-formatter :ref="card + '-rate2'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'rate'"
                        :next-cell="'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate2')"
                        @onKeyDown="onKeyPressDown('rate2')"
                        type="text"
                        :class="{'color-red': isRedHighlightRow}"
                        v-model="d_rate_2"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-else @contextmenu.prevent="menuForRepair" class="estimate-items-border estimate-items-total">
      <span :class="{'color-red': isRedHighlightRow}"
            class="noselect form-control numeric total-item-hover">{{ result | formatMoney }}</span>
    </div>
    <div v-if="isInsuranceRepairYes" @contextmenu.prevent="menuForRepair"
         class="estimate-items-border estimate-items-content-adj">
      <number-formatter
          v-if="isEstimateMethodologyDollars"
          :ref="card + '-adj_price'"
          :prev-cell="'rate'"
          :next-cell="'name'"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :style="{color: d_adj_rate > d_rate ? '#2BAE66FF': ((d_adj_rate < d_rate) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_rate"
          class="noselect form-control numeric adj"
          format="0,0.00"
          v-on:blur="emitUpdate"
          v-on:onChange="onChangeHrs"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
      ></number-formatter>
      <number-formatter
          v-else-if="isEstimateMethodologyCustom && d_adj_hrs === null"
          :prev-cell="'rate2'"
          :next-cell="'name'"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :class="[getClass]"
          :ref="card + '-adj_price'"
          :isTotalManual="true"
          type="text"
          v-model="resultAdj"
          :style="getStyleForAdjTotal()"
          class="noselect form-control numeric adj"
          format="$0,0.00"
          :isDisabled="!isEditableAdjRate || !isEnableManualAdj || d_adj_hrs != null || isDisabledFields || (isAuthorisePage && !isItemNew && !isEditableAdjRate)"
          v-on:blur="emitUpdate"
          v-on:onChange="onChange"
      ></number-formatter>
      <span v-else-if="resultAdj !== null" class="noselect form-control numeric"
            :class="[getClass]"
            :style="getStyleForAdjTotal()"
            style="border:none; padding-top: 10px;">{{ resultAdj | formatMoney }}</span>
    </div>
    <div @contextmenu.prevent="menuForRepair" class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon"
              :class="{'error-authorisation-checkbox': ((isEstimateMethodologyDollars && (_isEmpty2(d_rate) || Number(d_rate) == 0)) || (!isEstimateMethodologyDollars && ((_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)))) && d_isChecked}">
              <div
                  v-if="((isEstimateMethodologyDollars && (_isEmpty2(d_rate) || Number(d_rate) == 0)) || (!isEstimateMethodologyDollars && ((_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)))) && d_isChecked">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForRepair" class="comment-block"
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment" class="comment-text">
        <span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>

  <!--
Paint
-->
    <div v-else-if="card === 'Paint'" @mousedown="onMouseDown" @mouseup="onMouseUp" @mousemove="onMouseMove" v-on:keypress.enter="onKeyPressEnter(index)" class="qtit estimate-items-content"
         v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]">
        <div @contextmenu.prevent="menuForPaint($event, index)" class="estimate-items-border estimate-items-name-all">
            <div class="td-wrapper">
                <div class="td-group d-flex">
                    <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
                    <input v-if="isEstimateMethodologyCustom"
                           type="text"
                           :ref="card + '-name'"
                           @mouseover="updateDrag(false)"
                           @mouseleave="updateDrag(true)"
                               @change="onChange"
                               @focus="onFocus"
                               @dblclick="onFocus"
                               @click="onFocus"
                           :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
                               @blur="onBlur"
                               v-on:keypress="onKeyPress($event)"
                               v-on:keydown.left="(isEstimateMethodologyDollars ?  onKeyPressLeft('adj_price',$event,'edit') : isInsuranceRepairYes && d_adj_hrs === null? onKeyPressLeft('adj_price',$event,'edit'): onKeyPressLeft('rate2',$event,'edit'))"
                               v-on:keydown.right="onKeyPressRight(isEstimateMethodologyDollars ? 'rate' : 'paint_type',$event,'edit')"
                               v-on:keydown.up="onKeyPressUp('name')"
                               v-on:keydown.down="onKeyPressDown('name')"
                           class="noselect form-control"
                               v-model="d_name"
                               v-on:input="emitUpdate"
                        >
                        <input
                                v-else
                                type="text"
                                :ref="card + '-name'"
                            @mouseover="updateDrag(false)"
                            @mouseleave="updateDrag(true)"
                                @change="onChange"
                                @focus="onFocus"
                                @dblclick="onFocus"
                                @click="onFocus"
                            :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
                                @blur="onBlur"
                                v-on:keypress="onKeyPress($event)"
                                v-on:keydown.left="(d_hrs==0 && d_rate==0)?onKeyPressLeft('rate2',$event,'edit'): onKeyPressLeft('rate',$event,'edit')"
                                v-on:keydown.right="onKeyPressRight('paint_type',$event,'edit')"
                                v-on:keydown.up="onKeyPressUp('name')"
                                v-on:keydown.down="onKeyPressDown('name')"
                            class="noselect form-control"
                            v-model="d_name"
                            v-on:input="emitUpdate"
                    >
                    <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
                    <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
                    <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
                </div>
                <!--                <a href="#" v-if="index === 'partid-1'"-->
                <!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Comment text here', placement:'leftbottom'}">-->
                <!--                    <i class='bx bx-comment-detail'></i>-->
                <!--                </a>-->
                <!--                <a href="#"  v-if="index === 'partid-4'"-->
                <!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Decline item', placement:'leftbottom'}">-->
                <!--                    <i class='bx bx-x'></i>-->
                <!--                </a>-->
                <!--                <a href="#" v-if="index === 'partid-3'"-->
                <!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Comment text here', placement:'leftbottom'}">-->
                <!--                    <i class='bx bx-comment-detail'></i>-->
                <!--                </a>-->
                <!--                <a href="#"  v-if="index === 'partid-3'"-->
                <!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Report Item: <br> Reason text here', placement:'leftbottom'}">-->
                <!--                    <i class='bx bx-question-mark'></i>-->
                <!--                </a>-->
            </div>
        </div>
        <div @contextmenu.prevent="menuForPaint($event, index)" class="estimate-items-border estimate-items-paint-materials">
            <multiselect
                    :ref="card + '-paint_type'"
                    v-model="computedPaintType"
                    :options="optionsPaintMaterials"
                    :showLabels="false"
                    track-by="key"
                    label="label"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                    @select="emitMultiselect"
                    @onKeyLeft="onKeyPressLeft('name')"
                    @onKeyRight="onKeyPressRight(isEstimateMethodologyDollars ? 'rate' : 'hrs')"
                :disabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                style="opacity: 1"
                    placeholder=""
                    @blur="emitUpdate"
                    openDirection="bottom"
            >
            </multiselect>
        </div>
        <div v-if="!isEstimateMethodologyDollars" @contextmenu.prevent="menuForPaint($event, index)" class="estimate-items-border estimate-items-hrs">
            <number-formatter :ref="card + '-hrs'"
                              @onChange="onChangeHrs"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'paint_type'"
                        :next-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)?'adj_hrs': 'rate'):'rate'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('hrs')"
                        @onKeyDown="onKeyPressDown('hrs')"
                        :isDisabled="isDisabledFields"
                        type="text"
                        v-model="d_hrs"
                        :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="0,0.00">
      </number-formatter>
    </div>
    <div v-if="!isEstimateMethodologyDollars && isInsuranceRepairYes" @contextmenu.prevent="menuForPaint($event, index)"
         class="estimate-items-border estimate-items-adj">
      <span v-if="isEstimateMethodologyCustom && d_adj_rate !== null" class=""></span>
      <number-formatter
          v-else
          @updateDrag="updateDragFromNumberFormatter"
          :ref="card + '-adj_hrs'"
          :prev-cell="'hrs'"
          :next-cell="'rate'"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_hrs')"
          @onKeyDown="onKeyPressDown('adj_hrs')"
          :style="{color: d_adj_hrs > d_hrs ? '#2BAE66FF': ((d_adj_hrs < d_hrs) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_hrs"
          class="noselect form-control numeric adj"
          format="0,0.00"
          v-on:blur="emitUpdate"
          v-on:onChange="onChangeHrs"
          :isDisabled="!isEnableManualAdj || isDisabledFields "
      ></number-formatter>
<!--      for disabled|| (d_adj_rate != null && !isAuthorisePage)-->
    </div>
    <div v-if="isEstimateMethodologyCustom" @contextmenu.prevent="menuForPaint($event, index)"
         class="estimate-items-border estimate-items-total">
      <number-formatter
          v-if="isAuthorisePage"
          :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'hrs'): 'hrs'"
                        :next-cell="'rate2'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesPaint"
                        type="text"
                        v-model="d_adj_changed_rate"
                        :style="{color: d_adj_changed_rate > d_rs_rate ? '#2BAE66FF': ((d_adj_changed_rate < d_rs_rate) ? 'red' : 'black')}"
                        :class="{'error-authorisation': (_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
      <number-formatter
          v-else
          :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj && !(isEstimateMethodologyCustom && d_adj_rate !== null)? 'adj_hrs': 'hrs'): 'hrs'"
                        :next-cell="'rate2'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesPaint"
                        type="text"
                        v-model="d_rate"
                        :class="{'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-else-if="!isEstimateMethodologyDollars" @contextmenu.prevent="menuForPaint($event, index)" class="estimate-items-border estimate-items-total">
      <number-formatter
          v-if="isAuthorisePage"
          :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj? 'adj_hrs': 'hrs'):'hrs'"
                        :next-cell="(d_hrs==0 && d_rate==0)?'rate2': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_adj_changed_rate"
                        :style="{color: d_adj_changed_rate > d_rs_rate ? '#2BAE66FF': ((d_adj_changed_rate < d_rs_rate) ? 'red' : 'black')}"
                        :class="{'error-authorisation': (_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesPaint"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
      <number-formatter
          v-else
          :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj? 'adj_hrs': 'hrs'):'hrs'"
                        :next-cell="(d_hrs==0 && d_rate==0)?'rate2': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_rate"
                        :class="{'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesPaint"
                        class="noselect form-control numeric"
                        format="$0,0.00"></number-formatter>
    </div>

    <div v-if="isEstimateMethodologyDollars" @contextmenu.prevent="menuForRR">
      <number-formatter :ref="card + '-rate'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'paint_type'"
                        :next-cell="isInsuranceRepairYes ?'adj_price' : 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        :isDisabled="isDisabledFields || isDisabledRates || isDisabledRatesPaint"
                        type="text"
                        v-model="d_rate"
                        :class="{'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric dollar-total-input"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-else-if="isEstimateMethodologyCustom" @contextmenu.prevent="menuForPaint($event, index)"
         class="estimate-items-border estimate-items-total">
      <number-formatter :ref="card + '-rate2'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'rate'"
                        :next-cell="isInsuranceRepairYes && d_adj_hrs === null? 'adj_price': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate2')"
                        @onKeyDown="onKeyPressDown('rate2')"
                        type="text"
                        v-model="resultTotalManual"
                        :class="{'color-red': isRedHighlightRow}"
                        v-on:blur="emitUpdate"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        class="noselect form-control numeric"
                        format="$0,0.00"
                        :isTotalManual="true"
      >
      </number-formatter>
    </div>
    <div v-else-if="(d_hrs==0 && d_rate==0)" @contextmenu.prevent="menuForPaint($event, index)"
         class="estimate-items-border estimate-items-total">
      <number-formatter :ref="card + '-rate2'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="'rate'"
                        :next-cell="'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate2')"
                        :class="{'color-red': isRedHighlightRow}"
                        @onKeyDown="onKeyPressDown('rate2')"
                        type="text"
                        v-model="d_rate_2"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-else @contextmenu.prevent="menuForPaint($event, index)" class="estimate-items-border estimate-items-total">
      <span :class="{'color-red': isRedHighlightRow}"
            class="noselect form-control numeric total-item-hover">{{ result | formatMoney }}</span>
    </div>
    <div v-if="isInsuranceRepairYes" @contextmenu.prevent="menuForPaint($event, index)"
         class="estimate-items-border estimate-items-content-adj">

      <number-formatter
          v-if="isEstimateMethodologyDollars"
          :ref="card + '-adj_price'"
          :prev-cell="'rate'"
          :next-cell="'name'"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :style="{color: d_adj_rate > d_rate ? '#2BAE66FF': ((d_adj_rate < d_rate) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_rate"
          class="noselect form-control numeric adj"
          format="0,0.00"
          v-on:blur="emitUpdate"
          v-on:onChange="onChangeHrs"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
      ></number-formatter>

      <number-formatter
          v-else-if="isEstimateMethodologyCustom && d_adj_hrs === null"
          :prev-cell="'rate2'"
          :next-cell="'name'"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :class="[getClass]"
          :ref="card + '-adj_price'"
          :isTotalManual="true"
          type="text"
          v-model="resultAdj"
          class="noselect form-control numeric adj"
          format="$0,0.00"
          :style="getStyleForAdjTotal()"
          :isDisabled="!isEditableAdjRate || !isEnableManualAdj || d_adj_hrs != null || isDisabledFields || (isAuthorisePage && !isItemNew && !isEditableAdjRate)"
          v-on:blur="emitUpdate"
          v-on:onChange="onChange"
      ></number-formatter>
      <span v-else-if="resultAdj !== null" class="noselect form-control numeric"
            :class="[getClass]"
            :style="getStyleForAdjTotal()"
            style="border:none; padding-top: 10px;">{{ resultAdj | formatMoney }}</span>
    </div>
    <div class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon"
              :class="{'error-authorisation-checkbox': ((isEstimateMethodologyDollars && (_isEmpty2(d_rate) || Number(d_rate) == 0)) || (!isEstimateMethodologyDollars && ((_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)))) && d_isChecked}">
              <div
                  v-if="((isEstimateMethodologyDollars && (_isEmpty2(d_rate) || Number(d_rate) == 0)) || (!isEstimateMethodologyDollars && ((_isEmpty2(d_adj_changed_rate) || Number(d_adj_changed_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)))) && d_isChecked">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span><span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForPaint($event, index)" class="comment-block"
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment" class="comment-text">
        <span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>


  <!--
Parts
-->
    <div v-else-if="card === 'Part'"
         @mousedown="onMouseDown"
         @mousemove="onMouseMove"
         @mouseup="onMouseUp"
         v-on:keypress.enter="onKeyPressEnter(index)"
         class="qtit estimate-items-content"
         v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow, computedClassForEstimateItemWithIagOrm]
">
        <div @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-name-all estimate-items-part-max-width" :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock), 'estimate-authorise-part-name-width': isAuthorisePage}">
            <div class="td-wrapper">
                <div class="td-group d-flex">
                    <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
                    <input
                            :ref="card + '-name'"
                            type="text"
                            @mouseover="updateDrag(false)"
                            @mouseleave="updateDrag(true)"
                                @change="onChange"
                                @focus="onFocus"
                                @dblclick="onFocus"
                                @click="onFocus"
                                @blur="onBlur"
                            :readonly="isDisabledFields"
                                v-on:keypress="onKeyPress($event)"
                                v-on:keydown.left="isInsuranceRepairYes? (isEnableManualAdj? onKeyPressLeft('adj_price',$event,'edit'): onKeyPressLeft('rate',$event,'edit')): onKeyPressLeft('rate',$event,'edit')"
                                v-on:keydown.right="onKeyPressRight('part_num',$event,'edit')"
                                v-on:keydown.up="onKeyPressUp('name')"
                                v-on:keydown.down="onKeyPressDown('name')"
                            class="noselect form-control"
                            v-model="d_name"
                            v-on:input="emitUpdate"/>
                    <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
                    <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
                    <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
                    <i v-if="isHasPartssearchLinkedPart" v-b-tooltip.hover.topleft :title="'Linked to: ' + item.partssearchLinkedPartName" class='bx bx-link-alt' style="color: #a0a0a0; cursor: pointer; z-index: 99;"></i>
                </div>
            </div>
    </div>
    <div @contextmenu.prevent="menuForPart($event, true)" class="estimate-items-border estimate-items-part-num"
         :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
      <input
          :ref="card + '-part_num'"
          @change="onChange"
          @focus="onFocus"
          @mouseover="updateDrag(false)"
          @mouseleave="updateDrag(true)"
          :readonly="isDisabledFields"
                   @dblclick="onFocus"
                   @click="onFocus"
                   @blur="onBlur"
                   @mouseup="updateSelectedText($event)"
                   v-on:keypress="onKeyPress($event)"
                   v-on:keydown.left="onKeyPressLeft('name',$event,'edit')"
                   v-on:keydown.right="onKeyPressRight('type',$event,'edit')"
                   v-on:keydown.up="onKeyPressUp('part_num')"
                   v-on:keydown.down="onKeyPressDown('part_num')"
                   type="text"
                   class="noselect form-control mw-9rem"
                   v-model="d_part_num"
                   v-on:blur="emitUpdate"/></div>
        <div @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-part-num"  :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
            <multiselect
                :ref="card + '-type'"
                    track-by="key"
                    label="label"
                    v-model="computedPartType"
                    :options="optionsPartsType"
                    :showLabels="false"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                    @select="emitMultiselect"
                    @onKeyLeft="onKeyPressLeft('part_num')"
                    @onKeyRight="onKeyPressRight('mup')"
                    placeholder="Select"
                    @blur="emitUpdate"
                :disabled="isDisabledFields"
                style="opacity: 1"
                    openDirection="bottom"
            >
            </multiselect>
        </div>
        <div v-if="!isTemplate" @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-rate" style="width: 70px !important;" :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
            <number-formatter v-if="d_adj_mup !== undefined && d_adj_mup !== null && Number(d_adj_mup) !== Number(d_mup)"
                              :ref="card + '-mup'"
                              @onChange="onChange"
                              @onKeyPress="onKeyPress"
                              @updateDrag="updateDragFromNumberFormatter"
                              :prev-cell="'type'"
                              :next-cell="'qty'"
                              @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                              @onKeyRight="onKeyPressRightOnNumberFormatter"
                              @onKeyUp="onKeyPressUp('mup')"
                              @onKeyDown="onKeyPressDown('mup')"
                              type="text"
                              v-model="d_adj_mup"
                              v-on:blur="emitUpdate"
                              class="noselect form-control numeric adj"
                              :style="{color: d_adj_mup > d_mup ? '#2BAE66FF': 'red'}"
                              format="0,0.00"
                              :isDisabled="true"
            ></number-formatter>
            <number-formatter v-else
                              :ref="card + '-mup'"
                              @onChange="onChange"
                              @onKeyPress="onKeyPress"
                              @updateDrag="updateDragFromNumberFormatter"
                              :prev-cell="'type'"
                              :next-cell="'qty'"
                              @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                              @onKeyRight="onKeyPressRightOnNumberFormatter"
                              @onKeyUp="onKeyPressUp('mup')"
                              @onKeyDown="onKeyPressDown('mup')"
                              type="text"
                              v-model="d_mup"
                              v-on:blur="emitUpdate"
                              class="noselect form-control numeric"
                              :isDisabled="isDisabledFields"
                              format="0,0.00"></number-formatter>
        </div>
        <div @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-rate" style="width: 50px !important;"  :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
            <number-formatter :ref="card + '-qty'"
                              @onChange="onChange"
                              @onKeyPress="onKeyPress"
                              @updateDrag="updateDragFromNumberFormatter"
                              :prev-cell="'mup'"
                              :next-cell="'cost'"
                              @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                              @onKeyRight="onKeyPressRightOnNumberFormatter"
                              @onKeyUp="onKeyPressUp('qty')"
                              @onKeyDown="onKeyPressDown('qty')"
                              type="text"
                              v-model="d_qty"
                              v-on:blur="emitUpdate"
                              class="noselect form-control numeric numeric-qty"
                              :isDisabled="isDisabledFields"
                              format="0,0"></number-formatter>
        </div>
        <div v-if="!isTemplate" @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-rate"  :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
            <number-formatter :ref="card + '-cost'"
                              @onChange="onChange"
                              @updateDrag="updateDragFromNumberFormatter"
                              @onKeyPress="onKeyPress"
                              :prev-cell="'qty'"
                              :next-cell="'rate'"
                              @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                              @onKeyRight="onKeyPressRightOnNumberFormatter"
                              @onKeyUp="onKeyPressUp('cost')"
                              @onKeyDown="onKeyPressDown('cost')"
                              type="text"
                              v-model="d_cost"
                              v-on:blur="emitUpdate"
                              class="noselect form-control numeric"
                              :isDisabled="!isStatusActive"
                        format="$0,0.00"></number-formatter>
    </div>
    <div @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-rate"
         :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
      <number-formatter :ref="card + '-rate'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="'cost'"
                        :next-cell="isInsuranceRepairYes? (isEnableManualAdj? 'adj_price': 'name'): 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_rate"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        :isDisabled="isDisabledFields"
                        format="$0,0.00"></number-formatter>
    </div>
    <div @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-total"
         :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
      <span :class="{'color-red': isRedHighlightRow}"
            class="noselect form-control numeric additional-style-input-total total-item-hover">{{result | formatMoney}}</span></div>
        <div v-if="isInsuranceRepairYes" @contextmenu.prevent="menuForPart($event, false)" class="estimate-items-border estimate-items-adj"  :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock)}">
            <number-formatter
                    :ref="card + '-adj_price'"
                    @onChange="onChange"
                    @updateDrag="updateDragFromNumberFormatter"
                    :prev-cell="'rate'"
                    :next-cell="'name'"
                    @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                    @onKeyUp="onKeyPressUp('adj_price')"
                    @onKeyDown="onKeyPressDown('adj_price')"
                    @onKeyRight="onKeyPressRightOnNumberFormatter"
                    :isTotalManual="true"
                    type="text"
                    :style="{color: d_adj_rate > result ? '#2BAE66FF': ((d_adj_rate < result) ? 'red' : 'black')}"
                    v-model="d_adj_rate"
                    class="noselect form-control numeric adj"

          format="$0,0.00"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
          v-on:blur="emitUpdate"
          v-on:onChange="onChange"
      ></number-formatter>
    </div>
    <div class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'hide-border-bottom-when-comment-block-active':(isShowCommentBlock), 'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon"
              :class="{'error-authorisation-checkbox': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}">
              <div v-if="(_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span><span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForPart($event, false)" class="comment-block"
         :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment" class="comment-text">
        <span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>

  <!--
Materials
-->
    <div v-else-if="card === 'Materials' && d_subtype !== 'EnvironmentLevy' &&  d_subtype !== 'PaintSetupTime'" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp"
         v-on:keypress.enter="onKeyPressEnter(index)" class="qtit ini-materials estimate-items-content"
         v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]">
        <div v-if="d_type === 'calculation'" class="d-flex estimate-items-border estimate-items-name-all" @contextmenu.prevent="menuForMaterials">
            <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
            <span :ref="card + '-name2'" class="noselect form-control" style="height: 32px;">{{d_name}}</span>
            <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
        </div>
        <div v-else @contextmenu.prevent="menuForMaterials" class="d-flex estimate-items-border estimate-items-name-all">
            <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
            <template>
                <input type="text"
                       :ref="card + '-name'"
                       @mouseover="updateDrag(false)"
                       @mouseleave="updateDrag(true)"
                           @change="onChange"
                           @focus="onFocus"
                           @dblclick="onFocus"
                           @click="onFocus"
                           @blur="onBlur"
                           v-on:keypress="onKeyPress($event)"
                           v-on:keydown.left="isInsuranceRepairYes && !isTemplate?onKeyPressLeft('adj_price'):onKeyPressLeft('rate2')"
                           v-on:keydown.right="onKeyPressRight('hrs')"
                           v-on:keydown.up="onKeyPressUp('name')"
                           v-on:keydown.down="onKeyPressDown('name')"
                       class="noselect form-control "
                       :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
               v-model="d_name"
               v-on:input="emitUpdate"
               style="opacity: 1; cursor: pointer"
               @contextmenu.prevent="menuForMaterials"
        />
      </template>
      <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
      <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
      <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
    </div>

    <div v-if="d_type === 'manual'" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-hrs">
      <number-formatter :ref="card + '-hrs'"
                        @onChange="onChangeHrs"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="'name'"
                        :next-cell="'adj_hrs'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('hrs')"
                        @onKeyDown="onKeyPressDown('hrs')"
                        type="text"
                        v-model="d_hrs"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"
                        :isDisabled="isDisabledFields"
                        style="opacity: 1"
                        format="0,0.00">
      </number-formatter>
    </div>
    <div v-else @contextmenu.prevent="menuForMaterials" class="estimate-items-border estimate-items-hrs">
            <span v-if="d_original_hrs && d_original_hrs !== null" :ref="card + '-hrs'"
                  :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_original_hrs) || Number(d_original_hrs) == 0) && d_isChecked}"
                  class="noselect form-control numeric" style="height: 32px;">{{ d_original_hrs }}</span>
      <span v-else-if="d_rs_hrs && d_rs_hrs !== null" :ref="card + '-hrs'" class="noselect form-control numeric"
            :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rs_hrs) || Number(d_rs_hrs) == 0) && d_isChecked}"
            style="height: 32px;">{{ d_rs_hrs }}</span>
      <span v-else :ref="card + '-hrs'" class="noselect form-control numeric"
            :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"
            style="height: 32px;">{{ d_hrs }}</span>
    </div>
    <div v-if="isInsuranceRepairYes && !isTemplate" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-adj">
      <span v-if="isFromEio && d_type == 'calculation'" class="noselect form-control numeric adj">

      </span>
            <span v-else-if="isEstimateMethodologyCustom && d_adj_rate !== null"
                  class="">{{ d_adj_hrs }}</span>
      <number-formatter :ref="card + '-adj_hrs'"
                        v-else
                        @onChange="onChangeHrs"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="d_type === 'manual'? 'hrs': 'adj_price'"
                        :next-cell="d_type !== 'calculation'?'rate2':'adj_price'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('adj_hrs')"
                        @onKeyDown="onKeyPressDown('adj_hrs')"
                        @onKeyEnter="emitUpdate"
                        type="text"
                        v-model="d_adj_hrs"
                        class="noselect form-control numeric adj"

                        v-on:blur="emitUpdate"
                        :style="{color: d_adj_hrs > getMaterialsHrs1 ? '#2BAE66FF': ((d_adj_hrs < getMaterialsHrs1) ? 'red' : 'black')}"
                        format="0.00"
                        :isTotalManual="true"
                        :isDisabled="!isEnableManualAdj || isDisabledFields || (d_adj_rate != null && !isAuthorisePage)"
      >
      </number-formatter>
      <!--            <span :ref="card + '-adj_hrs'" class="noselect form-control numeric adj" style="height: 32px; color:red;">{{d_adj_hrs}}</span>-->
    </div>
    <div @contextmenu.prevent="menuForMaterials" class="estimate-items-border estimate-items-total">
      <template v-if="d_type !== 'calculation'">
        <number-formatter :ref="card + '-rate2'"
                          @onChange="onChange"
                          @onKeyPress="onKeyPress"
                          @updateDrag="updateDragFromNumberFormatter"
                          :prev-cell="'adj_hrs'"
                          :next-cell="'adj_price'"
                          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                          @onKeyRight="onKeyPressRightOnNumberFormatter"
                          @onKeyUp="onKeyPressUp('rate2')"
                          @onKeyDown="onKeyPressDown('rate2')"
                          @onKeyEnter="emitUpdate"
                          type="text"
                          v-model="d_rate"
                          class="noselect form-control numeric"
                          :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                          :isDisabled="isDisabledFields"
                          v-on:blur="emitUpdate"
                          format="$0,0.00">
        </number-formatter>
      </template>
      <template v-else>
                <span v-if="d_original_total && d_original_total !== null" :ref="card + '-hrs'"
                      class="noselect form-control numeric"
                      :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_original_total) || Number(d_original_total) == 0) && d_isChecked}"
                      style="height: 32px;">{{ d_original_total | formatMoney }}</span>
        <span v-else-if="d_rs_rate && d_rs_rate!==null" :ref="card + '-rate2'" class="noselect form-control numeric "
              :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rs_rate) || Number(d_rs_rate) == 0) && d_isChecked}"
              style="height: 32px;">{{ d_rs_rate | formatMoney }}</span>
        <span v-else :ref="card + '-rate2'" class="noselect form-control numeric" style="height: 32px;"
              :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(result) || Number(result) == 0) && d_isChecked}">{{ result | formatMoney }}</span>
      </template>
    </div>
    <div v-if="isInsuranceRepairYes && !isTemplate" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-adj">
      <number-formatter :ref="card + '-adj_price'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="d_type !== 'calculation'? 'rate2':'adj_hrs'"
                        :next-cell="d_type === 'calculation'? 'adj_hrs': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('adj_price')"
                        @onKeyDown="onKeyPressDown('adj_price')"
                        @onKeyEnter="emitUpdate"
                        type="text"
                        v-model="resultAdj1"
                        class="noselect form-control numeric adj"

                        v-on:blur="emitUpdate"
                        :style="{color: resultAdj1 > getMaterialsRate1 ? '#2BAE66FF': ((resultAdj1 < getMaterialsRate1) ? 'red' : 'black')}"
                        format="$0,0.00"
                        :isTotalManual="true"
                        :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled) || !!Number(d_adj_hrs) || true"
      >
      </number-formatter>
      <!--            <span v-if="d_adj_rate && d_adj_rate!==null" ref="adj_rate" class="noselect form-control numeric adj" style="height: 32px; color:red;">{{d_adj_rate | formatMoney}}</span>-->
      <!--            <span v-else ref="adj_rate" class="noselect form-control numeric adj" style="height: 32px;"></span>-->
    </div>
    <div class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon" :class="{'error-authorisation-checkbox':  isErrorMaterialCheckbox1}">
              <div v-if="isErrorMaterialCheckbox1">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="d_type=='manual'  && isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForMaterials" class="comment-block "
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment || isShowEIOMessage" class="comment-text">
        <span v-if="isHasManualComment"><span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}</span>

        <br v-if="isHasManualComment && isShowEIOMessage"/>
         <span v-if="isShowEIOMessage">Assessors Manual Adjustment -  Estimage</span>
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>


    <div v-else-if="card === 'Materials' && d_subtype === 'EnvironmentLevy' &&  showEnviromentLevy" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp"
         v-on:keypress.enter="onKeyPressEnter(index)" class="qtit ini-materials estimate-items-content"
         v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]">
        <div v-if="d_type === 'calculation'" class="d-flex estimate-items-border estimate-items-name-all" @contextmenu.prevent="menuForMaterials">
            <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
            <span :ref="card + '-name2'" class="noselect form-control" style="height: 32px;">{{d_name}}</span>
            <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
        </div>
        <div v-else @contextmenu.prevent="menuForMaterials" class="d-flex estimate-items-border estimate-items-name-all">
                <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
                <template>
                    <input type="text"
                           :ref="card + '-name'"
                       @mouseover="updateDrag(false)"
                       @mouseleave="updateDrag(true)"
                           @change="onChange"
                           @focus="onFocus"
                           @dblclick="onFocus"
                           @click="onFocus"
                           @blur="onBlur"
                           v-on:keypress="onKeyPress($event)"
                           v-on:keydown.left="isInsuranceRepairYes?onKeyPressLeft('name'):onKeyPressLeft('name')"
                           v-on:keydown.right="onKeyPressRight('hrs')"
                           v-on:keydown.up="onKeyPressUp('name')"
                           v-on:keydown.down="onKeyPressDown('name')"
                       class="noselect form-control "
                       :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
               v-model="d_name"
               v-on:input="emitUpdate"
               style="opacity: 1; cursor: pointer"
               @contextmenu.prevent="menuForMaterials"
        />
      </template>
      <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
      <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
      <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
    </div>

    <div v-if="d_type === 'manual'" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-hrs">
<!--      <number-formatter :ref="card + '-hrs'"-->
<!--                        @onChange="onChange"-->
<!--                        @onKeyPress="onKeyPress"-->
<!--                        @updateDrag="updateDragFromNumberFormatter"-->
<!--                        :prev-cell="'name'"-->
<!--                        :next-cell="'adj_hrs'"-->
<!--                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"-->
<!--                        @onKeyRight="onKeyPressRightOnNumberFormatter"-->
<!--                        @onKeyUp="onKeyPressUp('hrs')"-->
<!--                        @onKeyDown="onKeyPressDown('hrs')"-->
<!--                        type="text"-->
<!--                        :class="{'color-red': isRedHighlightRow }"-->
<!--                        v-on:blur="emitUpdate"-->
<!--                        class="noselect form-control numeric"-->
<!--                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"-->
<!--                        style="opacity: 1"-->
<!--                        format="0,0.00">-->
<!--      </number-formatter>-->
    </div>
    <div v-else @contextmenu.prevent="menuForMaterials" class="estimate-items-border estimate-items-hrs">
<!--            <span v-if="d_rs_hrs && d_rs_hrs !== null" :ref="card + '-hrs'" class="noselect form-control numeric"-->
<!--                  :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rs_hrs) || Number(d_rs_hrs) == 0) && d_isChecked}"-->
<!--                  style="height: 32px;">{{ d_rs_hrs }}</span>-->
<!--      <span v-else :ref="card + '-hrs'" class="noselect form-control numeric"-->
<!--            :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"-->
<!--            style="height: 32px;">{{ d_hrs }}</span>-->
    </div>
    <div v-if="isInsuranceRepairYes && !isTemplate" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-hrs">
      <span :ref="card + '-adj_hrs'" class="noselect form-control numeric" style="height: 32px;"></span>

    </div>
    <div @contextmenu.prevent="menuForMaterials" class="estimate-items-border estimate-items-total">
      <template v-if="d_type !== 'calculation'">
        <number-formatter :ref="card + '-rate2'"
                          @onChange="onChange"
                          @updateDrag="updateDragFromNumberFormatter"
                          @onKeyPress="onKeyPress"
                          :prev-cell="'adj_hrs'"
                          :next-cell="'adj_price'"
                          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                          @onKeyRight="onKeyPressRightOnNumberFormatter"
                          @onKeyUp="onKeyPressUp('rate2')"
                          @onKeyDown="onKeyPressDown('rate2')"
                          @onKeyEnter="emitUpdate"
                          type="text"
                          v-model="d_rate"
                          class="noselect form-control numeric"
                          :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                          :isDisabled="isDisabledFields"
                          v-on:blur="emitUpdate"
                          format="$0,0.00">
        </number-formatter>
      </template>
      <template v-else>
                <span v-if="d_rs_rate && d_rs_rate!==null" :ref="card + '-rate2'" class="noselect form-control numeric"
                      :class="{'color-red': isRedHighlightRow}"
                      style="height: 32px;">{{ d_rs_rate | formatMoney }}</span>
        <span v-else :ref="card + '-rate2'" class="noselect form-control numeric" style="height: 32px;"
              :class="{'color-red': isRedHighlightRow}">{{ result | formatMoney }}</span>
      </template>
    </div>
    <div v-if="isInsuranceRepairYes && !isTemplate" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-adj">
      <number-formatter
          v-if="isFromEio && d_type === 'calculation'"
          :ref="card + '-adj_price'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="d_type !== 'calculation'? 'rate2':'adj_hrs'"
                        :next-cell="d_type === 'calculation'? 'adj_hrs': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('adj_price')"
                        @onKeyDown="onKeyPressDown('adj_price')"
                        @onKeyEnter="emitUpdate"
                        type="text"
                        v-model="resultAdj1"
                        class="noselect form-control numeric adj"

                        v-on:blur="emitUpdate"
                        :style="{color: resultAdj1 > getMaterialsRate1 ? '#2BAE66FF': ((resultAdj1 < getMaterialsRate1) ? 'red' : 'black')}"
                        format="$0,0.00"
                        :isTotalManual="true"
                        :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled) || !!Number(d_adj_hrs) || true"
      >
      </number-formatter>
      <number-formatter :ref="card + '-adj_price'"
                        v-else
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="d_type !== 'calculation'? 'rate2': 'adj_hrs'"
                        :next-cell="d_type === 'calculation'? 'adj_hrs':'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('adj_price')"
                        @onKeyDown="onKeyPressDown('adj_price')"
                        @onKeyEnter="emitUpdate"
                        type="text"
                        v-model="d_adj_rate"
                        class="noselect form-control numeric adj"
                        v-on:blur="emitUpdate"
                        :style="{color: d_adj_rate > getMaterialsRate2 ? '#2BAE66FF': ((d_adj_rate < getMaterialsRate2) ? 'red' : 'black')}"
                        format="$0,0.00"
                        :isTotalManual="true"
                        :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
      >
      </number-formatter>
      <!--            <span v-if="d_adj_rate && d_adj_rate!==null" ref="adj_rate" class="noselect form-control numeric adj" style="height: 32px; color:red;">{{d_adj_rate | formatMoney}}</span>-->
      <!--            <span v-else ref="adj_rate" class="noselect form-control numeric adj" style="height: 32px;"></span>-->
    </div>
    <div class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon" :class="{'error-authorisation-checkbox': isErrorMaterialCheckbox2}">
              <div v-if="isErrorMaterialCheckbox2">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="d_type=='manual'  && isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForMaterials" class="comment-block "
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment || isShowEIOMessage" class="comment-text">
        <span v-if="isHasManualComment"><span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}</span>

        <br v-if="isHasManualComment && isShowEIOMessage"/>
        <span v-if="isShowEIOMessage">Assessors Manual Adjustment -  Estimage</span>
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>


    <div v-else-if="card === 'Materials' && d_subtype === 'PaintSetupTime'  &&  showPaintSetupTime" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp"
         v-on:keypress.enter="onKeyPressEnter(index)" class="qtit ini-materials estimate-items-content estimate-items-border"
         v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]">
        <div v-if="d_type === 'calculation'" class="d-flex estimate-items-name-all estimate-items-border" @contextmenu.prevent="menuForMaterials">
            <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
            <span :ref="card + '-name2'" class="noselect form-control" style="height: 32px;">{{d_name}}</span>
            <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
        </div>
        <div v-else @contextmenu.prevent="menuForMaterials" class="d-flex estimate-items-border estimate-items-name-all">
                <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
                <template>
                    <input type="text"
                           :ref="card + '-name'"
                           @change="onChange"
                       @mouseover="updateDrag(false)"
                       @mouseleave="updateDrag(true)"
                           @focus="onFocus"
                           @dblclick="onFocus"
                           @click="onFocus"
                           @blur="onBlur"
                           v-on:keypress="onKeyPress($event)"
                           v-on:keydown.left="isInsuranceRepairYes && !isTemplate?onKeyPressLeft('adj_price'):onKeyPressLeft('rate2')"
                           v-on:keydown.right="onKeyPressRight('hrs')"
                           v-on:keydown.up="onKeyPressUp('name')"
                           v-on:keydown.down="onKeyPressDown('name')"
                       class="noselect form-control "
                       :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
               v-model="d_name"
               v-on:input="emitUpdate"
               style="opacity: 1; cursor: pointer"
               @contextmenu.prevent="menuForMaterials"
        />
      </template>
      <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
    </div>

    <div v-if="d_type === 'manual'" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-hrs">
      <number-formatter :ref="card + '-hrs'"
                        @onChange="onChangeHrs"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="'name'"
                        :next-cell="'adj_hrs'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('hrs')"
                        @onKeyDown="onKeyPressDown('hrs')"
                        type="text"
                        v-model="d_hrs"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"
                        :isDisabled="isDisabledFields"
                        style="opacity: 1"
                        format="0,0.00">
      </number-formatter>
    </div>
    <div v-else @contextmenu.prevent="menuForMaterials" class="estimate-items-border estimate-items-hrs">
            <span v-if="d_original_hrs && d_original_hrs !== null" :ref="card + '-hrs'"
                  class="noselect form-control numeric"
                  :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_original_hrs) || Number(d_original_hrs) == 0) && d_isChecked}"
                  style="height: 32px;">{{ d_original_hrs }}</span>
      <span v-else-if="d_rs_hrs && d_rs_hrs !== null" :ref="card + '-hrs'" class="noselect form-control numeric"
            :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rs_hrs) || Number(d_rs_hrs) == 0) && d_isChecked}"
            style="height: 32px;">{{ d_rs_hrs }}</span>
      <span v-else :ref="card + '-hrs'" class="noselect form-control numeric"
            :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_hrs) || Number(d_hrs) == 0) && d_isChecked}"
            style="height: 32px;">{{ d_hrs }}</span>
    </div>
    <div v-if="isInsuranceRepairYes && !isTemplate" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-hrs">
      <span v-if="isFromEio && d_type == 'calculation'" class="noselect form-control numeric adj">
      </span>
      <span v-else-if="isEstimateMethodologyCustom && d_adj_rate !== null" class="">{{ d_adj_hrs }}</span>
      <number-formatter :ref="card + '-adj_hrs'"
                        v-else
                        @onChange="onChangeHrs"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="d_type === 'manual'? 'hrs': 'adj_price'"
                        :next-cell="d_type !== 'calculation'? 'rate2':'adj_price'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('adj_hrs')"
                        @onKeyDown="onKeyPressDown('adj_hrs')"
                        @onKeyEnter="emitUpdate"
                        type="text"
                        v-model="d_adj_hrs"
                        class="noselect form-control numeric adj"

                        v-on:blur="emitUpdate"
                        :style="{color: d_adj_hrs > getMaterialsHrs3 ? '#2BAE66FF': ((d_adj_hrs < getMaterialsHrs3) ? 'red' : 'black')}"
                        format="0.00"
                        :isTotalManual="true"
                        :isDisabled="!isEnableManualAdj || isDisabledFields || (d_adj_rate != null && !isAuthorisePage)"
      >
      </number-formatter>
      <!--            <span :ref="card + '-adj_hrs'" class="noselect form-control numeric adj" style="height: 32px; color:red;">{{d_adj_hrs}}</span>-->
    </div>
    <div @contextmenu.prevent="menuForMaterials" class="estimate-items-border estimate-items-total">
      <template v-if="d_type !== 'calculation'">
        <number-formatter :ref="card + '-rate2'"
                          @onChange="onChange"
                          @onKeyPress="onKeyPress"
                          @updateDrag="updateDragFromNumberFormatter"
                          :prev-cell="'adj_hrs'"
                          :next-cell="'adj_price'"
                          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                          @onKeyRight="onKeyPressRightOnNumberFormatter"
                          @onKeyUp="onKeyPressUp('rate2')"
                          @onKeyDown="onKeyPressDown('rate2')"
                          @onKeyEnter="emitUpdate"
                          type="text"
                          v-model="d_rate"
                          class="noselect form-control numeric"
                          :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                          :class="{'color-red': isRedHighlightRow}"
                          v-on:blur="emitUpdate"
                          format="$0,0.00">
        </number-formatter>
      </template>
      <template v-else>
                <span v-if="d_rs_rate && d_rs_rate!==null" :ref="card + '-rate2'" class="noselect form-control numeric"
                      :class="{'color-red': isRedHighlightRow}"
                      style="height: 32px;">{{ d_rs_rate | formatMoney }}</span>
        <span v-else :ref="card + '-rate2'" class="noselect form-control numeric" style="height: 32px;"
              :class="{'color-red': isRedHighlightRow}">{{ result | formatMoney }}</span>
      </template>
    </div>
    <div v-if="isInsuranceRepairYes && !isTemplate" @contextmenu.prevent="menuForMaterials"
         class="estimate-items-border estimate-items-adj">
      <number-formatter :ref="card + '-adj_price'"
                        @onChange="onChange"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onKeyPress="onKeyPress"
                        :prev-cell="d_type !== 'calculation'? 'rate2': 'adj_hrs'"
                        :next-cell="d_type === 'calculation'? 'adj_hrs': 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('adj_price')"
                        @onKeyDown="onKeyPressDown('adj_price')"
                        @onKeyEnter="emitUpdate"
                        type="text"
                        v-model="resultAdj1"
                        class="noselect form-control numeric adj"

                        v-on:blur="emitUpdate"
                        :style="{color: resultAdj1 > getMaterialsRate3 ? '#2BAE66FF': ((resultAdj1 < getMaterialsRate3) ? 'red' : 'black')}"
                        format="$0,0.00"
                        :isTotalManual="true"
                        :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled) || !!Number(d_adj_hrs)"
      >
      </number-formatter>
      <!--            <span v-if="d_adj_rate && d_adj_rate!==null" ref="adj_rate" class="noselect form-control numeric adj" style="height: 32px; color:red;">{{d_adj_rate | formatMoney}}</span>-->
      <!--            <span v-else ref="adj_rate" class="noselect form-control numeric adj" style="height: 32px;"></span>-->
    </div>
    <div class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon" :class="{'error-authorisation-checkbox': isErrorMaterialCheckbox3}">
               <div v-if="isErrorMaterialCheckbox3">!</div>
                <i class='bx bx-check' style="margin: 0px"></i>
              </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="d_type=='manual'  && isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForMaterials" class="comment-block"
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment || isShowEIOMessage" class="comment-text">
        <span v-if="isHasManualComment"><span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}</span>

        <br v-if="isHasManualComment && isShowEIOMessage"/>
        <span v-if="isShowEIOMessage">Assessors Manual Adjustment -  Estimage</span>
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>
  <!--
Sublet
-->
    <div v-else-if="card === 'Sublet' " v-on:keypress.enter="onKeyPressEnter(index)" class="qtit estimate-items-content" @mousemove="onMouseMove" @mousedown="onMouseDown" @mouseup="onMouseUp"
         v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]">
        <div @contextmenu.prevent="menuForSublet" class="d-flex estimate-items-border estimate-items-name-all">
                <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
                <input type="text"
                       :ref="card + '-name'"
                   @mouseover="updateDrag(false)"
                   @mouseleave="updateDrag(true)"
                       @change="onChange"
                       @focus="onFocus"
                       @dblclick="onFocus"
                       @click="onFocus"
                       @blur="onBlur"
                       v-on:keypress="onKeyPress($event)"
                       v-on:keydown.left="isInsuranceRepairYes? (isEnableManualAdj? onKeyPressLeft('adj_price',$event,'edit'): onKeyPressLeft('rate',$event,'edit')): onKeyPressLeft('rate',$event,'edit')"
                       v-on:keydown.right="onKeyPressRight('repairer',$event,'edit')"
                       v-on:keydown.up="onKeyPressUp('name')"
                       v-on:keydown.down="onKeyPressDown('name')"
                   class="noselect form-control"
                   :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
                   v-model="d_name"
                   v-on:input="emitUpdate"
            />
            <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
            <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
            <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
        </div>
        <div v-if="!isEstimateTemplate && !isTemplate" @contextmenu.prevent="menuForSublet" class="estimate-items-border estimate-items-repair">
            <input :ref="card + '-repairer'"
                   @change="onChange"
                   @mouseover="updateDrag(false)"
                   @mouseleave="updateDrag(true)"
                   @focus="onFocus"
                   @dblclick="onFocus"
                   @click="onFocus"
                   @blur="onBlur"
                   v-on:keypress="onKeyPress($event)"
                   v-on:keydown.left="onKeyPressLeft('name',$event,'edit')"
                   v-on:keydown.right="onKeyPressRight('mup',$event,'edit')"
                   v-on:keydown.up="onKeyPressUp('repairer')"
                   v-on:keydown.down="onKeyPressDown('repairer')"
                   type="text"
                   class="noselect form-control mw-9rem"
                   :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
             v-model="d_repairer"
             v-on:blur="emitUpdate"/>
    </div>
    <div v-if="!isTemplate && !isEstimateTemplate" @contextmenu.prevent="menuForSublet"
         class="estimate-items-border estimate-items-rate" style="width: 70px !important;">
      <number-formatter v-if="d_adj_mup !== undefined && d_adj_mup !== null && Number(d_adj_mup) !== Number(d_mup)"
                        :ref="card + '-mup'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'repairer'"
                        @updateDrag="updateDragFromNumberFormatter"
                        :next-cell="'rate'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('mup')"
                        @onKeyDown="onKeyPressDown('mup')"
                        type="text"
                        :style="{color: d_adj_mup > d_mup ? '#2BAE66FF': 'red'}"
                        v-model="d_adj_mup"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"

                              :isDisabled="true"
                              format="0,0.00"></number-formatter>
            <number-formatter v-else
                              :ref="card + '-mup'"
                              @onChange="onChange"
                              @updateDrag="updateDragFromNumberFormatter"
                              @onKeyPress="onKeyPress"
                              :prev-cell="'repairer'"
                              :next-cell="'rate'"
                              @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                              @onKeyRight="onKeyPressRightOnNumberFormatter"
                              @onKeyUp="onKeyPressUp('mup')"
                              @onKeyDown="onKeyPressDown('mup')"
                              type="text"
                              v-model="d_mup"
                              v-on:blur="emitUpdate"
                              class="noselect form-control numeric"
                              :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        format="0,0.00"></number-formatter>
    </div>
    <div @contextmenu.prevent="menuForSublet" class="estimate-items-border estimate-items-total">
      <number-formatter :ref="card + '-rate'"
                        style="min-width: 4.5rem;"
                        @updateDrag="updateDragFromNumberFormatter"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        :prev-cell="'mup'"
                        :next-cell="isInsuranceRepairYes? (isEnableManualAdj? 'adj_price': 'name'): 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_rate"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        :isDisabled="isDisabledFields"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-if="!isTemplate && !isEstimateTemplate" @contextmenu.prevent="menuForSublet"
         class="estimate-items-border estimate-items-total">
      <span :class="{'color-red': isRedHighlightRow}"
            class="noselect form-control numeric total-item-hover">{{ result | formatMoney }}</span>
    </div>
    <div v-if="isInsuranceRepairYes && !isTemplate" @contextmenu.prevent="menuForSublet"
         class="estimate-items-border estimate-items-adj">
      <number-formatter
          :ref="card + '-adj_price'"
          @onChange="onChange"
          @updateDrag="updateDragFromNumberFormatter"
          :prev-cell="'rate'"
          :next-cell="'name'"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :style="{color: d_adj_rate > d_rate ? '#2BAE66FF': ((d_adj_rate < d_rate) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-on:blur="emitUpdate"
          v-model="d_adj_rate"
          class="noselect form-control numeric adj"
          format="$0,0.00"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
      ></number-formatter>
    </div>
    <div class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon"
              :class="{'error-authorisation-checkbox': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}">
              <div v-if="(_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a>
      </div>

    </div>
    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForSublet" class="comment-block"
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment" class="comment-text">
        <span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>

  <!--
Other
-->
    <div v-else-if="card === 'Other'" @mousedown="onMouseDown" @mouseup="onMouseUp" @mousemove="onMouseMove" v-on:keypress.enter="onKeyPressEnter(index)" class="qtit estimate-items-content"
         v-bind:class="[{ handle: isNotEditText}, computedClassForHighlightRow, computedClassForSelectedRow ]">
        <div @contextmenu.prevent="menuForOther" class="d-flex estimate-items-border estimate-items-name-all">
            <!--<i class="fa fa-arrows drag-handle" aria-hidden="true" title="drag this row to change its position in the list"></i>-->
                <input type="hidden" :ref="card + '-id'" v-model="d_id"/>
                <input type="text"
                       :ref="card + '-name'"
                   @mouseover="updateDrag(false)"
                   @mouseleave="updateDrag(true)"
                       @change="onChange"
                       @focus="onFocus"
                       @dblclick="onFocus"
                       @click="onFocus"
                       @blur="onBlur"
                       v-on:keypress="onKeyPress($event)"
                       v-on:keydown.left="isInsuranceRepairYes? (isEnableManualAdj? onKeyPressLeft('adj_price',$event,'edit'): onKeyPressLeft('rate',$event,'edit')): onKeyPressLeft('rate',$event,'edit')"
                       v-on:keydown.right="onKeyPressRight('rate',$event,'edit')"
                       v-on:keydown.up="onKeyPressUp('name')"
                       v-on:keydown.down="onKeyPressDown('name')"
                   class="noselect form-control"
                   :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
             v-model="d_name"
             v-on:input="emitUpdate"
      />
      <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
      <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
      <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note'
         style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
    </div>
    <div @contextmenu.prevent="menuForOther" class="estimate-items-border estimate-items-total">
      <number-formatter :ref="card + '-rate'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="'name'"
                        :next-cell="isInsuranceRepairYes? (isEnableManualAdj? 'adj_price': 'name'): 'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('rate')"
                        @onKeyDown="onKeyPressDown('rate')"
                        type="text"
                        v-model="d_rate"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :class="{'color-red': isRedHighlightRow, 'error-authorisation': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}"
                        :isDisabled="isDisabledFields"
                        format="$0,0.00"></number-formatter>
    </div>
    <div v-if="isInsuranceRepairYes" @contextmenu.prevent="menuForOther"
         class="estimate-items-border estimate-items-adj">
      <number-formatter
          :ref="card + '-adj_price'"
          @onChange="onChange"
          @onKeyPress="onKeyPress"
          @updateDrag="updateDragFromNumberFormatter"
          :prev-cell="'rate'"
          :next-cell="'name'"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :style="{color: d_adj_rate > d_rate ? '#2BAE66FF': ((d_adj_rate < d_rate) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_rate"
          class="noselect form-control numeric adj"

          format="$0,0.00"
          v-on:blur="emitUpdate"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
      ></number-formatter>
    </div>
    <div @contextmenu.prevent="menuForOther" class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon"
              :class="{'error-authorisation-checkbox': (_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked}">
              <div v-if="(_isEmpty2(d_rate) || Number(d_rate) == 0) && d_isChecked">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a></div>
    </div>

    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForOther" class="comment-block"
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment" class="comment-text">
        <span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>

  <!--
    PDR
    -->

    <div v-else-if="card === 'PDR'" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp" class="qtit estimate-items-content"
         :class="[{ handle: isNotEditText, 'has-comment': item.comment }, computedClassForHighlightRow, computedClassForSelectedRow  ]">
        <div @contextmenu.prevent="menuForPDR" class="d-flex estimate-items-border estimate-items-name-all">
            <!--<i class="fa fa-arrows drag-handle" aria-hidden="true" title="drag this row to change its position in the list"></i>-->
                <input type="hidden"
                       :ref="card + '-id'"
                       v-model="d_id"/>
                <input type="text"
                       :ref="card + '-name'"
                   @mouseover="updateDrag(false)"
                   @mouseleave="updateDrag(true)"
                       v-if="d_type === 'Panel'"
                       @change="onChange"
                       @focus="onFocus"
                       @dblclick="onFocus"
                       @click="onFocus"
                       @blur="onBlur"
                       v-on:keypress="onKeyPress($event)"
                       v-on:keydown.left="onKeyPressLeft('price',$event,'edit')"
                       v-on:keydown.right="onKeyPressRight('material',$event,'edit')"
                       v-on:keydown.up="onKeyPressUp('name')"
                       v-on:keydown.down="onKeyPressDown('name')"
                   class="noselect form-control"
                   :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
                   v-model="d_name"
                   v-on:input="emitUpdate"/>
            <b-form-textarea :ref="card + '-name'"
                             v-else
                             no-resize
                                 :class="{'h-32': d_name.length < 10}"
                                 v-on:keypress="onKeyPress($event)"
                                 v-on:keydown.left="onKeyPressLeft('price',$event,'edit')"
                                 v-on:keydown.right="onKeyPressRight('material',$event,'edit')"
                                 v-on:keydown.up="onKeyPressUp('name')"
                                 v-on:keydown.down="onKeyPressDown('name')"
                                 rows="2"
                                 max-rows="8"
                                 style="scrollbar-width: none;"
                                 v-model="d_name"
                             :readonly="isDisabledFields  || (isAuthorisePage && !isItemNew)"
                             @change="onChange"
                             v-on:input="emitUpdate"
            >
            </b-form-textarea>
            <i v-if="isHasEstimateComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #02C39A; cursor: pointer; z-index: 99;"></i>
            <i v-if="isHasComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #FE914A; cursor: pointer; z-index: 99;"></i>
            <i v-if="isHasPartssearchComment" @click="showOrHideCommentBlock" class='bx bx-note' style="color: #29BBC1; cursor: pointer; z-index: 99;"></i>
            <!--            <div class="comment" v-if="d_comment" @click="">-->
            <!--                <span>comment :</span>-->
            <!--                <span>{{ d_comment }}</span>-->
            <!--            </div>-->
        </div>
        <div @contextmenu.prevent="menuForPDR" class="estimate-items-border estimate-items-pdr">
            <!-- we can add @select on this -->
            <multiselect :ref="card + '-material'"
                         v-model="d_material"
                         v-if="d_type === 'Panel' && !isMobile"
                         :options="optionsPDRMaterials"
                         :showLabels="false"
                         :option-height="29"
                         :max-height="203"
                         :close-on-select="true"
                         :disabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                   style="opacity: 1"
                   @onKeyLeft="onKeyPressLeft('name')"
                   @onKeyRight="onKeyPressRight('action')"
                   @select="emitMultiselect"
                   placeholder="Select"
                   openDirection="bottom"
      >
      </multiselect>
      <select :ref="card + '-material'"
              class="noselect form-control pr-2"

                    v-if="d_type === 'Panel' && isMobile">
                <option v-for="(option, index) in optionsPDRMaterials"
                        :key="index">
                    {{ option }}
                </option>
            </select>
        </div>
        <div @contextmenu.prevent="menuForPDR" class="estimate-items-border estimate-items-pdr">
            <!-- we can add @select on this -->
            <multiselect v-model="computedPDRAction"
                         :ref="card + '-action'"
                         v-if="d_type === 'Panel' && !isMobile"
                         :options="optionsPDRActions"
                         :showLabels="false"
                         :option-height="29"
                         :max-height="203"
                         :close-on-select="true"
                         :disabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                   style="opacity: 1"
                   @onKeyLeft="onKeyPressLeft('material')"
                   @onKeyRight="onKeyPressLeft('qty')"
                   placeholder="Select"
                   @select="emitMultiselect"
                   track-by="key"
                   label="label"
                   openDirection="bottom"
      >
      </multiselect>
      <select :ref="card + '-action'"
              class="noselect form-control pr-2"

                    v-if="d_type === 'Panel' && isMobile">
                <option v-for="(option, index) in optionsPDRActions"
                        class="noselect form-control px-2"
                        :key="index">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div @contextmenu.prevent="menuForPDR" class="estimate-items-border estimate-items-rate" style="width: 50px !important;" >
            <number-formatter :ref="card + '-qty'"
                              @onChange="onChange"
                              @onKeyPress="onKeyPress"
                              @updateDrag="updateDragFromNumberFormatter"
                              :prev-cell="'action'"
                              :next-cell="isInsuranceRepairYes? (isEnableManualAdj? 'adj_price': 'price'): 'price'"
                              @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                              @onKeyRight="onKeyPressRightOnNumberFormatter"
                              @onKeyUp="onKeyPressUp('qty')"
                              @onKeyDown="onKeyPressDown('qty')"
                              type="text"
                              v-model="d_qty"
                              v-on:blur="emitUpdate"
                              class="noselect form-control numeric"
                              :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        format="0,0.00">
      </number-formatter>
    </div>
    <div v-if="(showPDRPrice || !isPDRTechnicianUser) && isInsuranceRepairYes" @contextmenu.prevent="menuForPDR"
         class="estimate-items-border estimate-items-adj">
      <number-formatter
          :ref="card + '-adj_price'"
          @onChange="onChange"
          @updateDrag="updateDragFromNumberFormatter"
          @onKeyPress="onKeyPress"
          :prev-cell="'qty'"
          :next-cell="'price'"
          @onKeyLeft="onKeyPressLeftOnNumberFormatter"
          @onKeyRight="onKeyPressRightOnNumberFormatter"
          @onKeyUp="onKeyPressUp('adj_price')"
          @onKeyDown="onKeyPressDown('adj_price')"
          :style="{color: d_adj_rate > d_rate ? '#2BAE66FF': ((d_adj_rate < d_rate) ? 'red' : 'black')}"
          :isTotalManual="true"
          type="text"
          v-model="d_adj_rate"
          class="noselect form-control numeric adj"

          format="$0,0.00"
          v-on:blur="emitUpdate"
          :isDisabled="!isEnableManualAdj || isDisabledFields || (!isAuthorisePage && isAdjDisabled)"
      ></number-formatter>
    </div>
    <div @contextmenu.prevent="menuForPDR" class="estimate-items-border estimate-items-total">
      <number-formatter v-if="showPDRPrice || !isPDRTechnicianUser"
                        :ref="card + '-price'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="isInsuranceRepairYes? (isEnableManualAdj? 'adj_price': 'qty'): 'qty'"
                        :next-cell="'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('price')"
                        @onKeyDown="onKeyPressDown('price')"
                        type="text"
                        v-model="d_price"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        format="$0,0.00"
      >
      </number-formatter>
      <span v-else-if="showPDRPrice" :ref="card + '-total'" class="noselect form-control numeric"
            style="height: 32px;">{{ d_price | formatMoney }}</span>
    </div>
    <div @contextmenu.prevent="menuForPDR" class="estimate-items-border estimate-items-total">
      <number-formatter v-if="1==-1" :ref="card + '-total'"
                        @onChange="onChange"
                        @onKeyPress="onKeyPress"
                        @updateDrag="updateDragFromNumberFormatter"
                        :prev-cell="'price'"
                        :next-cell="'name'"
                        @onKeyLeft="onKeyPressLeftOnNumberFormatter"
                        @onKeyRight="onKeyPressRightOnNumberFormatter"
                        @onKeyUp="onKeyPressUp('total')"
                        @onKeyDown="onKeyPressDown('total')"
                        type="text"
                        v-model="d_total"
                        v-on:blur="emitUpdate"
                        class="noselect form-control numeric"
                        :isDisabled="isDisabledFields || (isAuthorisePage && !isItemNew)"
                        format="$0,0.00">
      </number-formatter>
      <span v-if="showPDRPrice || !isPDRTechnicianUser" :ref="card + '-total'" :class="{'color-red': isRedHighlightRow}"
            class="noselect form-control numeric"
            style="height: 32px;">{{ result | formatMoney }}</span>
    </div>
    <div class="estimate-items-border estimate-items-content-adj">
        <span v-if="item.adj_approve_status == 'reported' || item.adj_approve_status == 'declined'" class="noselect form-control numeric"
              style="border:none; padding-top: 10px;">{{ 0 | formatMoney }}</span>
    </div>
    <div class="estimate-items-border estimate-items-content-btn-remove d-flex"
         :class="{'estimate-items-content-btn-remove-width80': !isAuthorisePage}">
      <label class="form-check-inline checkbox" v-if="isAuthorisePage" style="padding-top: 8px;">
        <input :disabled="!isStatusActive || isAuthorizedItemList" class="form-check-input"
               v-model="d_isChecked" @change="$emit('checkedItem', card, id, d_isChecked)"
               type="checkbox">
        <span class="icon"
              :class="{'error-authorisation-checkbox': false && ((_isEmpty2(d_rate) || Number(d_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)) && d_isChecked}">
              <div
                  v-if="false && ((_isEmpty2(d_rate) || Number(d_rate) == 0) || (_isEmpty2(d_hrs) || Number(d_hrs) == 0)) && d_isChecked">!</div>
              <i class='bx bx-check' style="margin: 0px"></i>
            </span>
        <span class="text"></span>
      </label>

      <div v-if="item.adj_approve_status == 'approved' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Authorised', placement:'bottomleft'}"
           class='bx bxs-message-square-check adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'reported' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'On Report', placement:'bottomleft'}"
           class='bx bxs-message-square-detail adj-icon'></i>
      </div>
      <div v-if="item.adj_approve_status == 'denied' && !item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Declined', placement:'bottomleft'}"
           class='bx bxs-message-square-x adj-icon'></i>
      </div>
      <div v-if="item.addedByAssessor">
        <i v-b-tooltip.hover="{customClass: 'ps-tooltip adj-status-tooltip', title: 'Added by Assessor', placement:'bottomleft'}"
           class='bx bxs-message-square-edit adj-icon'></i>
      </div>
      <div>
        <a v-if="isCanRemove" @click.prevent="$emit('remove')" class="nav-link btn_trush" href=""
           style="width:20px;height:20px;"></a></div>
    </div>

    <div v-if="isShowCommentBlock || isNeedReduceCharacters" @contextmenu.prevent="menuForPDR" class="comment-block"
          :class="{'comment-block-active': (isShowCommentBlock || isNeedReduceCharacters)}">
      <p v-if="isNeedReduceCharacters" class="reduce-characters-text">
        Reduce Characters
      </p>
      <p v-if="isHasADJComment" class="comment-text">
        <span>ADJ:</span> {{ d_comments.adjComment ? d_comments.adjComment : item.externalComment }}
      </p>
      <p v-if="isHasManualComment" class="comment-text">
        <span>{{ d_comments.manualCommentAuthor }}:</span> {{ d_comments.manualComment }}
      </p>
      <p v-if="isHasEstimateComment" class="estimate-comment-text">
        <span>{{ d_comments.manualPdfCommentAuthor }}:</span> {{ d_comments.manualPdfComment }}
      </p>
      <p v-if="isHasPartssearchComment" class="estimate-comment-text" style="color: #29bbc1">
        <span>PartsSearch:</span> {{ item.partssearchComment }}
      </p>
      <p v-if="isHasInternalComment" class="estimate-comment-text">
        <span>{{ item.authorizedBy && item.authorizedBy.name ? item.authorizedBy.name : '' }}:</span>
        {{ item.internalComment }}
      </p>
      <p v-if="isHasExternalComment" class="estimate-comment-text">
        <span>ADJ:</span> {{ item.externalComment }}
      </p>
    </div>
  </div>

</template>

<style>
.V3 .adj-status-tooltip .tooltip-inner {
  transform: translateX(49px) !important;
  width: 124px !important;
}

.total-item-hover {
  background-color: rgba(255, 0, 0, 0);
}

.error-authorisation {
  color: red !important;
  border-color: red !important;
}

.color-red {
  color: red !important;
}

.error-authorisation-checkbox {
  font-weight: bold;
  font-size: 20px;
  color: red;
  background-color: #fdfdfd !important;
  border-color: red !important;
}

.error-authorisation-checkbox div {
  position: relative;
  left: 2px;
  bottom: 2px;
}

.error-authorisation-checkbox i.bx-check {
  display: none !important;
}

.selected-row {
  display: flex;
  width: 100%;
  background-color: #F9F9F9 !important;
  border: 2px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.3rem rgba(94, 121, 255, 0.25);
  border-radius: 3px;
  box-sizing: border-box;
}

tr.qtit div {
  position: relative;
}

/* i.fa.fa-arrows.drag-handle {
        position: absolute;
        top: 1.35rem;
        left: -0.55rem;
        color: #3e70c9;
        display:none;
        animation: 0.5s;
    }
    tr:hover i.fa.fa-arrows.drag-handle {
        display:block;
    } */
tr.qtit:hover {
  background-color: #F9F9F9 !important;
  cursor: move;
}

/* PDR */
tr.has-alt td,
tr.has-comment div {
  vertical-align: top !important;
  padding-top: 0.75rem;
}

tr.has-comment td:first-child input[type='text'] {
  margin-bottom: .75rem;
}

tr.has-alt td:first-child input[type='text'] {
  margin-bottom: .75rem;
  min-width: 200px;
}

tr.qtit .comment {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #5E6571;
  padding-left: 4px;
}

tr.qtit .comment span {
  display: inline-block;
  width: 60px;
  vertical-align: top;
}

tr.qtit .comment span:last-child {
  width: 72%;
}

.h-32 {
  height: 32px !important;
}

textarea.h-unset {
  height: unset !important;
}

.V3 .estimate-items-list .estimate-items span.noselect form-control {
  height: 32px;
}

#estimate-item-list .estimate-items {
  /*margin-bottom: 3rem !important;*/
}

/* receive from partssearch styles */
/* has-alt */
.qtit .td-group {
  width: 100%;
}

.qtit .td-wrapper {
  display: flex;
  flex: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.qtit.has-alt.handle input[type="text"] {
  margin-bottom: 0 !important;
}

.qtit.handle input {
  /*border: 0;*/
  /*color: #1C1F39 !important;*/
}

.qtit .bx {
  font-size: 20px;
  margin-left: 10px;
}

.qtit .bx {
  margin-top: 5px;
}

.qtit .bx-edit,
.qtit .bx-comment-detail {
  color: #EBAE42;
}

.qtit .bx-x {
  color: #CE3939;
}

.qtit .bx-question-mark {
  color: #FF7F2E;
}

.qtit .bx-plus-circle {
  color: #328530;
}

.qtit .bx-link {
  color: #A6A19C;
}

.qtit.is-linked td:first-child input,
.qtit .alt-name {
  opacity: 0.5;
  color: #1C1F39;
}


/* Icons focus style */
.qtit div a:focus {
  border-radius: 50%;
  text-shadow: 0 2px white;
}

.qtit .btn_trush:focus {
  border-radius: 0;
  text-shadow: none;
}

.qtit.part th,
.qtit.part div {
  padding: 0.75rem 0.25rem;
}

.estimate-items-paint-materials .multiselect__single,
.estimate-items-paint-materials .multiselect__input,
.estimate-items-paint-materials .multiselect__tags,
.estimate-items-part-num .multiselect__single,
.estimate-items-part-num .multiselect__input,
.estimate-items-part-num .multiselect__tags,
.estimate-items-pdr .multiselect__single,
.estimate-items-pdr .multiselect__input,
.estimate-items-pdr .multiselect__tags {
  min-width: auto;
}
</style>
<style>

#estimate-item-list .estimate-items div.highlight-red-deleted,
#estimate-item-list .estimate-items div.highlight-red-deleted:hover,
#estimate-item-list .estimate-items div.highlight-red-deleted .form-control,
#estimate-item-list .estimate-items div.highlight-red-deleted:hover .form-control,
#estimate-item-list .estimate-items div.highlight-red-deleted:hover .form-control:focus,
#estimate-item-list .estimate-items div.highlight-red-deleted .multiselect__tags,
#estimate-item-list .estimate-items div.highlight-red-deleted .multiselect__input,
#estimate-item-list .estimate-items div.highlight-red-deleted .multiselect__single {
  background-color: #FAEBEA !important;
}

#estimate-item-list .estimate-items div.highlight-yellow,
#estimate-item-list .estimate-items div.highlight-yellow:hover,
#estimate-item-list .estimate-items div.highlight-yellow .noselect form-control,
#estimate-item-list .estimate-items div.highlight-yellow:hover .noselect form-control {
  background-color: #FFFF00 !important;
}

#estimate-item-list .estimate-items div.highlight-yellow .noselect form-control:focus {
  background: #FFFFFF !important;
}

#estimate-item-list .estimate-items div.highlight-green,
#estimate-item-list .estimate-items div.highlight-green:hover,
#estimate-item-list .estimate-items div.highlight-green .noselect form-control,
#estimate-item-list .estimate-items div.highlight-green:hover .noselect form-control {
  background-color: #00B050 !important;
}

#estimate-item-list .estimate-items div.highlight-green .noselect form-control:focus {
  background: #FFFFFF !important;
}

#estimate-item-list .estimate-items div.highlight-purple,
#estimate-item-list .estimate-items div.highlight-purple:hover,
#estimate-item-list .estimate-items div.highlight-purple .noselect form-control,
#estimate-item-list .estimate-items div.highlight-purple:hover .noselect form-control {
  background-color: #F069ED !important;
}

#estimate-item-list .estimate-items div.highlight-purple .noselect form-control:focus {
  background: #FFFFFF !important;
}

.numeric-qty {
  min-width: 2.5rem !important;
}
</style>
<style>
    #estimate-item-list .estimate-items .icon-block:hover {
        cursor: pointer;
    }

    #estimate-item-list .estimate-items .icon-block {
        z-index: 99;
        display: flex;
    }
</style>
<style>

    /* New style for icons */
    #estimate-item-list .estimate-items tr.highlight-comment,
    #estimate-item-list .estimate-items tr.highlight-comment:hover,
    #estimate-item-list .estimate-items tr.highlight-comment .form-control,
    #estimate-item-list .estimate-items tr.highlight-comment:hover .form-control {
        background-color: rgb(151, 243, 186);
    }

    #estimate-item-list .estimate-items tr.highlight-comment .form-control:focus {
        background: #FFFFFF !important;
    }

    #estimate-item-list .estimate-items tr.highlight-circle,
    #estimate-item-list .estimate-items tr.highlight-circle:hover,
    #estimate-item-list .estimate-items tr.highlight-circle .form-control,
    #estimate-item-list .estimate-items tr.highlight-circle:hover .form-control {
        background-color: rgb(207, 207, 207) !important;
    }

    #estimate-item-list .estimate-items tr.highlight-circle .form-control:focus {
        background: #FFFFFF !important;
    }

    #estimate-item-list .estimate-items tr.highlight-edit,
    #estimate-item-list .estimate-items tr.highlight-edit:hover,
    #estimate-item-list .estimate-items tr.highlight-edit .form-control,
    #estimate-item-list .estimate-items tr.highlight-edit:hover .form-control {
        background-color: rgb(255, 246, 164) !important;
    }

    #estimate-item-list .estimate-items tr.highlight-edit .form-control:focus {
        background: #FFFFFF !important;
    }

    #estimate-item-list .estimate-items tr.highlight-flag,
    #estimate-item-list .estimate-items tr.highlight-flag:hover,
    #estimate-item-list .estimate-items tr.highlight-flag .form-control,
    #estimate-item-list .estimate-items tr.highlight-flag:hover .form-control {
        background-color: rgb(151, 209, 243) !important;
    }

    #estimate-item-list .estimate-items tr.highlight-flag .form-control:focus {
        background: #FFFFFF !important;
    }

    #estimate-item-list .estimate-items .additional-style-input-total {
        height: 32px;
    }
</style>
<style>

#estimate-item-list .estimate-items .comment-block {
  left: 0px;
  width: 100%;
  padding-left: 0px;
  display: none;
}

#estimate-item-list .estimate-items .comment-block-active {
  display: block;
}

#estimate-item-list .estimate-items .hide-border-bottom-when-comment-block-active {
  border-bottom: none !important;
}


#estimate-item-list .estimate-items .comment-block .comment-text {
  padding-left: 20px;
  color: #FE914A;
}

#estimate-item-list .estimate-items .comment-block .reduce-characters-text {
  padding-left: 20px;
  animation-name: blinking-red;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
  color: white;
  font-weight: bold;
}

@keyframes blinking-red {
  20% {
    color: #F44236;
    opacity: 1;
  }
  80% {
    color: #F44236;
    opacity: 1;
  }
}

#estimate-item-list .estimate-items .comment-block .comment-text span,
#estimate-item-list .estimate-items .comment-block .estimate-comment-text span {
  font-weight: bold;
}

#estimate-item-list .estimate-items .comment-block .estimate-comment-text {
  padding-left: 20px;
  color: #02C39A;
}
</style>

<style>
.adj-color-green {
  color: #2BAE66FF !important;
}

.adj-color-red {
  color: red !important;
}

.width-rr {
  min-width: 760px;
}

.width-paint {
  min-width: 920px;
}

.width-parts {
  min-width: 1115px;
}

.width-other {
  min-width: 450px;
}

.width-pdr {
  min-width: 1035px;
}

.estimate-items .qtit:hover {
  background-color: #F9F9F9 !important;
  cursor: move;
}

.estimate-items-content {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(27, 30, 56, .25);
}

.estimate-items .numeric {
  text-align: right;
}

.estimate-items .form-control {
  border: 0px;
  padding: .5rem .15rem;
}

.estimate-items-content .estimate-items-border {
  padding: 0.75rem;
}

.estimate-items-content-btn-remove {
  width: 111px !important;
  align-items: center;
}

.estimate-items-content-btn-remove-width80 {
  width: 80px !important;
}

.estimate-items-content-btn-remove .checkbox .icon {
  position: relative;
  bottom: 5px;
}

.estimate-items-content-btn-remove div {
  margin-left: 7px;
}

.estimate-items-content-btn-remove .form-check-inline.checkbox {
  margin-bottom: 10px !important;
}

.estimate-items-name-all {
  flex-grow: 1;
  min-width: 240px;
}

.estimate-items-name-repair {
  width: 40% !important;
}

.estimate-items-name-paint {
  width: 40% !important;
}

.estimate-items-name-parts {
  width: 40% !important;
}

.estimate-items-name-materials {
  width: 40% !important;
}

.estimate-items-name-sublet {
  width: 40% !important;
}

.estimate-items-name-other {
  width: 40% !important;
}

.estimate-items-name-pdr {
  width: 40% !important;
}

.estimate-items-hrs {
  width: 95px !important;
  flex-grow: 0;
}

.estimate-items-adj {
  width: 95px !important;
  flex-grow: 0;
}

.estimate-items-rate {
  width: 95px !important;
  flex-grow: 0;
}

.estimate-items-total {
  width: 95px !important;
  flex-grow: 0;
}

.estimate-items-type {
  width: 95px !important;
  flex-grow: 0;
}

.estimate-items-content-adj {
  width: 95px !important;
  flex-grow: 0;
}

.estimate-items-part-num {
  width: 125px !important;
  flex-grow: 0;
}

.estimate-items-repair {
  width: 125px !important;
  flex-grow: 0;
}

.estimate-items-pdr {
  width: 125px !important;
  flex-grow: 0;
}

.estimate-items-paint-materials {
  width: 80px !important;
  flex-grow: 0;
}

.btn_trush {
  margin-top: 2px;
  margin-left: 4px;
}

.adj-icon.bx {
  font-size: 24px;
  margin-left: 0;
  cursor: pointer;
  position: relative;
  bottom: 1px;
}

.adj-icon.bx.bxs-message-square-check {
  color: #498422;
}

.adj-icon.bx.bxs-message-square-detail {
  color: #d09400;
}

.adj-icon.bx.bxs-message-square-x {
  color: #bd5008;
}

.adj-icon.bx.bxs-message-square-edit {
  color: #498422;
}
.estimate-items-part-max-width{
  max-width: 244px;
}
@media (min-width: 1920px) {
  .estimate-items-part-max-width{
    max-width: 100%;
  }
}
.estimate-authorise-part-name-width{
  max-width: 100%;
}

</style>
<style scoped>
.dollar-total-input {
  max-width: 75px;
  margin-top: 10px;
}
.bx.bx-check {
  margin-left: 8px;
}
</style>
