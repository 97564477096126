<template>
  <div id="snapshot-view" class="estimate-snapshot">
    <tabs>
      <tab name="All" id="cards-all-snapshot">
        <div class="cards-all-snapshot-body">
          <div class="cards-all-snapshot-header d-flex">
            <div class="cards-all-snapshot-header-title w-100">
              <h1 style="text-align: center; padding-left: 23%">Estimate Performance Snapshot</h1>
            </div>
            <div class="cards-all-snapshot-header-period d-flex">
              <div class="cards-all-snapshot-header-period-num">
                Period of {{ currentFilterDates }}
              </div>
              <div class="cards-all-snapshot-header-period-selection">
                <div class="planer-period-menu">
                  <div class="dropdown dropdown-options show inline">
                    <button type="button"
                            class="btn waves-effect waves-light ex-options-button"
                            id="dropdownMenuLink1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true">
                      {{snapshotDateFilter}}
                      <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                    <i class="fa fa-chevron-down pull-right"></i>
                    </span>
                    </button>
                    <div style="z-index: 9999" class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink">
                      <a v-if="snapshotDateFilter != 'Month'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('estimate/setSnapshotDateFilter', 'Month')">Month (Current)</a>
                      <a v-if="snapshotDateFilter != 'Week'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('estimate/setSnapshotDateFilter', 'Week')">Week</a>
                      <a v-if="snapshotDateFilter != 'Day'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('estimate/setSnapshotDateFilter', 'Day')">Day</a>
                      <a v-if="snapshotDateFilter != 'Last Month'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('estimate/setSnapshotDateFilter', 'Last Month')">Last Month</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-all-snapshot-main">
            <div class="cards-all-snapshot-main-total d-flex">
              <div class="cards-all-snapshot-main-total_item">
                <div class="cards-all-snapshot-main-total_item-border">
                  <div class="cards-all-snapshot-main-total_item-sum cards-all-snapshot-main-total_item-sum-bold">
                    {{getTotalEstimatesAmount | formatMoney}}
                  </div>
                  <div class="cards-all-snapshot-main-total_item-name">
                    Total Estimates Written
                  </div>
                </div>
              </div>
              <div class="cards-all-snapshot-main-total_item">
                <div class="cards-all-snapshot-main-total_item-border">
                  <div class="cards-all-snapshot-main-total_item-sum">
                    {{getTotalInsuranceEstimatesAmount | formatMoney}}
                  </div>
                  <div class="cards-all-snapshot-main-total_item-name">
                    Insurance Estimates Written
                  </div>
                </div>
              </div>
              <div class="cards-all-snapshot-main-total_item">
                <div class="cards-all-snapshot-main-total_item-border">
                  <div class="cards-all-snapshot-main-total_item-sum">
                    {{getTotalNonInsuranceEstimatesAmount | formatMoney}}
                  </div>
                  <div class="cards-all-snapshot-main-total_item-name">
                    Non-Insurance Estimates Written
                  </div>
                </div>
              </div>
              <div class="cards-all-snapshot-main-total_item">
                <div class="cards-all-snapshot-main-total_item-border">
                  <div class="cards-all-snapshot-main-total_item-sum">
                    {{getFleetCustomersEstimatesAmount | formatMoney}}
                  </div>
                  <div class="cards-all-snapshot-main-total_item-name">
                    Fleet Customer Estimates
                  </div>
                </div>
              </div>
              <div class="cards-all-snapshot-main-total_item">
                <div class="cards-all-snapshot-main-total_item-border">
                  <div class="cards-all-snapshot-main-total_item-sum cards-all-snapshot-main-total_item-sum-bold">
                    {{getTotalInvoicedEstimatesAmount | formatMoney}}
                  </div>
                  <div class="cards-all-snapshot-main-total_item-name">
                    Total Invoiced
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-all-snapshot-main-graphs d-flex">
              <div class="cards-all-snapshot-main-graphs_item">
                <div class="cards-all-snapshot-main-graphs_item-title">
                  Estimator Performance
                </div>
                <div class="cards-all-snapshot-main-graphs_item-graph" :class="{'empty-chart': _isEmpty(computedGraphFirstSeries[0].data)}">
                  <apexchart type="bar" height="200" :options="computedGraphFirstOptions" :series="computedGraphFirstSeries"></apexchart>
                </div>
              </div>
              <div class="cards-all-snapshot-main-graphs_item">
                <div class="cards-all-snapshot-main-graphs_item-title">
                  Top Insurance Estimates Created
                </div>
                <div class="cards-all-snapshot-main-graphs_item-graph" :class="{'empty-chart': _isEmpty(computedGraphTwoSeries[0].data)}">
                  <apexchart type="bar" height="200" :options="computedGraphTwoOptions" :series="computedGraphTwoSeries"></apexchart>
                </div>
              </div>
              <div class="cards-all-snapshot-main-graphs_item">
                <div class="cards-all-snapshot-main-graphs_item-title">
                  Top Insurance Estimates Invoiced
                </div>
                <div class="cards-all-snapshot-main-graphs_item-graph" :class="{'empty-chart': _isEmpty(computedGraphThreeSeries[0].data)}">
                  <apexchart type="bar" height="200" :options="computedGraphThreeOptions" :series="computedGraphThreeSeries"></apexchart>
                </div>
              </div>
            </div>

            <template v-if="isShowTracker">
              <the-trackers></the-trackers>
            </template>
          </div>
        </div>
      </tab>
      <template slot="nav-item-center">
        <div class="btn-group cards-view-button cards-view-button-center" role="group">
          <button @click="$emit('setViewTable')" type="button" class="btn">Table</button>
          <button type="button" class="btn btn-primary active">Estimates Tracker</button>
        </div>
      </template>
      <template slot="nav-item-right">
<!--        <div class="tabs-nav-right-search" style="min-width: 290px !important">-->
<!--          <search-filter-->
<!--              type="estimates"-->
<!--              :debug="false"-->
<!--              height="41px"-->
<!--              aria-expanded="false"-->
<!--              data-toggle="dropdown"-->
<!--          />-->
<!--        </div>-->
      </template>
    </tabs>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import SearchFilter from '../../search-filter/search-filter';
import {mapGetters} from 'vuex'
import accounting from "accounting";
import dayjs from 'dayjs'
import TheTrackers from './the-trackers'
let isoWeek = require("dayjs/plugin/isoWeek");
let isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isoWeek)
dayjs.extend(isBetween);


export default {
  name: "estimates-snapshot",
  components: {
    apexchart: VueApexCharts,
    SearchFilter,
    TheTrackers
  },
  data: function () {
    return {
      currentDateFilter: 'Month',
    }
  },
  mounted() {
  },
  methods: {
    isCheckedDate(value) {
      if (!_.isString(value) || !value) {
        return false
      }
      let createdOnCopy = value.split("/")['1'] + '/' + value.split("/")['0'] + '/' + value.split("/")['2']
      let estDate = dayjs(createdOnCopy)
      if (this.snapshotDateFilter == 'Month') {
        return estDate.month() == this.currentMonth &&  estDate.year() == this.currentYear
      } else if (this.snapshotDateFilter == 'Last Month') {
        let prevMonth = new Date(new Date().setDate(0))
        return estDate.month() == prevMonth.getMonth() && estDate.year() == prevMonth.getFullYear()
      } else if (this.snapshotDateFilter == 'Day') {
        return value == dayjs().format('DD/MM/YYYY')
      } else if (this.snapshotDateFilter == 'Week') {
        let monday = dayjs().isoWeekday(1).format('MM/DD/YYYY')
        let sunday = dayjs().isoWeekday(7).format('MM/DD/YYYY')
        return dayjs(createdOnCopy).isBetween(monday, sunday, null , "[]");
      }
      return false
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    formatBarValues(value) {
      if (document.body.clientWidth <= 1380) {
        return this.formantCash(value)
      } else {
        return this.formatMoneyFunc(value)
      }
    },
    formatMoneyFunc(value) {
      return accounting.formatMoney(value)
    },
    formantCash(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return '$' + Number((n / 1e3)).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return '$' + Number((n / 1e6)).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return '$' + Number((n / 1e9)).toFixed(1) + "B";
      if (n >= 1e12) return '$' + Number((n / 1e12)).toFixed(1) + "T";
    }
  },
  computed: {
    ...mapGetters({
      getterAllEstimates: 'estimate/getAllEstimates',
      isShopManagerUser: 'isShopManagerUser',
      isProductionManagerUser: 'isProductionManagerUser',
      isAdministrator: 'isAdministrator',
      isCustomerUser: 'isCustomerUser',
      isEstimatorUser: 'isEstimatorUser',
      snapshotDateFilter: 'estimate/snapshotDateFilter',
    }),

    isShowTracker() {
      return this.isAdministrator || this.isShopManagerUser || this.isProductionManagerUser || this.isCustomerUser || this.isEstimatorUser
    },
    computedEstimatesAllFiltered() {
      return _.filter(this.getterAllEstimates, (est) => {
        return this.isCheckedDate(est.createdOn)
      })
    },
    computedEstimatesAllFilteredByInvoice() {
      return _.filter(this.getterAllEstimates, (est) => {
        let excessInvoiceDate = est.excessInvoiceDate ? dayjs(est.excessInvoiceDate).format('DD/MM/YYYY') : null;
        let invoiceDate = est.invoiceDate ? dayjs(est.invoiceDate).format('DD/MM/YYYY') : null;
        return this.isCheckedDate(excessInvoiceDate) || this.isCheckedDate(invoiceDate)
      })
    },
    currentFilterDates() {
      if (this.snapshotDateFilter == 'Month') {
        return dayjs().startOf('month').format('DD/MM/YYYY') + ' - ' + dayjs().endOf('month').format('DD/MM/YYYY')
      } else if (this.snapshotDateFilter == 'Last Month') {
        let prevMonth = new Date().setDate(0)
        return dayjs(prevMonth).startOf('month').format('DD/MM/YYYY') + ' - ' + dayjs(prevMonth).endOf('month').format('DD/MM/YYYY')
      } else if (this.snapshotDateFilter == 'Day') {
        return dayjs().format('DD/MM/YYYY')
      } else if (this.snapshotDateFilter == 'Week') {
        let monday = dayjs().isoWeekday(1).format('DD/MM/YYYY')
        let sunday = dayjs().isoWeekday(7).format('DD/MM/YYYY')
        return monday + ' - ' + sunday
      }
    },
    currentMonth() {
      return new Date().getMonth();
    },
    currentYear() {
      return new Date().getFullYear();
    },
    getTotalEstimatesAmount() {
      return _.sumBy(this.computedEstimatesAllFiltered, (est) => {
        return Number(est.amount)
      })
    },
    getTotalInvoicedEstimatesAmount() {
      return _.sumBy(this.computedEstimatesAllFilteredByInvoice, (est) => {
        let excessInvoiceDate = est.excessInvoiceDate ? dayjs(est.excessInvoiceDate).format('DD/MM/YYYY') : null;
        let invoiceDate = est.invoiceDate ? dayjs(est.invoiceDate).format('DD/MM/YYYY') : null;

        let r = 0;
        if (this.isCheckedDate(excessInvoiceDate)) {
          r +=  Number(est.excessInvoiceAmount)
        }
        if (this.isCheckedDate(invoiceDate)) {
          r += Number(est.invoiceAmount)
        }
        return r
      })
    },
    getTotalInsuranceEstimatesAmount() {
      let estimates = _.filter(this.computedEstimatesAllFiltered, (est) => {return !_.isEmpty(est.insurerName)})
      return _.sumBy(estimates, (est) => {
        return Number(est.amount)
      })
    },
    getTotalNonInsuranceEstimatesAmount() {
      let estimates = _.filter(this.computedEstimatesAllFiltered, (est) => {return _.isEmpty(est.insurerName)})
      return _.sumBy(estimates, (est) => {
        return Number(est.amount)
      })
    },

    getFleetCustomersEstimatesAmount() {
      let estimates = _.filter(this.computedEstimatesAllFiltered, (est) => {return est.customer_type == 'F'})
      return _.sumBy(estimates, (est) => {
        return Number(est.amount)
      })
    },

    getEstimatorCount() {
      let r = [];
      _.forEach(this.computedEstimatesAllFiltered, (est) => {
        if (!_.isEmpty(est.estimator)) {
          let rIns = _.find(r, (i) => { return i.name == est.estimator})
          if (rIns) {
            rIns.count += Number(est.amount)
          } else if (Number(est.amount)) {
            r.push({
              name: est.estimator,
              count: Number(est.amount),
            })
          }
        }
      })

      return r
    },
    computedGraphFirstOptions() {
      let self = this;
      let options = {
        chart: {
          type: 'bar',
          height: '5px'
        },
        colors:['#4371c2'],
        plotOptions: {
          bar: {
            borderRadius: 2,
            borderRadiusApplication: 'end',
            horizontal: true,
            barHeight: '30%',
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          tickAmount: 2,
          labels: {
            formatter: function (value) {
              return self.formatBarValues(value);
            }
          },
        },
        tooltip: {
          marker: {show: true},
          enabled: true,
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            let color = w?.globals?.colors[dataPointIndex]
            let result ='<div class="apexcharts-tooltip-title" style="font-family: \'Nunito Sans\'; font-size: 13px;">'+ w.globals.labels[dataPointIndex] +'</div><div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: '+color+';"></span><div class="apexcharts-tooltip-text" style="font-family: \'Nunito Sans\'; font-size: 13px;"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Estimate Totals: </span><span class="apexcharts-tooltip-text-y-value">'+self.formatMoneyFunc(series[seriesIndex][dataPointIndex])+'</span></div><div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div></div></div></div>'

            return result
          },
          y: {
            // title: {
            //   formatter: (v) => {
            //     console.log('formatter',v)
            //     return 'Estimate Totals: '
            //   },
            // },
          },
          style: {
            fontSize: '13px',
            fontFamily: 'Nunito Sans',
            fontWeight: 'bold',
          },
        },
      }

      return options
    },
    computedGraphFirstSeries () {
      let series = [{
        data: []
      }]
      _.forEach(_.orderBy(this.getEstimatorCount, 'count', 'desc').slice(0, 5).reverse(), (i) => {
        let v = Number(accounting.toFixed(i.count, 2))
        series[0].data.push({
          x: i.name,
          y: v
        })
      })

      return series

    },

    getInsuranceEstimatesCount () {
      let r = []
      _.forEach(this.computedEstimatesAllFiltered, (est) => {
        if (!_.isEmpty(est.insurerName)) {
          let rIns = _.find(r, (i) => { return i.name == est.insurerName})
          if (rIns) {
            rIns.count += Number(est.amount)
          } else if (Number(est.amount)) {
            r.push({
              name: est.insurerName,
              count: Number(est.amount),
            })
          }
        }
      })

      return r
    },
    computedGraphTwoOptions() {
      let self = this;
      let options = {
            chart: {
              type: 'bar',
              height: '5px'
            },
            colors:['#4371c2'],
            plotOptions: {
              bar: {
                borderRadius: 2,
                borderRadiusApplication: 'end',
                horizontal: true,
                barHeight: '30%',
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              tickAmount: 2,
              labels: {
                formatter: function (value) {
                  return self.formatBarValues(value);
                }
              },
            },
            tooltip: {
              marker: {show: true},
              enabled: true,
              custom: function({series, seriesIndex, dataPointIndex, w}) {
                let color = w?.globals?.colors[dataPointIndex]
                let result ='<div class="apexcharts-tooltip-title" style="font-family: \'Nunito Sans\'; font-size: 13px;">'+ w.globals.labels[dataPointIndex] +'</div><div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: '+color+';"></span><div class="apexcharts-tooltip-text" style="font-family: \'Nunito Sans\'; font-size: 13px;"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Estimate Totals: </span><span class="apexcharts-tooltip-text-y-value">'+self.formatMoneyFunc(series[seriesIndex][dataPointIndex])+'</span></div><div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div></div></div></div>'

                return result
              },
              y: {
                // title: {
                //   formatter: () => {
                //     return 'Estimate Totals: '
                //   },
                // },
              },
              style: {
                fontSize: '13px',
                fontFamily: 'Nunito Sans',
                fontWeight: 'bold',
              },
            },
          }

      return options
    },
    computedGraphTwoSeries() {
      let series = [{
        data: []
      }];
      _.forEach(_.orderBy(this.getInsuranceEstimatesCount, 'count', 'desc').slice(0, 5).reverse(), (i) => {
        series[0].data.push({
          x: i.name,
          y: Number(accounting.toFixed(i.count, 2))
        })
      })

      return series

    },

    getInsuranceEstimatesInvoicedCount() {
      let r = [];
      _.forEach(this.computedEstimatesAllFilteredByInvoice, (est) => {
        if (!_.isEmpty(est.insurerName)) {
          let rIns = _.find(r, (i) => { return i.name == est.insurerName})
          let excessInvoiceDate = est.excessInvoiceDate ? dayjs(est.excessInvoiceDate).format('DD/MM/YYYY') : null;
          let invoiceDate = est.invoiceDate ? dayjs(est.invoiceDate).format('DD/MM/YYYY') : null;

          let count = 0;
          if (this.isCheckedDate(excessInvoiceDate)) {
            count +=  Number(est.excessInvoiceAmount)
          }
          if (this.isCheckedDate(invoiceDate)) {
            count += Number(est.invoiceAmount)
          }

          if (rIns) {
            rIns.count += count
          } else if (count) {
            r.push({
              name: est.insurerName,
              count: count,
            })
          }
        }
      })

      return r
    },
    computedGraphThreeOptions() {
      let self = this;
      let options = {
        chart: {
          type: 'bar',
          height: '5px'
        },
        colors:['#4371c2'],
        plotOptions: {
          bar: {
            borderRadius: 2,
            borderRadiusApplication: 'end',
            horizontal: true,
            barHeight: '30%',
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          tickAmount: 2,
          labels: {
            formatter: function (value) {
              return self.formatBarValues(value);
            }
          },
        },
        tooltip: {
          marker: {show: true},
          enabled: true,
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            let color = w?.globals?.colors[dataPointIndex]
            let result ='<div class="apexcharts-tooltip-title" style="font-family: \'Nunito Sans\'; font-size: 13px;">'+ w.globals.labels[dataPointIndex] +'</div><div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: '+color+';"></span><div class="apexcharts-tooltip-text" style="font-family: \'Nunito Sans\'; font-size: 13px;"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Estimate Totals: </span><span class="apexcharts-tooltip-text-y-value">'+self.formatMoneyFunc(series[seriesIndex][dataPointIndex])+'</span></div><div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div></div></div></div>'

            return result
          },
           y: {
          //   title: {
          //     formatter: () => {
          //       return 'Estimate Totals: '
          //     },
          //   },
          },
          style: {
            fontSize: '13px',
            fontFamily: 'Nunito Sans',
            fontWeight: 'bold',
          },
        },
      }

      return options
    },
    computedGraphThreeSeries() {
      let series = [{
        data: []
      }];
      _.forEach(_.orderBy(this.getInsuranceEstimatesInvoicedCount, 'count', 'desc').slice(0, 5).reverse(), (i) => {
        series[0].data.push({
          x: i.name,
          y: Number(accounting.toFixed(i.count, 2))
        })
      })

      return series

    },
  },
}
</script>

<style>
#snapshot-view .empty-chart .apexcharts-yaxis {
  opacity: 0;
}
</style>
<style scoped>
.cards-all-snapshot-main-graphs_item:first-child{
  margin-left: 0;
}
.cards-all-snapshot-main-graphs_item:last-child{
  margin-right: 0;
}
.cards-all-snapshot-main-graphs_item-title{
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}
.cards-all-snapshot-main-graphs_item-graph >>> .apexcharts-menu-icon{
  display: none;
}
.cards-all-snapshot-body{
  padding-top: 20px;
}
.cards-all-snapshot-header{
  justify-content: space-between;
}
.cards-all-snapshot-header-title h1{
  font-size: 25px;
  font-weight: bold;
}
.cards-all-snapshot-header-period-num{
  font-weight: 600;
  margin-top: 10px;
  margin-right: 30px;
}
.cards-all-snapshot-main{
  margin-top: 20px;
}
.cards-all-snapshot-main-total_item{
  text-align: center;
  flex: 20%;
  padding: 5px;
}
.cards-all-snapshot-main-total_item-border{
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 20px 20px;
  height: 100%;
}
.cards-all-snapshot-main-total_item:first-child{
  margin-left: 0;
}
.cards-all-snapshot-main-total_item:last-child{
  margin-right: 0;
}
.cards-all-snapshot-main-total_item-sum{
  font-weight: 700;
  font-size: 23px;
}
.cards-all-snapshot-main-total_item-name{
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}
.cards-all-snapshot-main-total_item-sum-bold{
  font-size: 25px;
  font-weight: 900;
}
.cards-all-snapshot-main-graphs{
  margin-top: 10px;
}
.cards-all-snapshot-main-graphs_item{
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  margin: 0 5px;
  padding: 20px 5px;
  flex: 33.333%;
}



@media screen and (max-width: 1100px) {
  .cards-all-snapshot-main-graphs{
    flex-flow: wrap;
  }
  .cards-all-snapshot-main-graphs_item{
    flex: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 5px !important;
  }
  .cards-all-snapshot-main-total{
    flex-flow: wrap;
    justify-content: space-around;
  }
  .cards-all-snapshot-main-total_item{
    flex: 33.333%;
    max-width: 30%;
  }
}
.cards-view-button-center{
  margin-left: 20vw;
}
@media screen and (min-width: 1900px) {
  .cards-view-button-center{
    margin-left: 35vw;
  }
}
@media screen and (max-width: 1200px) {
  .cards-view-button-center{
    margin-left: 7vw;
  }
}
@media screen and (max-width: 880px) {
  .cards-view-button-center{
    margin-left: 20px;
  }
}
@media screen and (max-width: 460px) {
  .estimate-snapshot >>> .tabs-component {
    top: 116px;
    padding-top: 10px;
  }
}
</style>
