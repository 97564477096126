import { render, staticRenderFns } from "./activity-list-default.vue?vue&type=template&id=31993130&scoped=true"
import script from "./activity-list-default.vue?vue&type=script&lang=js"
export * from "./activity-list-default.vue?vue&type=script&lang=js"
import style0 from "./activity-list-default.vue?vue&type=style&index=0&id=31993130&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31993130",
  null
  
)

export default component.exports