<template>
    <div>
        <div class="card-header">Xero Integration</div>
        <div class="card-block" style="padding-left: 0px; padding-right: 0px">

            <div class="form-group row">
                <label class="col-sm-4 col-4">
                    Xero status:
                    <span v-show="!originalCompanyInfo.activeXeroAuth" class="text-danger">not connected</span>
                    <span v-show="originalCompanyInfo.activeXeroAuth" class="text-success">connected</span>
                </label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <a v-show="!originalCompanyInfo.activeXeroAuth" href="#" class="btn btn-primary" @click.prevent="connectToXero">Connect to XERO</a>
                    <a v-show="originalCompanyInfo.activeXeroAuth" href="#" class="btn btn-danger btn-sm" @click.prevent="disconnectFromXero">Disconnect</a>
                </div>
            </div>


            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Selected Xero Company</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <multiselect
                            v-model="computedTenantId"
                            :options="computedTenants"
                            :showLabels="false"
                            :close-on-select="true"
                            :option-height="29"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :max-height="203"
                            placeholder="Select Xero Company"
                            track-by="key"
                            label="name"
                    ></multiselect>
                </div>
            </div>


            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Create New Invoice - (Default)</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <multiselect
                            v-model="xeroAuth.defaultInvoiceStatus"
                            :options="xeroStatuses.map(data => data.value)"
                            :custom-label="opt => xeroStatuses.find(x => x.value == opt).label"
                            :showLabels="false"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :close-on-select="true"
                            :option-height="29"
                            :max-height="203"
                            placeholder="Select one item"
                    >
                    </multiselect>
                </div>
            </div>

            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Invoice Reference Desc</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <multiselect
                            v-model="xeroAuth.invoiceReferenceDesc"
                            :options="invoiceReferenceDescs.map(data => data.value)"
                            :custom-label="opt => invoiceReferenceDescs.find(x => x.value == opt).label"
                            :showLabels="true"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :close-on-select="true"
                            :option-height="29"
                            :max-height="203"
                            placeholder="Select Reference Desc"
                    >
                    </multiselect>
                </div>
            </div>

            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Invoice GST Setting</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <multiselect
                            v-model="xeroAuth.defaultGstSetting"
                            :options="xeroGstSettings.map(data => data.value)"
                            :custom-label="opt => xeroGstSettings.find(x => x.value == opt).label"
                            :showLabels="false"
                            :close-on-select="true"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :option-height="29"
                            :max-height="203"
                            placeholder="Select Invoice GST Setting"
                    >
                    </multiselect>
                </div>
            </div>

            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Complete Invoice Line Item Code</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <input v-model="xeroAuth.defaultLineItemCode" type="text" :readonly="!isStatusActive"
                           class="form-control" placeholder="Complete Invoice Line Item Code"/>
                </div>
            </div>

            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Excess Invoice Line Item Code</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <input v-model="xeroAuth.defaultExcessLineItemCode" type="text" :readonly="!isStatusActive"
                           class="form-control" placeholder="Excess Invoice Line Item Code"/>
                </div>
            </div>

            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Due Date - (Default)</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <multiselect
                            v-model="xeroAuth.invoiceDueDate"
                            :options="invoiceDueDates.map(data => data.value)"
                            :custom-label="opt => invoiceDueDates.find(x => x.value == opt).label"
                            :showLabels="false"
                            :close-on-select="true"
                            :option-height="29"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :max-height="203"
                            placeholder="Default Due Date"
                    >
                    </multiselect>
                </div>
            </div>

            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Auto update Xero invoice</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <multiselect
                            v-model="computedAutoUpdateInvoice"
                            :options="xeroToggleVariants.map(data => data.value)"
                            :custom-label="opt => xeroToggleVariants.find(x => x.value === opt).label"
                            :showLabels="false"
                            :close-on-select="true"
                            :option-height="29"
                            :max-height="203"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            placeholder="Enable or disable feature"
                    >
                    </multiselect>
                </div>
            </div>

            <div v-show="originalCompanyInfo.activeXeroAuth" class="form-group row">
                <label class="col col-form-label col-lg-4">Auto delete/void Xero invoice</label>
                <div class="col-sm-8 col-8 float-sm-right">
                    <multiselect
                            v-model="computedAutoDeleteInvoice"
                            :options="xeroToggleVariants.map(data => data.value)"
                            :custom-label="opt => xeroToggleVariants.find(x => x.value == opt).label"
                            :showLabels="false"
                            :close-on-select="true"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :option-height="29"
                            :max-height="203"
                            placeholder="Enable or disable feature"
                    >
                    </multiselect>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Axios from 'axios';
    import {appConfig} from '../../../config';
    import _ from 'lodash';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'Xero',
        components: {
            Multiselect
        },
        data() {
            return {
                xeroAuth: {
                    tenantId: null,
                    tenants: [],
                    defaultInvoiceStatus: null,
                    defaultGstSetting: null,
                    defaultLineItemCode: null,
                    defaultExcessLineItemCode: 'Invoice GST - Exclusive',
                    autoUpdateInvoice: null,
                    autoDeleteInvoice: 1,
                    invoiceReferenceDesc: null,
                    invoiceDueDate: null,
                },
                xeroStatuses: [
                    {label: 'Draft', value: 'Draft'},
                    {label: 'Submitted', value: 'Submitted'},
                    {label: 'Authorised', value: 'Authorised'},
                ],
                invoiceReferenceDescs: [
                    {label: 'Card Number', value: 'CardNumber'},
                    {label: 'Estimate Number', value: 'EstimateNumber'},
                    {label: 'Rego', value: 'Rego'},
                    {label: 'Insurer Name', value: 'InsurerName'},
                ],
                invoiceDueDates: [
                    {label: 'Today', value: 'Today'},
                    {label: 'In 7 Days', value: 'In7Days'},
                    {label: 'In 14 Days', value: 'In14Days'},
                    {label: 'In 30 days', value: 'In30days'},
                    {label: '1st day of Next Month', value: 'FirstDayOfNextMonth'},
                    {label: 'Last day of Next month', value: 'LastDayOfNextMonth'},
                ],
                xeroGstSettings: [
                    {label: 'Inclusive', value: 'Inclusive'},
                    {label: 'Exclusive', value: 'Exclusive'},
                ],
                xeroAuthEditable: false,
                xeroToggleVariants: [
                    {label: 'Enabled', value: 1},
                    {label: 'Disabled', value: 0},
                ],
                debouncedSave: null,
            };
        },
        computed: {
            ...mapGetters({
                // originalCompanyInfo: "currentCompany/getDetails",
                originalCompanyInfo: 'vendorInfo',
                isStatusActive: 'isStatusActive',
            }),
            computedAutoUpdateInvoice: {
                get() {
                    if (this.xeroAuth.autoUpdateInvoice == 1 || this.xeroAuth.autoUpdateInvoice === true) {
                        return 1;
                    }
                    return 0;
                },
                set(val) {
                    if (val == 1 || val === true) {
                        this.xeroAuth.autoUpdateInvoice = true;
                    } else {
                        this.xeroAuth.autoUpdateInvoice = false;
                    }
                    console.log('valval222', this.xeroAuth.autoUpdateInvoice)
                }
            },
            computedAutoDeleteInvoice: {
                get() {
                    if (this.xeroAuth.autoDeleteInvoice == 1 || this.xeroAuth.autoDeleteInvoice === true) {
                        return 1;
                    }
                    return 0;
                },
                set(val) {
                    if (val == 1 || val === true) {
                        this.xeroAuth.autoDeleteInvoice = true;
                    } else {
                        this.xeroAuth.autoDeleteInvoice = false;
                    }
                }
            },
            computedTenants() {
                let tentants = [];
                _.forEach(this.xeroAuth.tenants, function (value, key) {
                    tentants.push({key: key, name: value});
                });
                return tentants;
            },
            computedTenantId: {
                get() {
                    let vm = this;
                    let result = _.find(this.computedTenants, function (i) {
                        return i.key == vm.xeroAuth.tenantId;
                    });
                    console.log(result);
                    return result || null;
                },
                set(val) {
                    this.xeroAuth.tenantId = val.key;
                }
            },
        },
        created: function () {
            this.debouncedSave = _.debounce(this.saveXeroAuthData, 1000);
        },
        mounted() {
            this.loadXeroAuthData();
        },
        methods: {
            loadXeroAuthData() {
                Axios.get(`/fe/settings/get-xero-auth`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            if (response.data.data) {
                                this.xeroAuth = response.data.data;
                                setTimeout(() => {
                                    this.xeroAuthEditable = true;
                                }, 300);
                            } else {
                                this.xeroAuth = {
                                    tenantId: null,
                                    tenants: [],
                                    defaultInvoiceStatus: null,
                                    defaultGstSetting: null,
                                    defaultLineItemCode: null,
                                    defaultExcessLineItemCode: 'Invoice GST - Exclusive',
                                    autoUpdateInvoice: 0,
                                    autoDeleteInvoice: 0,
                                    invoiceReferenceDesc: null,
                                    invoiceDueDate: null,
                                };
                            }
                        }
                    })
                    .catch(e => console.error(e));
            },
            saveXeroAuthData() {
                Axios.post(`/fe/settings/update-xero-auth`, this.xeroAuth)
                    .then(response => {
                        if (response.data && response.data._status) {
                            toastr.success('Xero settings has been updated successfully!');
                        }
                    })
                    .catch(e => console.error(e));
            },
            connectToXero() {
                let wnd = window.open(`${appConfig.baseAPIURL}/settings/xero-oauth-start`, 'xero-auth-window', 'width=800,height=600,location=on');
                window.addEventListener('message', e => {
                    console.log(e);
                    let data = e.data;
                    if(data && typeof data !== "string") return;
                    if(data && data.source === 'vue-devtools-proxy') return;
                    if(data && data.source === 'vue-devtools-backend') return;
                    wnd.close();
                    data = JSON.parse(data);
                    console.log(data);

                    Axios.post(`/fe/settings/set-xero-auth`, data)
                        .then(response => {
                            if (response.data && response.data._status) {
                                this.$store.dispatch('loadVendorInfo', {}, {root: true});
                                this.loadXeroAuthData();
                            }
                        })
                        .catch(e => console.error(e));

                });
            },
            disconnectFromXero() {
                if (!this.isStatusActive) {
                  return;
                }
                Axios.post(`/fe/settings/remove-xero-auth`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.$store.dispatch('loadVendorInfo', {}, {root: true});
                        }
                    })
                    .catch(e => console.error(e));
            },
        },
        watch: {
            xeroAuth: {
                deep: true,
                handler() {
                    if (this.xeroAuthEditable) {
                        this.debouncedSave();
                    }
                }
            },
        },
    };
</script>

<style scoped>

    .btn-danger {
        font-weight: bold;
        border-radius: 3px;
        font-size: 1rem;
    }

    .custom-select option {
        background-color: #5E6571 !important;

    }

</style>
