<template>
    <div style="transform: translateY(-10px)">
        <div class="tabs-component tabs-component-2" style="padding-top: 0px">
            <div class="tabs-component-w">
                <ul class="nav nav-tabs nav-tabs-2  float-sm-left">
                    <li v-if="vendorInfo && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.partsSearch" style='padding-top: 0px' @click="selectIntegration('partssearch')" class="tabs-component-tab nav-item">
                        <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'partssearch'}">Partssearch</span>
                    </li>
                    <li @click="selectIntegration('claimlinq')" class="tabs-component-tab nav-item">
                      <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'claimlinq'}">Claimlinq</span>
                    </li>
                    <li v-if="vendorInfo && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.estimage" @click="selectIntegration('estimage')" class="tabs-component-tab nav-item">
                      <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'estimage'}">Estimage</span>
                    </li>
                    <li v-if="vendorInfo && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="selectIntegration('xero')" class="tabs-component-tab nav-item">
                      <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'xero'}">Xero</span>
                    </li>
                    <li v-if="vendorInfo && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.orm" @click="selectIntegration('orm')" class="tabs-component-tab nav-item">
                      <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'orm'}">IAG ORM</span>
                    </li>
                    <li @click="selectIntegration('webtrim')" class="tabs-component-tab nav-item">
                      <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'webtrim'}">Webtrim</span>
                    </li>
                    <li @click="selectIntegration('scanner')" class="tabs-component-tab nav-item">
                      <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'scanner'}">Scanner</span>
                    </li>
                    <li @click="selectIntegration('auda')" class="tabs-component-tab nav-item">
                      <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'auda'}">AudaNet</span>
                    </li>
                </ul>
            </div>
        </div>

        <PartsSearch v-if="isShowPartsSearch" v-model="value"></PartsSearch>
        <Claimlinq v-if="isShowClaimlinq" v-model="value"></Claimlinq>
        <Estimage v-if="isShowEstimage" v-model="value"></Estimage>
        <xero-integration v-if="isShowXero" style="margin-top: 15px; padding-left: 15px; padding-right: 0px" class="card row no-gutter card col-lg-5 col-12"></xero-integration>
        <iag-orm-integration v-if="isShowIagOrm" style="margin-top: 15px; padding-left: 15px; padding-right: 0px" class="card row no-gutter"></iag-orm-integration>
        <webtrim v-if="isShowWebtrim" ref="webtrim"></webtrim>
        <auda v-if="isShowAuda" style="margin-top: 15px; padding-left: 15px; padding-right: 0px" class="card row no-gutter card col-lg-5 col-12"></auda>
        <scanner v-if="isShowScanner" style="margin-top: 15px; padding-left: 15px; padding-right: 0px" class="card row no-gutter card col-lg-5 col-12"></scanner>
    </div>

</template>

<script>
import XeroIntegration from './integrations/Xero';
import PartsSearch from "./integrations/PartsSearch";
import Claimlinq from "./integrations/Claimlinq";
import Estimage from './integrations/Estimage';
import Auda from './integrations/Audanet';
import Scanner from './integrations/Scanner';
import _ from "lodash";
import IagOrmIntegration from './integrations/Orm';
import { mapGetters } from 'vuex'
import Multiselect from "vue-multiselect";
import Webtrim from "@/components/settings/integrations/Webtrim.vue";

export default {
    name: "Integrations",
    components: {
      Webtrim,
      XeroIntegration,
      PartsSearch,
      Claimlinq,
      Estimage,
      IagOrmIntegration ,
      Multiselect,
      Auda,
      Scanner
    },
    props: {
        value: {}
    },
    data: function() {
        return {
            isShowPartsSearch: true,
            isShowEstimage: false,
            isShowClaimlinq: false,
            isShowXero: false,
            isShowAuda: false,
            isShowScanner: false,
            selectedTab: 'partssearch',
            isShowIagOrm: false,
            isShowWebtrim: false,
        };
    },
    mounted () {
      if (!_.isEmpty(this.$storage.get("views.advancedSettings.integrations.defaultView"))) {
        this.selectedTab = this.$storage.get("views.advancedSettings.integrations.defaultView");
      } else {
        let path = this.$route.path.split('/');
        let subTab = path[path.length-1];
        if (subTab === 'partssearch' || subTab === 'xero' || subTab === 'estimage' || subTab === 'claimlinq' || subTab === 'auda' || subTab === 'iag-orm' || subTab === 'scanner' || subTab === 'webtrim' || subTab === 'orm') {
          this.selectedTab = subTab;
        } else {
          this.selectedTab = 'xero';
        }
      }
      if (this.selectedTab === 'partssearch') {
        this.setTabVisible('isShowPartsSearch')
      } else if (this.selectedTab === 'webtrim') {
        this.setTabVisible('isShowWebtrim')
      } else if (this.selectedTab === 'estimage') {
        this.setTabVisible('isShowEstimage')
      } else if (this.selectedTab === 'xero') {
        this.setTabVisible('isShowXero')
      } else if (this.selectedTab === 'claimlinq') {
        this.setTabVisible('isShowClaimlinq')
      } else if (this.selectedTab === 'orm') {
        this.setTabVisible('isShowIagOrm')
      }  else if (this.selectedTab === 'auda') {
        this.setTabVisible('isShowAuda')
      } else if (this.selectedTab === 'scanner') {
        this.setTabVisible('isShowScanner')
      } else{
        this.setTabVisible('isShowPartsSearch')
      }
    },
    computed: {
      ...mapGetters({
        isStatusActive: 'isStatusActive',
        vendorInfo: 'vendorInfo',
      }),
    },
    methods: {
      setTabVisible(tab) {
        this.isShowPartsSearch = tab == 'isShowPartsSearch' ? true : false;
        this.isShowEstimage = tab == 'isShowEstimage' ? true : false;
        this.isShowClaimlinq = tab == 'isShowClaimlinq' ? true : false;
        this.isShowXero = tab == 'isShowXero' ? true : false;
        this.isShowAuda = tab == 'isShowAuda' ? true : false;
        this.isShowIagOrm = tab == 'isShowIagOrm' ? true : false;
        this.isShowWebtrim = tab == 'isShowWebtrim' ? true : false;
        this.isShowScanner = tab == 'isShowScanner' ? true : false;
      },
      saveTab(tab) {
        this.selectedTab = tab;
        this.$storage.set("views.advancedSettings.integrations.defaultView", tab);
        let params = {name: 'integrations', params: {tab: tab}};
        this.$router.push(params).catch(()=>{});
      },
      saveWebtrim() {
        this.$refs.webtrim.save()
      },
      selectIntegration(tab) {
        if (tab === 'partssearch') {
          this.saveTab('partssearch')
          this.setTabVisible('isShowPartsSearch')
        } else if (tab === 'estimage') {
          this.saveTab('estimage')
          this.setTabVisible('isShowEstimage')
        } else if (tab === 'xero') {
          this.saveTab('xero')
          this.setTabVisible('isShowXero')
        } else if (tab === 'claimlinq') {
          this.saveTab('claimlinq')
          this.setTabVisible('isShowClaimlinq')
        } else if (tab === 'webtrim') {
          this.saveTab('webtrim')
          this.setTabVisible('isShowWebtrim')
        } else if (tab === 'orm') {
          this.saveTab('orm')
          this.setTabVisible('isShowIagOrm')
        } else if (tab === 'auda') {
          this.saveTab('auda')
          this.setTabVisible('isShowAuda')
        } else if (tab === 'scanner') {
          this.saveTab('scanner')
          this.setTabVisible('isShowScanner')
        }
      },
    }
    };
</script>

<style scoped>
    .tab-content .active {
        width: 100%;
    }

    .tabs-component-2::after {
        content: '';
        width: 100%;
        display: block;
        background: #e3e4e7;
        height: 1px;
        clear: both;
    }

    .nav-item:hover {
        cursor: pointer;
    }

    .V3 .tabs-component.tabs-component-2 {
        top: 228px;
    }
</style>
