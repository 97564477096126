<script>
  import draggable from 'vuedraggable';
  import Multiselect from "vue-multiselect";
  import Axios from 'axios';
  import _ from "lodash";
  import {mapGetters} from "vuex";

  export default {
  name: "Triggers",
  components: {
    draggable,
    Multiselect,
    Axios,
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
    optionsForTriggerType() {
      let options = [];
      _.forEach(this.triggerCategories, (cat) => {
        options.push( {key: cat.id, value: cat.name})
      });
      return options;
    },
    computedTriggerType: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.optionsForTriggerType, function (itm) {
            return itm.key == vm.trigger.type;
          });
          return item;
        } catch (e) {
          return {};
        }
      },
      set: function (item) {
        if (item) {
          this.trigger.type = item.key;
        }
      },
    },
  },

  props: {
    triggers: {
      type: Array,
      default: function () {
        return [];
      },
    }
  },
  data(){
    return {
      isMovedElement: false,
      conditionTypes: [
        "And",
        "Or",
      ],
      triggerCategories:[
        {
          id: 1,
          name: "Option",
        },
        {
          id: 2,
          name: "Email",
        },
        {
          id: 3,
          name: "Integration",
        },
        {
          id: 4,
          name: "Delay",
        },
      ],
      triggerSubcategories: [
        {
          id: 1,
          categoryId: 1,
          name: "Cards",
        },
        {
          id: 2,
          categoryId: 1,
          name: "Estimates",
        },
        {
          id: 3,
          categoryId: 1,
          name: "Invoices",
        },
        {
          id: 4,
          categoryId: 1,
          name: "Parts",
        },
        {
          id: 5,
          categoryId: 2,
          name: "Sent",
        },
        {
          id: 6,
          categoryId: 3,
          name: "Sent",
        },
        {
          id: 7,
          categoryId: 4,
          name: "Delay",
        },
        {
          id: 8,
          categoryId: 3,
          name: "Incoming",
        },
      ],
      triggerNames:[
        // Option - Cards
        {
          id: 111,
          categoryId: 1,
          subcategoryId: 1,
          name: "Create Estimate",
        },
        {
          id: 112,
          categoryId: 1,
          subcategoryId: 1,
          name: "Mark As Open",
        },
        {
          id: 116,
          categoryId: 1,
          subcategoryId: 1,
          name: "Check In Customer",
        },
        {
          id: 113,
          categoryId: 1,
          subcategoryId: 1,
          name: "Mark As Active",
        },
        {
          id: 114,
          categoryId: 1,
          subcategoryId: 1,
          name: "Mark As Closed",
        },
        {
          id: 115,
          categoryId: 1,
          subcategoryId: 1,
          name: "Delete Card",
        },
        // Option - Estimates
        {
          id: 131,
          categoryId: 1,
          subcategoryId: 2,
          name: "Send to Supplier",
        },
        {
          id: 132,
          categoryId: 1,
          subcategoryId: 2,
          name: "Send to PartsSearch",
        },
        {
          id: 133,
          categoryId: 1,
          subcategoryId: 2,
          name: "Send to Insurer",
        },
        {
          id: 134,
          categoryId: 1,
          subcategoryId: 2,
          name: "Send to Customer",
        },
        {
          id: 135,
          categoryId: 1,
          subcategoryId: 2,
          name: "Mark as Sent",
        },
        {
          id: 136,
          categoryId: 1,
          subcategoryId: 2,
          name: "Mark as On Hold",
        },
        {
          id: 137,
          categoryId: 1,
          subcategoryId: 2,
          name: "Mark as Closed",
        },
        {
          id: 138,
          categoryId: 1,
          subcategoryId: 2,
          name: "Create Invoice",
        },
        {
          id: 139,
          categoryId: 1,
          subcategoryId: 2,
          name: "Mark as Draft",
        },
        // Option - Invoices
        {
          id: 151,
          categoryId: 1,
          subcategoryId: 3,
          name: "Send to Customer",
        },
        {
          id: 152,
          categoryId: 1,
          subcategoryId: 3,
          name: "Send to Insurer",
        },
        {
          id: 154,
          categoryId: 1,
          subcategoryId: 3,
          name: "Mark as Awaiting Payment",
        },
        {
          id: 155,
          categoryId: 1,
          subcategoryId: 3,
          name: "Mark as Void",
        },
        {
          id: 156,
          categoryId: 1,
          subcategoryId: 3,
          name: "Mark as Paid",
        },
        // Option - Parts
        {
          id: 171,
          categoryId: 1,
          subcategoryId: 4,
          name: "Mark as Ordered",
        },
        {
          id: 172,
          categoryId: 1,
          subcategoryId: 4,
          name: "Mark as Arrived",
        },
        // Email - Sent
        {
          id: 211,
          categoryId: 2,
          subcategoryId: 5,
          name: "Send to Supplier",
        },
        // {
        //   id: 212,
        //   categoryId: 2,
        //   subcategoryId: 5,
        //   name: "Send to Partssearch",
        // },
        {
          id: 213,
          categoryId: 2,
          subcategoryId: 5,
          name: "Send to Insurer",
        },
        {
          id: 214,
          categoryId: 2,
          subcategoryId: 5,
          name: "Send to Customer",
        },
        // Integration
        {
          id: 342,
          categoryId: 3,
          subcategoryId: 6,
          name: "Send to Audabridge",
        },
        {
          id: 311,
          categoryId: 3,
          subcategoryId: 6,
          name: "Send to PartsSearch",
        },
        {
          id: 312,
          categoryId: 3,
          subcategoryId: 6,
          name: "Send to Claimlinq",
        },
        {
          id: 313,
          categoryId: 3,
          subcategoryId: 6,
          name: "Send to Estimage",
        },
        {
          id: 314,
          categoryId: 3,
          subcategoryId: 6,
          name: "Send to ORM",
        },
        {
          id: 321,
          categoryId: 3,
          subcategoryId: 8,
          name: "Authority from ORM",
        },
        {
          id: 322,
          categoryId: 3,
          subcategoryId: 8,
          name: "Authority Withheld from ORM",
        },
        {
          id: 323,
          categoryId: 3,
          subcategoryId: 8,
          name: "Authority Withdrawn from ORM",
        },
        {
          id: 324,
          categoryId: 3,
          subcategoryId: 8,
          name: "Total Loss Determined from ORM",
        },
        {
          id: 325,
          categoryId: 3,
          subcategoryId: 8,
          name: "Cash Settlement Determined from ORM",
        },
        {
          id: 331,
          categoryId: 3,
          subcategoryId: 8,
          name: "New RFQ from Estimage",
        },
        {
          id: 332,
          categoryId: 3,
          subcategoryId: 8,
          name: "Authority from Estimage",
        },
        {
          id: 341,
          categoryId: 3,
          subcategoryId: 8,
          name: "New Claim from Audabridge",
        },
        {
          id: 343,
          categoryId: 3,
          subcategoryId: 8,
          name: "Authority Received",
        },
        {
          id: 344,
          categoryId: 3,
          subcategoryId: 8,
          name: "Supp Authorised Received",
        },
        // Delay
        {
          id: 411,
          categoryId: 4,
          subcategoryId: 7,
          name: "1 Day",
        },
        {
          id: 412,
          categoryId: 4,
          subcategoryId: 7,
          name: "2 Days",
        },
        {
          id: 413,
          categoryId: 4,
          subcategoryId: 7,
          name: "3 Days",
        },
        {
          id: 414,
          categoryId: 4,
          subcategoryId: 7,
          name: "7 Days",
        },
        {
          id: 415,
          categoryId: 4,
          subcategoryId: 7,
          name: "14 Days",
        },
        {
          id: 416,
          categoryId: 4,
          subcategoryId: 7,
          name: "1 Month",
        },
      ],

      statusTrigger: {
        types: ['Option', 'Integration', 'Manual', 'Delay'],
        values: [
          'Cards-Create Estimate',
          'Cards-Wizard Create Estimate',
          'Estimate-Send to Supplier',
          'Estimate-Send to PartSearch',
          'Estimate-Sent to Insurer',
          'Estimate-Sent to Customer',
          'Estimate-Receive ATR',
          'Selected by User',
          'Estimate-Create Complete Invoice',
          'Invoice-Mark as Paid',
          'Estimate-Mark as Closed',
          'Card-Mark as Closed',
          '14 Days-From-Estimate'
        ],
        transitions: [
          'Or',
          'And'
        ]
      },
      cardsStatuses: [
        {
          ce_status_id: "0",
          vendor_id: "2",
          original_name: "Card Open",
          name: "Card Open",
          dynamic_name: "0",
          dynamic_order: "1",
          order_number: "0",
          _details: false,
          _is_edit: false,
          triggers: [
            {
              type: 'Option',
              value: 'Cards-Create Estimate',
              transition: 'Or',
            },
            {
              type: 'Option',
              value: 'Cards-Wizard Create Estimate'
            }
          ]
        },
        {
          ce_status_id: "1",
          vendor_id: "2",
          original_name: "Estimate Created",
          name: "Estimate Created",
          dynamic_name: "0",
          dynamic_order: "1",
          order_number: "1",
          _details: false,
          _is_edit: false,
          triggers: [],
        },
        {
          ce_status_id: "2",
          vendor_id: "2",
          original_name: "Parts Pricing Requested",
          name: "Parts Pricing Requested",
          dynamic_name: "1",
          dynamic_order: "1",
          order_number: "2",
          _details: true,
          _is_edit: false,
          triggers: [
            {
              type: 'Option',
              value: 'Cards-Create Estimate',
              transition: 'Or',
            },
            {
              type: 'Option',
              value: 'Cards-Wizard Create Estimate'
            }
          ]
        },
        {
          ce_status_id: "3",
          vendor_id: "2",
          original_name: "Estimate Complete",
          name: "Estimate Complete",
          dynamic_name: "1",
          dynamic_order: "1",
          order_number: "3",
          _details: false,
          _is_edit: false,
          triggers: [],
        },
      ],
      statuses: [],
      nameAddStatus: '',
    }
  },
  mounted () {
    this.loadData();
  },
  methods: {
    loadData(){
      //this.convertDataToReceive(this.triggers);
      //return;
      Axios.get('/fe/settings/vendor-statuses')
        .then(response => {
          if(response && response.data && response.data._status){
            this.convertDataToReceive(response.data.statuses);
          }
        })
        .catch( e =>{
          console.warn(e);
        })
    },
    saveData(){
      let data = this.convertDataToSend();
      Axios.post('/fe/settings/update-vendor-statuses', {
        statuses: data,
        toRemove: [],
      })
        .then(
          response => {
            if(response && response.data && response.data._status){
              this.loadData();
              // toast here
            }
          }
        )
        .catch(e => {
          console.warn(e);
          // toast here
        });
    },
    testFoo(){
    //  console.log(this.convertDataToSend());
    },
    convertDataToSend(){
      let data = [];
      this.statuses.forEach(st => {
        //if(!st.editable){
        //  return;
        //}
        let triggers = null;
        let lastTrigger = null;
        st.triggers.forEach(tr => {
          let ctr = {
            trigger: tr.value,
          };
          if(tr.transition){
            ctr[tr.transition.toLowerCase()] = {};
          }
          if(lastTrigger){
            if(lastTrigger.and){
              lastTrigger.and = ctr;
            }
            if(lastTrigger.or){
              lastTrigger.or = ctr;
            }
          }
          if(!triggers){
            triggers = ctr;
          }
          lastTrigger = ctr;
        });
        let est = {
          name: st.name,
          active: st.active,
          rules: triggers,
          sort: st.sort,
          editable: st.editable,
        };
        if(!st.new){
          est.statusId = st.id;
        }
        data.push(est)
      })
      return data;
    },
    convertDataToReceive(data){
      this.statuses = [];
      data.forEach(st => {
        let stt = {
          id: st.statusId,
          name: st.name,
          editable: !!st.editable,
          active: !!st.active,
          _edit: false,
          isDrag: st.isDrag,
          _details: false,
          sort: this.statuses.length,
          triggers: [],
        };
        let trs = [];
        let trToArray = trigger => {
          let ntr = {
            value: trigger.trigger,
            transition: null,
          };
          trs.push(ntr);
          if(trigger.or){
            ntr.transition = 'Or';
            trToArray(trigger.or);
          }
          if(trigger.and){
            ntr.transition = 'And';
            trToArray(trigger.and);
          }
          let ti = this.getTriggerInfo(ntr.value);
          if(ti){
            ntr.type = ti.categoryId;
          } else {
            let i = trs.indexOf(ntr);
            trs.splice(i, 1);
          }
        };
        if(st.rules){
          trToArray(st.rules);
        }
        stt.triggers = trs;
        this.statuses.push(stt);
       // console.log(stt);
      })
    },
    getTriggerInfo(id){
      let trigger = null;
      this.triggerNames.forEach(t => {
        if(trigger) return;
        if(t.id == id){
          trigger = t;
        }
      });
      if(!trigger){
        return null;
      }
      let cat = null;
      let subcat = null;
      this.triggerCategories.forEach(c => {
        if(c.id == trigger.categoryId){
          cat = c;
        }
      })
      this.triggerSubcategories.forEach(c => {
        if(c.id == trigger.subcategoryId){
          subcat = c;
        }
      });
      trigger.category = cat;
      trigger.subcategory = subcat;
      return trigger;
    },
    getTriggersByCategory(id){
  //    console.log('ID #%s', id);
      let triggers = [];
      let subcats = {};
      this.triggerSubcategories.forEach(s => {
        subcats[s.id] = s;
      });
      this.triggerNames.forEach(t => {
        if(Number(t.categoryId) === Number(id)){
          t.subcategory = subcats[t.subcategoryId];
          triggers.push(t);
        }
      });
      let opts = [];
      _.forEach(triggers, function (t) {
        console.log('t', t.subcategory, t.subcategory.name)
        opts.push({key: t.id, value: t.subcategory.name + '-' + t.name})
      })
      return opts;
    },
    hasTrigger(index) {
      return this.statuses[index].triggers && this.statuses[index].triggers.length > 0
    },
    handleTriggerClick(index) {
      if (!this.isStatusActive) {
        return;
      }
      if (this.hasTrigger(index)) {
        this.statuses[index]._details = !this.statuses[index]._details;
      }
    },
    handleAddStatus() {
   //   console.log('New Status %s', this.nameAddStatus)
      if (!this.isStatusActive) {
        return;
      }
      if (this.nameAddStatus === '') return;

      let id = 0;
      this.statuses.forEach(e => {
        if(e.id > id){
          id = e.id;
        }
      });
      id++;

      this.statuses.push({
        id: id,
        new: true,
        name: this.nameAddStatus,
        editable: true,
        active: false,
        _edit: false,
        isDrag: true,
        triggers: [],
        isNotSaved: true,
      });
      this.nameAddStatus = '';
    },
    handleAddTrigger(statusIndex, triggerIndex) {
      if (!this.isStatusActive) {
        return;
      }
      const currentCard = this.statuses[statusIndex];
      let newTrigger = {
        type: 1,
        value: null,
      };
      if (!this.hasTrigger(statusIndex)) {
        currentCard.triggers = currentCard.triggers || [];
        currentCard.triggers.push(newTrigger);
        currentCard._details = true;
        currentCard._edit = true;
      } else {
        const currentTrigger = currentCard.triggers[triggerIndex]
        // add transition
        if (!currentTrigger.transition) {
          currentTrigger.transition = this.conditionTypes[0];
        }
        // add new trigger
        if (triggerIndex !== currentCard.triggers.length - 1) {
          newTrigger.transition = this.conditionTypes[0];
        }
        this.statuses[statusIndex].triggers.splice(triggerIndex + 1, 0, newTrigger)
      }

    },
    handleRemoveTrigger(statusIndex, triggerIndex){
      if (!this.isStatusActive) {
        return;
      }
      const currentCard = this.statuses[statusIndex];
      if(currentCard.triggers.length - 1 == triggerIndex && triggerIndex > 0){
        currentCard.triggers[triggerIndex - 1].transition = null;
      }
      currentCard.triggers.splice(triggerIndex, 1);
    },
    toggleCardStatusEdit(index) {
      if (!this.isStatusActive) {
        return;
      }
      this.statuses[index]._edit = !this.statuses[index]._edit

      // open details when closed, and intend to edit
      if (this.statuses[index]._edit === true) {
        this.statuses[index]._details = true;
      }
    },
    handleTriggersRemove(index) {
      if (!this.isStatusActive) {
        return;
      }
      if (confirm('Are you sure want to remove status automation?')) {
        this.statuses[index].triggers = [];
        this.statuses[index]._is_edit = false;
        this.statuses[index]._details = false;
      }

    },
    handleStatusRemove(index) {
      if (!this.isStatusActive) {
        return;
      }
      if (confirm('Are you sure want to remove this status?')) {
        if (this.statuses[index].isNotSaved) {
          this.statuses.splice(index, 1);
          toastr.success("Status successfully removed");
          return;
        }

        Axios.post("/fe/settings/delete-vendor-status", {statusId: this.statuses[index].id})
          .then(response => {
            if(response && response.data){

              if(response.data._status){
                toastr.success("Status successfully removed");
                this.statuses.splice(index, 1);
                this.$store.dispatch('loadCEStatuses');
              } else {
                let msg = response.data._msg || "Cannot remove status";
                toastr.error(msg);
              }
            }
          })
          .catch(e => {
            toastr.error("Cannot remove status")
          });

      }
    },
    emitUpdateCEStatusKeyUp(ce_status) {
      if (!this.isStatusActive) {
        return;
      }
      if (ce_status.isNotSaved) {
        return;
      }
      this.typingTimerCEStatusId = ce_status.ce_status_id;
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(function () {
        if (ce_status.dynamic_name === "0") {
          toastr.error("Access denied to update current card status");
          return false;
        }
        Axios.post('/fe/card/updateStatusName', {ce_status_id: ce_status.id, name: ce_status.name})
          .then(response => {
            toastr.success(response.data.msg);
            this.$store.dispatch('loadCEStatuses');
          })
          .catch(error => {
            toastr.error(error);
          });
      }, 3000);
    },
    emitUpdateCEStatusKeyDown() {
      clearTimeout(this.typingTimer);
    },
    moveStatus (q, w) {
      console.log('moveStatus', q, w)
//                let el = q.dragged;
//console.log('evt.dragged',q.dragged);
//console.log('evt.draggedRect',q.draggedRect);
//console.log('evt.related',q.related);
//console.log('evt.relatedRect',q.relatedRect);
//console.log('evt.willInsertAfter',q.willInsertAfter);
//console.log('evt.clientY',w.clientY);
//console.log('evt',q);
//console.log('evt2',w);
//if (q.willInsertAfter) {
//    q.related.scrollIntoView({block: 'start'});
//}else{
//    q.related.scrollIntoView({block: 'end'});
//}
    },
    updateCardStatusOrders(ev) {
      console.log('ev', ev);

      this.statuses.forEach(function (el, index) {
        el.sort = index;
      });

      return true;
      // let ce_status_ids = [];
      // ev.target.childNodes.forEach((tr, i) => {
      //   ce_status_ids.push(tr.getAttribute("data-ce_status_id"));
      // });
      //
      // NProgress.start();
      // Axios.post('/fe/card/updateStatusOrders', {ce_status_ids: ce_status_ids})
      //   .then(response => {
      //     NProgress.done();
      //     toastr.success(response.data.msg);
      //   })
      //   .catch(error => {
      //     NProgress.done();
      //     toastr.error(error);
      //   });
    },
  },
}
</script>

<template>
  <div>
    <div class="table table-responsive tab-special mb-0 rs-scroll rs-scroll--y window-cards-status-responsive">
      <div class="thead-light d-flex" style="min-width: 870px">
        <div class="tfont-13-bold verticalTextTh table-header-item col-lg-1">Order</div>
        <div class="tfont-13-bold verticalTextTh table-header-item col-lg-3">Status Name
        </div>
        <div class="tfont-13-bold verticalTextTh table-header-item col-lg-1" style="padding: 15px 0">Status
          Automation Active
        </div>
        <div class="tfont-13-bold verticalTextTh table-header-item col-lg-5" style="padding-left: 30px">Automated
          Status Trigger
        </div>
        <div class=" verticalTextTh table-header-item col-lg-2">
        </div>
      </div>

      <!--      <template v-show="false" v-if="false" v-for="(status, index) in statuses">-->
      <!--        <tr-->
      <!--          :data-ce_status_id="status.id"-->
      <!--          :key="index"-->
      <!--          :class="{'active': status.active, 'is-edit': status._edit}">-->
      <!--          <td class="tfont-12-reguler orderNameClass">-->
      <!--            {{ index + 1 }}-->
      <!--          </td>-->
      <!--          <td class="tfont-12-bold">-->
      <!--            <template v-if="status._edit">-->
      <!--              <input type="text"-->
      <!--                     class="form-control"-->
      <!--                     v-model="status.name"-->
      <!--                     v-on:keyup="emitUpdateCEStatusKeyUp(status)"-->
      <!--                     v-on:keydown="emitUpdateCEStatusKeyDown"-->
      <!--              />-->
      <!--            </template>-->
      <!--            <template v-else>-->
      <!--              {{ status.name }}-->
      <!--            </template>-->
      <!--          </td>-->
      <!--          <td class="tfont-12-bold">-->
      <!--            <span v-if="status.active">-->
      <!--              Yes-->
      <!--            </span>-->
      <!--            <span v-else>-->
      <!--              No-->
      <!--            </span>-->
      <!--          </td>-->
      <!--          <td class="tfont-12-reguler">-->
      <!--            <a href="#"-->
      <!--               @click.prevent="handleTriggerClick(index)"-->
      <!--               class="trigger-link">-->
      <!--              <i class='bx bx-chevron-down'></i>-->
      <!--              <template v-if="hasTrigger(index)">-->
      <!--                Trigger-->
      <!--                is Active-->
      <!--              </template>-->
      <!--              <template v-else>No Trigger Set-->
      <!--              </template>-->
      <!--            </a>-->
      <!--          </td>-->
      <!--          <td></td>-->
      <!--          <td class="tfont-12-reguler" colspan="2">-->
      <!--            <span-->
      <!--              v-if="status.editable">-->
      <!--                <i @click="clickDeleteCEStatus(status.id)"-->
      <!--                   class="fa fa-trash-o trashBarrelClass"-->
      <!--                   aria-hidden="true"></i>-->
      <!--            </span>-->
      <!--            <span-->
      <!--              v-if="!status.editable">Cannot be Edited</span>-->
      <!--          </td>-->
      <!--        </tr>-->
      <!--        <transition name="slide-row" :key="index">-->
      <!--          <tr v-if="status._details">-->
      <!--            <td></td>-->
      <!--            <td></td>-->
      <!--            <td></td>-->
      <!--            <td class="tfont-12-reguler" colspan="3">-->
      <!--              &lt;!&ndash; trigger list &ndash;&gt;-->
      <!--              <template-->
      <!--                v-for="(trigger, i) in cardsStatuses[index].triggers">-->
      <!--                <div class="trigger" :key="i">-->
      <!--                  <div class="trigger__line">-->
      <!--                    <span>If</span>-->
      <!--                    <select-->
      <!--                      class="form-control trigger__option"-->
      <!--                      v-model="trigger.type"-->
      <!--                      :disabled="!ce_status._is_edit">-->
      <!--                      <option-->
      <!--                        v-for="(type, _i) of statusTrigger.types"-->
      <!--                        :key="_i"-->
      <!--                        :value="type">-->
      <!--                        {{ type }}-->
      <!--                      </option>-->
      <!--                    </select>-->
      <!--                    <span>is</span>-->
      <!--                    <select class="form-control"-->
      <!--                            v-model="trigger.value"-->
      <!--                            :disabled="!ce_status._is_edit">-->
      <!--                      <option-->
      <!--                        v-for="(value, _i) of statusTrigger.values"-->
      <!--                        :key="_i"-->
      <!--                        :value="value">{{-->
      <!--                          value-->
      <!--                        }}-->
      <!--                      </option>-->
      <!--                    </select>-->
      <!--                    <span class="actions">-->
      <!--                                                                                              <a href="#"-->
      <!--                                                                                                 @click.prevent="handleAddTrigger(index, i)"-->
      <!--                                                                                                 v-if="ce_status._is_edit">-->
      <!--                                                                                                  <i class='bx bx-plus-circle green'></i>-->
      <!--                                                                                              </a>-->
      <!--                                                                                          </span>-->
      <!--                  </div>-->
      <!--                  <div class="trigger__line"-->
      <!--                       v-if="trigger.transition">-->
      <!--                    <span></span>-->
      <!--                    <select-->
      <!--                      class="form-control trigger__option"-->
      <!--                      v-model="trigger.transition"-->
      <!--                      :disabled="!ce_status._is_edit">-->
      <!--                      <option-->
      <!--                        v-for="(value, _i) of statusTrigger.transitions"-->
      <!--                        :key="_i"-->
      <!--                        :value="value">{{-->
      <!--                          value-->
      <!--                        }}-->
      <!--                      </option>-->
      <!--                    </select>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </template>-->
      <!--              &lt;!&ndash; end trigger list &ndash;&gt;-->
      <!--            </td>-->
      <!--            <td class="tfont-12-reguler"></td>-->
      <!--          </tr>-->
      <!--        </transition>-->
      <!--      </template>-->
      <draggable v-model="statuses"
                 :move="moveStatus"
                 class="dragArea"
                 tag="div"
                 style="min-width: 870px"
                 @start="isMovedElement = false"
                 @end="isMovedElement = true"
                 :disabled="!isStatusActive"
                 draggable=".draggeble"
                 ghost-class="ghost-items"
                 :animation="150"
                 @change="updateCardStatusOrders">
        <template v-for="(status, index) in statuses">
          <div class="table-body-item d-flex" :class="{'is-edit': status._edit, 'active': status.active, 'draggeble': status.isDrag }">
            <div class="tfont-12-reguler col-lg-1">
              {{ index + 1 }}
            </div>
            <div class="tfont-12-bold col-lg-3">
              <template v-if="status._edit">
                <input type="text"
                       class="form-control"
                       v-model="status.name"
                       v-on:keyup="emitUpdateCEStatusKeyUp(status)"
                       v-on:keydown="emitUpdateCEStatusKeyDown"
                />
              </template>
              <template v-else>
                {{ status.name }}
              </template>
            </div>
            <div class="tfont-12-bold col-lg-1">
                          <span v-if="status.active">
                            Yes
                          </span>
              <span v-else>
                            No
                          </span>
            </div>
            <div class="tfont-12-reguler col-lg-5">
              <a @click.prevent="handleTriggerClick(index)"
                 class="trigger-link">
                <i v-if="status._details" class='bx bx-chevron-down'></i>
                <i v-else class='bx bx-chevron-up'></i>
                <template v-if="hasTrigger(index)">
                  Trigger is Set
                </template>
                <template v-else>
                  No Trigger Set
                </template>
              </a>
              <template v-if="status._details"
                        v-for="(trigger, i) in status.triggers">
                <div class="trigger" :key="'trr'+i">
                  <div class="trigger__line">
                    <span class="cell">If</span>
                    <multiselect
                        v-model="trigger.type"
                        :options="optionsForTriggerType.map(op => op.key)"
                        :custom-label="opt => optionsForTriggerType.find(x => x.key == opt).value"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        @input="trigger.value = null"
                        :close-on-select="true"
                        :disabled="!status._edit"
                        class="input-trigger input-trigger-type"
                    ></multiselect>
                    <span class="cell">is</span>
                    <multiselect
                        v-model="trigger.value"
                        :options="getTriggersByCategory(trigger.type).map(op => op.key)"
                        :custom-label="opt => getTriggersByCategory(trigger.type).find(x => x.key == opt).value"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        :disabled="!status._edit"
                        class="input-trigger"
                    ></multiselect>
                    <div class="trigger__actions actions" style="min-width: 48px; margin-left: 10px;">
                      <a href="#"
                          @click.prevent="handleAddTrigger(index, i)"
                          v-if="status._edit">
                          <i class='bx bx-plus-circle green'></i>
                      </a>
                      <a href="#"
                          @click.prevent="handleRemoveTrigger(index, i)"
                          v-if="status._edit">
                        <i class='bx bx-trash'></i>
                      </a>
                    </div>
                  </div>
                  <div class="trigger__line"
                       v-if="trigger.transition">
                    <span class="cell"></span>
                    <multiselect
                        v-model="trigger.transition"
                        :options="conditionTypes"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        :disabled="!status._edit"
                        class="input-trigger-last"
                    ></multiselect>
                  </div>
                </div>
              </template>
            </div>
            <div class="tfont-12-reguler col-lg-2">
              <div v-if="status.editable" class="actions">
                <template v-if="status.triggers && status.triggers.length > 0">
                              <span @click.stop="toggleCardStatusEdit(index)">
                                  <i class='bx bx-pencil'></i>
                              </span>
                  <span @click="handleStatusRemove(index)">
                                <i class='bx bx-trash'></i>
                              </span>
                </template>
                <template v-else>
                              <span @click="handleAddTrigger(index)">
                                  <i class='bx bx-plus-circle'></i>
                              </span>
                  <span @click="handleStatusRemove(index)">
                                <i class='bx bx-trash'></i>
                              </span>
                </template>
              </div>
              <div v-else>
                <span>Cannot be Edited</span>
              </div>
            </div>
          </div>
        </template>
      </draggable>

    </div>

    <div class="setings-cards-btn-group">
      <input @keyup.enter="handleAddStatus"
             v-model="nameAddStatus"
             type="text"
             title="new status"
             :readonly="!isStatusActive"
             placeholder="Add new status name"
             class="form-control add-status-input-settings">
      <button type="button"
              @click="handleAddStatus"
              @keyup.space="handleAddStatus"
              class="btn btn-secondary add-status-button">
        Add Status
      </button>
<!--      <button v-if="true" type="button"-->
<!--              @click="testFoo"-->
<!--              class="btn btn-secondary add-status-button">-->
<!--        Test-->
<!--      </button>-->
    </div>
  </div>
</template>

<style scoped>
  .ghost-items a{
    border: none;
    background-color: rgba(253, 253, 253, 0);
  }
  .window-cards-status-responsive{
    max-height: calc(100vh - 455px);
    overflow-y: auto;
  }
  .V3 #cards .table.tab-special td {
    vertical-align: top;
  }
  .V3 #cards .table.tab-special tr {
    position: inherit;
  }
  .draggeble {
    cursor: move;
  }

  .input-trigger {
    height: 31px !important;
  }

  .input-trigger-last {
    width: 100px;
  }

  .setings-cards-btn-group {
    margin-top: 15px !important;
  }
  .table-header-item{
    padding: 15px;
    align-self: center;
  }
  .table-body-item{
    line-height: 50px;
    border-bottom: 1px solid rgba(27, 30, 56, .25);
    padding: 15px 0;
  }
  .ghost-items a{
    margin: 0;
    line-height: 0;
  }
</style>
